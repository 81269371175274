import React, { useEffect } from "react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styles";
import { useHistory } from "react-router-dom";
import miniMapImage from "../../assets/images/miniMapImage.jpg";
import whyMintImg from "../../assets/images/whyMintImg.jpg";
import randomAvatar from "../../assets/images/randomAvatar.png";
import teenyicons1 from "../../assets/images/teenyicons1.png";
import wallet1 from "../../assets/images/wallet1.png";
import fluent1 from "../../assets/images/fluent1.png";
import vipImage from "../../assets/images/vipImage.png";
import topToImage from "../../assets/images/topToImage.png";
import nftTransImage from "../../assets/images/nftTransImage.png";
import transferImage from "../../assets/images/transferImage.png";
import characterDesk from "../../assets/images/characterDesk.jpg";
import characterMobile from "../../assets/images/characterMobile.jpg";

// Material ui style
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  textCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  imgCont: {
    display: "flex",
    justifyContent: "center",
  },
  progress: {
    width: "80%",
    height: 14,
    borderRadius: 7,
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  topArea: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
  },
  stackarea: {
    backgroundColor: "#8F4AFF",
    [theme.breakpoints.down("md")]: {
      marginTop: -40,
    },
  },
  calcArea: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
}));

interface ButtonProps {
  status: number;
}

// styled-components
const Description1 = styled.p`
  font-size: 26px;
  font-weight: 200;
  text-align: center;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  letter-spacing: 0.5px;
  padding-bottom: 40px;
  @media screen and (max-width: 600px) {
    margin: 0 auto;
    font-size: 14px;
  }
`;
const ContainerVideo = styled.video`
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
  display: flex;
  margin-bottom: 30px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;
const Container1 = styled.div`
  background-color: rgb(${colors.black});
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BannerContainer = styled.div`
  background-color: rgb(${colors.black});
  background-image: url(/images/background1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media screen and (max-width: 1550px) {
    min-height: 100vh;
  }
`;
const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  padding: 50px 10px 50px 0;
  align-items: center;
  @media screen and (max-width: 1550px) {
  }
`;
const MainContent = styled.div`
  background-image: url(/images/pahse2BackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-bottom: 60px;
`;
const TopImage = styled.img`
  width: 100%;
`;
const TopImage1 = styled.img`
  width: 100%;
  display: flex;
  @media screen and (max-width: 660px) {
    display: none;
  }
`;
const TopImage2 = styled.img`
  width: 100%;
  display: none;
  @media screen and (max-width: 660px) {
    display: flex;
  }
`;
const MediumImage3 = styled.img`
  border-radius: 20px;
  width: 90%;
  margin-bottom: 30px;
  text-align: center;
`;
const ImageContenterGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 7%;
  justify-content: space-between;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ImageContainer1 = styled.div`
  flex-direction: row;
  display: flex;
  @media screen and (max-width: 600px) {
    // width: 100%;
    // flex-direction: column;
    // justify-content: center;
    // padding-bottom: 2em;
  }
`;
const ImageArea = styled.img`
  width: 200px;
  @media screen and (max-width: 600px) {
    width: 100px;
    height: 150px;
  }
`;
const MainContent1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
  }
`;
const LoadmapTitle = styled.p`
  font-size: 96px;
  color: white;
    font-family: AldotheApache;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  @media screen and (max-width: 560px) {
    font-size: 44px;
    margin-top: 30px !important;
  }
`;
const ComingTitle = styled.p`
  font-size: 30px;
  color: white;
  //   font-family: fenwick-outline;
  font-family: Orbitron-Regular;
  margin: 0;
  text-align: center;
  margin-bottom: 30px;
  max-width: 1100px;
  text-transform: uppercase;
  width: 90%;
  @media screen and (max-width: 560px) {
    font-size: 18px;
    margin-top: 0px;
  }
`;

const Description2 = styled.p`
  font-size: 18px;
  color: white;
  font-family: MonumentExtended-Regular;
  font-weight: 200;
  text-align: center;
  margin: 0;
  letter-spacing: 0.5px;
  max-width: 800px;
  width: 90%;
  @media (max-width: 980px) {
    margin: 0 auto;
    font-size: 12px;
  }
`;

const TopToImage = styled.img`
  border-radius: 20px;
  margin-bottom: 50px;
  margin-top: 0px;
  width: 250px;
  @media (max-width: 650px) {
    width: 150px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});

interface Props {
  balance: number;
  totalPrice: number;
  onStakAmountChangeButtonClick: (dir: number) => void;
  onStakReleaseChangeButtonClick: (dir: number) => void;
  onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStakeAmountHandle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStakeReleaseHandle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCallMint: () => void;
  add_chain_with_web3: () => void;
  amount: number;
  stakeRelease: number;
  stakeAmount: number;
  maxAmount: number;
  itemPrice: number;
  mintLoading: number;
  connect: () => void;
  connected: boolean;
  connectFlag: boolean;
}

let totalprice = 0;

const RewardAreaContainer: React.FC<Props> = ({ }) => {
  let history = useHistory();

  useEffect(() => {
    AOS.init();
  }, []);

  const navigateTo = (targetPath: string) => {
    history.push(`/${targetPath}`);
  };

  return (
    <Container1 id="reward">
      {/* <LoadmapTitle id="roadmap">ROADMAP</LoadmapTitle> */}
      <TopImage id="roadmap" src={miniMapImage} alt="" />
      <BannerContainer>
        <TopImage id="whymint" src={whyMintImg} alt="" />
        <TopImage1 id="characterDesk" src={characterDesk} alt="" />
        <TopImage2 id="characterMobile" src={characterMobile} alt="" />
        <ContainerVideo autoPlay muted loop playsInline style={{ width: '100%', marginBottom: 0 }}>
          <source
            src="https://sinsofshadow.s3.amazonaws.com/videos/SINS_TEASER.mp4"
            type="video/mp4"
          />
        </ContainerVideo>
        <MainContent>
          <LoadmapTitle id="parse" style={{ marginTop: 100 }}>
            Phase 2
          </LoadmapTitle>
          <ComingTitle>
            Mint a Genesis PFP (ETH) to upgrade to a 3D game and mocap NFT -
            available ONLY with $SMBR token 🔥🔥🔥
          </ComingTitle>
          <ContainerVideo
            style={{ maxWidth: "500px", borderRadius: 20 }}
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              src="https://sinsofshadow.s3.amazonaws.com/videos/landingVideo1.mp4"
              type="video/mp4"
            />
          </ContainerVideo>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <MediumImage3
              style={{ maxWidth: 1100, marginBottom: 0 }}
              src={nftTransImage}
              alt=""
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
            }}
          >
            <MediumImage3
              style={{ maxWidth: 1000 }}
              src={transferImage}
              alt=""
            />
          </div>
          <Description2>
            Phase 2 brings 3D motion capture and game ready NFT avatars. Each
            PFP has a matching 3D twin that enters the game and the TV show when
            a user mints.
          </Description2>
          <TopToImage
            onClick={(e) => {
              const mint = document.querySelector("#top");
              if (mint) {
                mint.scrollIntoView({ behavior: "smooth", block: "center" });
              }
            }}
            src={topToImage}
            alt=""
          />
        </MainContent>
      </BannerContainer>
    </Container1>
  );
};

export default RewardAreaContainer;
