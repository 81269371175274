import {
  DAI_CONTRACT,
  MINT_CONTRACT,
  NFT_CONTRACT,
  TOKEN_CONTRACT,
  STAKING_CONTRACT,
  LOCK_CONTRACT,
} from "../constants";

import {
  rinkebyChainId,
  mainChainId,
  rinkebyAddress,
  mainAddress,
  rinkebyNetworkInfo,
  mainNetworkInfo,
  rarityLink,
  rairityImageLink,
  num_revealed,
  rairityJsonLink,
  rairityPreJsonLink,
  testNet,
} from "../sombra_config";

export function getDaiContract(chainId: number, web3: any) {
  const dai = new web3.eth.Contract(
    DAI_CONTRACT[chainId].abi,
    DAI_CONTRACT[chainId].address
  );
  return dai;
}

export function callBalanceOf(address: string, chainId: number, web3: any) {
  return new Promise(async (resolve, reject) => {
    const dai = getDaiContract(chainId, web3);

    await dai.methods
      .balanceOf(address)
      .call(
        { from: "0x0000000000000000000000000000000000000000" },
        (err: any, data: any) => {
          if (err) {
            reject(err);
          }

          resolve(data);
        }
      );
  });
}

export function callTransfer(address: string, chainId: number, web3: any) {
  return new Promise(async (resolve, reject) => {
    const dai = getDaiContract(chainId, web3);

    await dai.methods
      .transfer(address, "1")
      .send({ from: address }, (err: any, data: any) => {
        if (err) {
          reject(err);
        }

        resolve(data);
      });
  });
}

export async function callMint(
  mintAddress: string,
  address: string,
  chainId: number,
  web3: any,
  amount: number,
  totalPrice: number
) {
  try {
    const contract = new web3.eth.Contract(MINT_CONTRACT.abi, mintAddress);
    await contract.methods.mint_multiple_with_nativecoin(amount).send({
      from: address,
      value: web3.utils.toWei(totalPrice.toString(), "ether"),
    });
    return true;
  } catch (error) {
    return false;
  }
}

export async function getItemPrice(mintAddress: string, web3: any) {
  try {
    const contract = new web3.eth.Contract(MINT_CONTRACT.abi, mintAddress);
    let value = await contract.methods.getNativePrice().call();
    return value;
  } catch (error) {
    console.log("errorr", error);
  }
}

export async function getMaxMintAmount(mintAddress: string, web3: any) {
  try {
    const contract = new web3.eth.Contract(MINT_CONTRACT.abi, mintAddress);
    let maxAmount = await contract.methods.getAmmountMinted().call();
    return maxAmount;
  } catch (error) {
    console.log("errorr", error);
  }
}

export async function fetchNFT(
  mintAddress: string,
  web3: any,
  j: any,
  account: string
) {
  let contract_token_1 = new web3.eth.Contract(MINT_CONTRACT.abi, mintAddress);
  let current_nft;
  let owners_balance;

  try {
    current_nft = await contract_token_1.methods
      .tokenOfOwnerByIndex(account, j)
      .call();
    owners_balance = await contract_token_1.methods.balanceOf(account).call();
    // nft_info = await contract_token_1.methods.nft_info(current_nft).call()
  } catch (err) {
    return false;
  }
  try {
    if (current_nft) {
      let nft_uri = await contract_token_1.methods.tokenURI(current_nft).call();

      let just_hash = nft_uri.slice(7); // change to appropriate length

      let meta_data_link = "https://sins.mypinata.cloud/ipfs/" + just_hash;

      return [meta_data_link, owners_balance, current_nft];
    }
  } catch (err) {
    console.log("uri/return failed", err);
    return false;
  }
}

export async function callStake(
  address: string,
  web3: any,
  totalPrice: number,
  contractAddress: string
) {
  try {
    const contract_stake = new web3.eth.Contract(
      STAKING_CONTRACT.abi,
      contractAddress
    );
    await contract_stake.methods
      .stake(totalPrice.toString())
      .send({ from: address });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}

export async function fetchBonuses(
  web3: any,
  address: string,
  contractAddress: string
) {
  try {
    const contract_stake = new web3.eth.Contract(
      STAKING_CONTRACT.abi,
      contractAddress
    );
    let bonuses = await contract_stake.methods.bonuses(address).call();
    let bonus_denominator = await contract_stake.methods
      .bonus_denominator()
      .call();
    return [bonuses, bonus_denominator];
  } catch (error) {
    console.log("errorr", error);
  }
}

export async function callWithDraw(
  address: string,
  web3: any,
  withdrawAmount: number,
  contractAddress: string
) {
  try {
    const contract_stake = new web3.eth.Contract(
      STAKING_CONTRACT.abi,
      contractAddress
    );
    await contract_stake.methods
      .withdraw(withdrawAmount.toString())
      .send({ from: address });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}
export async function callHarvest(
  address: string,
  web3: any,
  contractAddress: string
) {
  try {
    const contract_stake = new web3.eth.Contract(
      STAKING_CONTRACT.abi,
      contractAddress
    );
    await contract_stake.methods.getReward().send({ from: address });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}

export async function approve(
  web3: any,
  address: string,
  totalPrice: number,
  contractAddress: string,
  stakingContractAddress: string,
) {
  try {
    const contract_stake = new web3.eth.Contract(
      TOKEN_CONTRACT.abi,
      contractAddress
    );
    await contract_stake.methods
      .approve(stakingContractAddress, totalPrice.toString())
      .send({ from: address });
  } catch (error) {
    console.log("errorr", error);
  }
}

export async function fetchSMBRTokenBalance(
  web3: any,
  account: string,
  contractAddress: string
) {
  let contract_stake = new web3.eth.Contract(
    TOKEN_CONTRACT.abi,
    contractAddress
  );
  let owners_balance;
  try {
    owners_balance = await contract_stake.methods.balanceOf(account).call();
    return owners_balance;
  } catch (err) {
    console.log(err);
  }
}

export async function fetchStakedTokenBalance(
  web3: any,
  address: string,
  contractAddress: string
) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    contractAddress
  );
  let stakedBalance;
  try {
    stakedBalance = await contract_stake.methods.balanceOf(address).call();
    return stakedBalance;
  } catch (err) {
    console.log(err);
  }
}

export async function fetchEarnedTokenBalance(
  web3: any,
  address: string,
  contractAddress: string
) {
  let contract_stake = new web3.eth.Contract(
    STAKING_CONTRACT.abi,
    contractAddress
  );
  let earnedBalance;
  try {
    earnedBalance = await contract_stake.methods.earned(address).call();
    return earnedBalance;
  } catch (err) {
    console.log(err);
  }
}

/////////////////////// NFT Staking/////////////////////////////

export async function isApprovedForAll(
  web3: any,
  address: string,
  contractAddress: string,
  lockContractAddress: string
) {
  let nft_contract = new web3.eth.Contract(MINT_CONTRACT.abi, contractAddress);

  let result = await nft_contract.methods
    .isApprovedForAll(address, lockContractAddress)
    .call();
  return result;
}

export async function isCheckStakable(
  web3: any,
  tokenId: string,
  contractAddress: string
) {
  let nft_lock = new web3.eth.Contract(LOCK_CONTRACT.abi, contractAddress);

  try {
    let result = await nft_lock.methods.checkStakable(tokenId).call();
    if (result) {
      return true;
    }
  } catch (error: any) {
    return error.message;
  }
}

export async function callBoostCStaking(
  web3: any,
  tokenId: string,
  address: string,
  contractAddress: string
) {
  let nft_lock = new web3.eth.Contract(LOCK_CONTRACT.abi, contractAddress);

  await nft_lock.methods.boostStaking(tokenId).send({ from: address });
}

export async function nftApprove(
  web3: any,
  address: string,
  contractAddress: string,
  lockContractAddress: string
) {
  try {
    const contract_stake = new web3.eth.Contract(
      MINT_CONTRACT.abi,
      contractAddress
    );
    await contract_stake.methods
      .setApprovalForAll(lockContractAddress, true)
      .send({ from: address });
  } catch (error) {
    console.log("errorr", error);
  }
}

export async function callNFTLock(
  address: string,
  tokenId: number,
  web3: any,
  contractAddress: string
) {
  try {
    const contract = new web3.eth.Contract(LOCK_CONTRACT.abi, contractAddress);
    await contract.methods.lock_nft(tokenId).send({ from: address });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}

export async function callNFTUnlock(
  address: string,
  tokenId: number,
  web3: any,
  contractAddress: string
) {
  try {
    const contract = new web3.eth.Contract(LOCK_CONTRACT.abi, contractAddress);
    await contract.methods.unlock_nft(tokenId).send({ from: address });
    return true;
  } catch (error) {
    console.log("errorr", error);
    return false;
  }
}

export async function fetchLockedNFT(web3: any, account: string) {
  // let contract_token = new web3.eth.Contract(
  //   MINT_CONTRACT.abi,
  //   MINT_CONTRACT.address
  // );
  // let contract_Lock = new web3.eth.Contract(
  //   LOCK_CONTRACT.abi,
  //   LOCK_CONTRACT.address
  // );
  // let current_nft;
  // let owners_balance;

  // try {
  //   current_nft = await contract_Lock.methods
  //     .tokenOfOwnerByIndex(account, j)
  //     .call();
  //   owners_balance = await contract_token.methods.balanceOf(account).call();
  //   // nft_info = await contract_token.methods.nft_info(current_nft).call()
  // } catch (err) {
  //   // console.log(err)
  //   return false;
  // }
  // try {
  //   if (current_nft) {
  //     let nft_uri = await contract_token.methods.tokenURI(current_nft).call();

  //     let just_hash = nft_uri.slice(7); // change to appropriate length

  //     let meta_data_link = "https://sins.mypinata.cloud/ipfs/" + just_hash;

  //     return [meta_data_link, owners_balance, current_nft];
  //   }
  // } catch (err) {
  //   return false;
  // }
  //create instance of contract
  // var contract = new web3.eth.Contract(
  //   LOCK_CONTRACT.abi,
  //   LOCK_CONTRACT.address
  // );

  //listen for new events
  let contract_token = new web3.eth.Contract(
    MINT_CONTRACT.abi,
    MINT_CONTRACT.address
  );
  web3.eth
    .getPastLogs({
      address: LOCK_CONTRACT.address,
      fromBlock: "0x1",
      toBlock: "latest",
      topics: [web3.utils.sha3("NftLocked(address,uint256)")],
    })
    .then(async (res: any) => {
      let current_nfts: any = [];
      for (let i = 0; i < res.length; i++) {
        const element = res[i];
        let tempObj: any = {};
        if (Number(element.topics[1]) == Number(account)) {
          tempObj.tokenId = Number(element.data);
          let nft_uri = await contract_token.methods
            .tokenURI(Number(element.data))
            .call();
          let just_hash = nft_uri.slice(7); // change to appropriate length
          let meta_data_link = "https://sins.mypinata.cloud/ipfs/" + just_hash;
          tempObj.metaDataLink = meta_data_link;
          let link = rairityJsonLink + tempObj.tokenId;
          let rarityLik = rarityLink + tempObj.tokenId + ".json";
          let rarityData: any;
          await fetch(rarityLik)
            .then((res) => res.json())
            .then((json) => {
              rarityData = json.rarity;
            });
          fetch(link)
            .then((res) => res.json())
            .then((json) => {
              tempObj.name = json.name;
              tempObj.description = json.description;
              tempObj.attributes = json.attributes;
              tempObj.rarityData = rarityData;
              tempObj.image = rairityImageLink + tempObj.tokenId + ".png";
            });

          current_nfts.unshift(tempObj); // changed this to after break to avoid double of the previsous
        }
      }
      return current_nfts;
    });
}
