import styled from "styled-components";
import { useHistory } from "react-router-dom";
import GameHeader from "src/components/Home/GameHeader";
import videoBox1 from "src/assets/images/videoBox1.png";
import image1 from "src/assets/images/image1.png";
import landImage from "src/assets/images/landImage.png";
import homeImage from "src/assets/images/homeImage.png";
import gearImage from "src/assets/images/gearImage.png";
import landImage1 from "src/assets/images/landImage1.png";
import vehicleImage from "src/assets/images/vehicleImage.png";
import vehicleImage1 from "src/assets/images/vehicleImage1.png";
import gameTitle from "src/assets/images/gameTitle.png";
import wave from "src/assets/images/wave.png";
import nftTransImage from "src/assets/images/nftTransImage.png";
import transferImage from "src/assets/images/transferImage.png";
import gamePreview3 from "src/assets/images/gamePreview3.png";
import gamePreview1 from "src/assets/images/gamePreview1.png";
import gamePreview2 from "src/assets/images/gamePreview2.png";
import sinsImage from "src/assets/images/SINS_LOGO_WHite.png";
import SINS_tag from "src/assets/images/SINS_tag.png";
import landingGameImage from "src/assets/images/landingGameImage.png";
import videoBoxImage from "src/assets/images/videoBoxImage.png";
import Grid from "@material-ui/core/Grid";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
}

// Material ui style
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  container: {
    padding: 0,
  },
  textCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  imgCont: {
    display: "flex",
    justifyContent: "center",
  },
  progress: {
    width: "80%",
    height: 14,
    borderRadius: 7,
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
  },
  topArea: {
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  topArea1: {
    marginBottom: 40,
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
  },
  stackarea: {
    backgroundColor: "#8F4AFF",
    [theme.breakpoints.down("md")]: {
      marginTop: -40,
    },
  },
  calcArea: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
}));

const MintContainer = styled.div`
  background-image: url(/images/gameBackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  align-items: center;
  padding-bottom: 60px;
  @media (max-width: 600px) {
    padding-top: 50px;
  }
`;
const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1100px;
  width: 95%;
`;
const VideoBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
const TitleImage = styled.img`
  margin-top: 80px;
  margin-bottom: 50px;
  max-width: 400px;
  width: 80%;
  display: flex;
  align-self: center;
  @media screen and (max-width: 560px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
const Description4 = styled.p`
  font-size: 16px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  max-width: 1000px;
  width: 100%;
  font-weight: 400;
  margin-top: 40px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0%;
    margin-top: 20px;
    font-size: 12px;
  }
`;
const LeftSideTitle = styled.p`
  font-size: 24px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  width: 100%;
  font-weight: 400 !important;
  text-align: center;
  text-transform: uppercase;
  @media screen and (max-width: 1300px) {
    font-size: 18px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin-left: 0%;
    font-size: 14px;
    text-align: center;
  }
`;
const MainTitle = styled.p`
  font-size: 55px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  @media screen and (max-width: 1300px) {
    font-size: 40px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 20px;
    width: 100%;
    margin-left: 0%;
    font-size: 30px;
    padding-top: 0px;
    text-align: center;
  }
`;
const MediumImage2 = styled.img`
  width: 100%;
  margin-bottom: 0px;
  margin-top: 50px;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    width: 95%;
    width: 95%;
    margin: 20px auto;
    margin-bottom: 0px;
  }
`;
const MediumImage7 = styled.img`
  max-width: 900px;
  width: 100%;
  margin-bottom: 20px;
  @media screen and (max-width: 560px) {
  }
`;
const MediumImage = styled.img`
  width: 100%;
  @media screen and (max-width: 560px) {
  }
  &:hover {
  }
`;
const ContainerVideo = styled.video`
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
  display: flex;
  padding: 1% 1% 0.5% 1%;
  border-radius: 30px 50px 50px 50px;
  @media screen and (max-width: 650px) {
    border-radius: 30px;
    padding: 1% 1% 0% 1%;
    padding-bottom: -10px;
  }
`;
const ContainerVideo2 = styled.video`
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
  display: flex;
  padding: 0.9% 0.8% 1% 1.1%;
  border-radius: 55px 35px 50px 50px;
  max-width: 900px;
  @media screen and (max-width: 650px) {
    border-radius: 25px 20px 25px 25px;
    padding: 0.9% 0.7% 0.9% 1%;
    padding-bottom: -10px;
  }
`;
const TopTitle2 = styled.p`
  font-size: 50px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  margin-top: 30px;
  text-align: center;
  display: none;
  @media screen and (max-width: 600px) {
    display: block;
    text-align: center;
    margin: 0px auto;
    font-size: 36px;
    margin-top: 0px;
  }
`;
const TopTitle3 = styled.p`
  font-size: 50px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  margin-top: 30px;
  margin-left: 100px;
  display: block;
  @media screen and (max-width: 600px) {
    display: none;
    text-align: center;
    margin-left: 0px;
    font-size: 36px;
    margin-top: 50px;
  }
`;
const Description3 = styled.p`
  font-size: 18px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 524px;
  width: 90%;
  margin-left: 100px;
  @media screen and (max-width: 560px) {
    margin-left: 0px;
    font-size: 12px;
    margin: 0 auto;
  }
`;
const HorizontalLineArea = styled.div`
  width: 100%;
  height: 3px;
  background-color: #ff56f6;
  margin-top: 25px;
  margin-bottom: 25px;
  @media screen and (max-width: 560px) {
    margin-top: 0px !important;
  }
`;
const Description1 = styled.p`
  font-size: 18px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 524px;
  width: 90%;
  @media screen and (max-width: 560px) {
    font-size: 12px;
    margin: 0 auto;
  }
`;
const TopImage2 = styled.img`
  max-width: 1255px;
  width: 100%;
  display: block;
  margin-bottom: 0px;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    width: 95%;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: -50px;
  }
  &:hover {
    transform: scale(1.025);
  }
`;
const MediumImage5 = styled.img`
  width: 100%;
  margin-top: 50px;
  display: block;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    display: none;
    width: 95%;
    width: 95%;
    margin: 50px auto;
  }
`;
const TopTitle1 = styled.p`
  font-size: 50px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  margin-top: 30px;
  margin-left: 100px;
  @media screen and (max-width: 600px) {
    margin-left: 0px;
    font-size: 48px;
    margin-top: 0px;
    text-align: center;
  }
`;
const MediumImage6 = styled.img`
  width: 100%;
  margin-top: 50px;
  display: none;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    display: flex;
    width: 95%;
    width: 95%;
    margin: 50px auto;
    margin-bottom: 0;
    margin-top: 20px;
  }
`;
const SinsArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 40px;
  margin-bottom: 10px;
  width: 100%;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
const SinsImage = styled.img`
  width: 200px;
  @media screen and (max-width: 900px) {
    width: 120px;
  }
`;
const SisnTitle = styled.p`
  text-align: center;
  color: white;
  font-family: MonumentExtended-Regular;
  font-size: 32px;
  padding: 0;
  margin: 0;
  text-shadow: #ed00ea 2px 0 15px;
  @media screen and (max-width: 900px) {
    font-size: 14px;
    margin: 5px 0;
  }
`;
const SinsImage1 = styled.img`
  width: 185px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 600px) {
    width: 75px;
    display: flex;
    margin-top: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const GameScreen = (props: Props) => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <MintContainer>
      <GameHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SinsImage1
          onClick={() => {
            history.push(`/`);
          }}
          src={sinsImage}
          alt=""
        />
      </div>
      <TitleImage src={gameTitle} alt="" />
      <ContentArea>
        <VideoBox>
          <ContainerVideo autoPlay muted loop playsInline>
            <source
              src="https://sinsofshadow.s3.amazonaws.com/videos/SINS_DEBUT_1.mp4"
              type="video/mp4"
            />
          </ContainerVideo>
          <img
            src={videoBoxImage}
            alt=""
            style={{ position: "absolute", width: "100%", height: "100%" }}
          />
        </VideoBox>
        <SinsArea>
          <SinsImage src={SINS_tag} alt="" />
          <SisnTitle>=</SisnTitle>
          <SisnTitle>Cyberpunk + Counterstrike + Fable</SisnTitle>
        </SinsArea>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <MediumImage style={{ maxWidth: 800 }} src={wave} alt="" />
        </div>
        <HorizontalLineArea />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <MediumImage
            style={{ maxWidth: 1000, marginTop: "-25px" }}
            src={nftTransImage}
            alt=""
          />
        </div>
        <div
          id="nft"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <MediumImage
            style={{ maxWidth: 800, marginTop: "-25px" }}
            src={transferImage}
            alt=""
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        ></div>
        <Description4>
          Genesis NFTs have 3D twins. Users can upgrade in Phase 2 to game ready
          avatars that are a 1:1 representation of your Genesis. You must own 2
          or more Genesis NFTs to qualify for upgrades. Each 3D avatar is a 1:1
          representation of your Genesis and enters you into the storyline of
          our game and VR TV show.
        </Description4>
        <MainTitle>Good or Evil...</MainTitle>
        <LeftSideTitle id="good">What side will you choose?</LeftSideTitle>
        <MediumImage7 style={{ marginBottom: 0 }} src={gamePreview1} alt="" />
        <Description4>
          The Sins of Shadow world economy is one based on good faith. If you do
          good deeds you earn a positive Shadow rating. If you do bad deeds you
          earn a negative Shadow rating. These ratings affect your eligibility
          for upgrades and rewards in the greater ecosystem and who you can play
          with in the Arena as a guild. Your actions also influence the types of
          NFT upgrades you can buy for your avatar.
        </Description4>
        <MediumImage7 src={gamePreview2} alt="" />
        <MainTitle>The Arena</MainTitle>
        <MediumImage7 src={landingGameImage} alt="" />
        <Description4 style={{ marginTop: 0 }} id="arena">
          To enter the Arena players must bring $SMBR token to stake into escrow
          during the match. Each guild together puts in an equal amount of
          tokens across the players and that money is held until the winners are
          declared. Each match is winner takes all and players are paid as per
          their k/d ratio and other performance rewards.
          <br />
          <br />
          Spectators can place live bets on the match and players while watching
          rhe games through realtime movable cameras inside tha game.
        </Description4>
        <MediumImage7 src={gamePreview3} alt="" />
        <MainTitle>Open World</MainTitle>
        <Description4 id="openworld" style={{ marginTop: 0 }}>
          Sins has a large open world map containing a variety of enviorments
          and gameplay with friends. To buy items and play in The Arena, players
          must travel through the open world with $SMBR token. When traveling in
          the open world with $SMBR token, other players can kill you and then
          take ALL you tokens. This is not a joke so travel with only what you
          can lose if you are attached.
        </Description4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <MediumImage7 src={image1} alt="" />
        </div>
        <Description4>
          Guilds roam together and the Sombra Rangers are a company hired team
          of players that patrol the open world hunting down evil doers. When a
          Ranger kills you your tokens are taken by the house. <br />
          <br />
        </Description4>
        <VideoBox>
          <ContainerVideo2 autoPlay muted loop playsInline>
            <source
              src="https://sinsofshadow.s3.amazonaws.com/videos/FREEROAM_SINS.mp4"
              type="video/mp4"
            />
          </ContainerVideo2>
          <img
            src={videoBox1}
            alt=""
            style={{ position: "absolute", width: "100%", height: "100%" }}
          />
        </VideoBox>
        <Description4>
          Bodyguards and mercanary companies can be hired to protect you and
          your crew if needed...for a cost of course.
        </Description4>
        <HorizontalLineArea />
        <Grid container spacing={0} className={classes.topArea}>
          <Grid item md={6} xs={12} className={classes.imgContainer}>
            <TopTitle3 style={{ marginLeft: 0 }}>LAND</TopTitle3>
            <Description1>
              Sins of Shadow offers unique land sales all throughout the map.
              You can grab a spot on the volcanic surface of the Hydgroden
              Mines, the bustling fervor of Silver City or the luxury water
              fronts of Terra Del Mar.
              <br />
              <br />
              It doesn’t stop there. Check out all the other areas for sale
              soon!
            </Description1>
          </Grid>
          <Grid item md={6} xs={12} className={classes.calcArea}>
            <MediumImage2 src={landImage} alt="" />
          </Grid>
          <TopTitle2>LAND</TopTitle2>
        </Grid>
        <Grid item md={12} xs={12} className={classes.calcArea}>
          <TopImage2 src={landImage1} alt="" />
        </Grid>
        <HorizontalLineArea />
        <Grid container spacing={0} className={classes.topArea}>
          <Grid item md={6} xs={12} className={classes.calcArea}>
            <MediumImage5 src={vehicleImage} alt="" />
          </Grid>
          <Grid item md={6} xs={12} className={classes.imgContainer}>
            <TopTitle1>VEHICLES</TopTitle1>
            <MediumImage6 src={vehicleImage} alt="" />
            <Description3>
              Getting around is important inside Sins of Shadow’s large scale
              map. Artists can upload different cars and vehicles to put up for
              sale and the Sombra team will make them game ready and NFT minted.
              Sombra also produces its own line of luxury supercars that will be
              available for purchase and gifted to special users.
              <br />
              <br />
              Get your dream ride soon!
            </Description3>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12} className={classes.calcArea}>
          <TopImage2 src={vehicleImage1} alt="" />
        </Grid>
        <HorizontalLineArea />
        <Grid container spacing={0} className={classes.topArea1}>
          <Grid item md={6} xs={12} className={classes.imgContainer}>
            <TopTitle3 style={{ marginLeft: 0 }}>LOOT</TopTitle3>
            <Description1>
              Buy and sell weapons, clothes, styles, and more inside the Bodega.
              You can stock up on ammo, power and all sorts of goodies that
              you’ll need when battling it out against your pals in the Arena.
              Make sure you’re packing heat and you check the stats of your NFT
              weapons.
              <br />
              <br />
              Devil is in the details...
            </Description1>
          </Grid>
          <Grid item md={6} xs={12} className={classes.calcArea}>
            <MediumImage2 src={gearImage} alt="" />
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.topArea}>
          <Grid item md={6} xs={12} className={classes.calcArea}>
            <MediumImage2 src={homeImage} alt="" />
          </Grid>
          <Grid item md={6} xs={12} className={classes.imgContainer}>
            <TopTitle1>HOMES</TopTitle1>
            <Description3>
              Buy or build your dream home in Sins of Shadow. With many
              different landscapes and terrains to choose from, you’re bound to
              find your perfect pad.
              <br />
              <br />
              Make sure you check out the neighborhood though. We hear some are
              better than others. Of course luxury comes with a cost so spend
              wisely...
            </Description3>
          </Grid>
        </Grid>
        {/* <HorizontalLineArea /> */}
      </ContentArea>
    </MintContainer>
  );
};

export default GameScreen;
