import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import BuccaneerHeader from "../components/Home/BuccaneerHeader";
import TopContainer from "../components/Home/TopContainer";
import Phase_Biomatics from "../components/Home/Phase_Biomatics";
import RewardArea from "../components/Home/RewardArea";
import LoadMapContainer from "../components/Home/LoadMapContainer";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  onMintChangeButtonClick: (dir: number) => void;
  onStakAmountChangeButtonClick: (dir: number) => void;
  onStakReleaseChangeButtonClick: (dir: number) => void;
  onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStakeAmountHandle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onStakeReleaseHandle: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCallMint: () => void;
  add_chain_with_web3: () => void;
  isHide: boolean;
  connected: boolean;
  connectFlag: boolean;
  address: string;
  chainId: number;
  amount: number;
  stakeAmount: number;
  stakeRelease: number;
  totalPrice: number;
  balance: number;
  maxAmount: number;
  itemPrice: number;
  mintLoading: number;
  sombraPrice: number;
  maticPrice: number;
}

const MintContainer = styled.div`
  padding-top: 70px;
  @media (max-width: 600px) {
    padding-top: 60px;
  }
`;

const MintScreen = (props: Props) => {
  let history = useHistory();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [window.scrollY]);

  const handleScroll = () => {
    var d: HTMLElement | null = document.getElementById("myID");
    var dd: HTMLElement | null = document.getElementById("fixedID");
    var topPos: number | undefined = d?.offsetTop;
    if (dd && topPos && window.scrollY > topPos) {
      dd.style.display = "flex";
    } else if (dd && topPos && window.scrollY < topPos) {
      dd.style.display = "none";
    }
  };

  const warning = () => {
    alert("Not quite ready yet");
  };

  return (
    <MintContainer>
      <BuccaneerHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div  id="top"/>
      <Phase_Biomatics
        connect={props.connect}
        connected={props.connected}
        balance={props.balance}
        amount={props.amount}
        totalPrice={props.totalPrice}
        onMintChangeButtonClick={props.onMintChangeButtonClick}
        onMaxButtonClick={props.onMaxButtonClick}
        onCallMint={props.onCallMint}
        maxAmount={props.maxAmount}
        itemPrice={props.itemPrice}
        connectFlag={props.connectFlag}
        add_chain_with_web3={props.add_chain_with_web3}
        mintLoading={props.mintLoading}
      />
      {/* <LoadMapContainer /> */}
      <RewardArea
        connect={props.connect}
        connected={props.connected}
        balance={props.balance}
        amount={props.amount}
        stakeAmount={props.stakeAmount}
        stakeRelease={props.stakeRelease}
        totalPrice={props.totalPrice}
        onStakReleaseChangeButtonClick={props.onStakReleaseChangeButtonClick}
        onStakAmountChangeButtonClick={props.onStakAmountChangeButtonClick}
        onMaxButtonClick={props.onMaxButtonClick}
        onStakeReleaseHandle={props.onStakeReleaseHandle}
        onStakeAmountHandle={props.onStakeAmountHandle}
        onCallMint={props.onCallMint}
        maxAmount={props.maxAmount}
        itemPrice={props.itemPrice}
        connectFlag={props.connectFlag}
        add_chain_with_web3={props.add_chain_with_web3}
        mintLoading={props.mintLoading}
      />
    </MintContainer>
  );
};

export default MintScreen;
