import { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="wrapper">
      <img className="collections_header_bg" src="/home_header_bg.png" alt="" />
      <div className="policyContent">
        <h1>TERMS AND CONDITIONS</h1>

        <h2>1. Introduction</h2>

        <p>
          These Terms of Use (the “Terms”) and all policies posted on our
          website set out the terms on which Sombra Network LLC (trading as
          Sombra) offers you access to and use of our websites, Smart Contracts,
          mobile applications, web applications, decentralized applications,
          API, or any other service which Sombra.app operates (collectively
          ‘Services’). All policies are incorporated into these Terms. You agree
          to comply with all the above and all the matters as set out in these
          Terms when accessing or using our Services. For information regarding
          the processing of personal data, please see our Privacy Policy.
        </p>
        <p>
          Please read these terms carefully. They set out your rights and
          responsibilities when you use the Services, and other important
          information.
        </p>
        <p>
          You are entering into a contract with Sombra Network LLC, a USA based
          company. 
        </p>

        <h2>2. How do I accept these terms and conditions?</h2>
        <ol>
          <li>
            by clicking or tapping any button or box marked “accept” or “agree”
            (or a similar term) in connection with the term son the Sombra app
            or browser. 
          </li>
          <li>
            by using our services in any way you herby agree as a participant to
            the terms and conditions
          </li>
          <li>
            by accessing the sombra website and interacting with smart contracts
            on the platform 
          </li>
          <li>You are 18 years or older. </li>
          <li>
            You agree and understand that Sombra Network and affiliated partners
            are not liable for financial mistakes or problems attributed to user
            error, blockchain problems, or artist choices. Please refer to
            section 5 for more information.
          </li>
        </ol>
        <p>
          When you accept these terms, they are legally binding. Terms and
          conditions are subject to change as per section 17 below. Sombra will
          always announce changes prior to implementation. If you are a
          business/company/estate and you are using our network in any way, by
          accepting these terms are verifying that you have the authority on
          behalf of that business/company/brand and related individuals. If you
          do not have written authority, then you are not authorized to
          participate in Sombra Network or utilize its services. Sombra holds no
          liability for unauthorized publications if the artist confirms
          authenticity. 
        </p>
        <p>
          If you do not agree to these Terms, you must not access or use the
          Services or the App.
        </p>

        <h2>3. About Sombra Network</h2>
        <p>
          Sombra Network is a distributed application, functioning as a
          marketplace and social network, that runs on the Binance Smart Chain
          and Ethereum Mainnet, using specially developed smart contracts that
          provide an immutable ledger of all transactions that occur on Sombra
          Network (“Smart Contracts”). Using these Smart Contracts, users can
          create, buy, transfer and trade unique digital assets in the form of
          non-fungible tokens.
        </p>
        <p>
          Sombra Network is not involved in the actual transaction between
          buyers and sellers on the marketplace. We are an administrative
          platform that facilitates transactions between a buyer and a seller
          and helps maintain visual and auditory collections. Depending on the
          preference of the artist, Sombra does help facilitate conversions and
          uploads to maintain order and smooth user experience. Some artists are
          not well versed in the process of crypto and they have given us
          permission to act on their behalf to facilitate the transaction while
          maintaining personal agreements and responsibility for the sale
          itself. The contract for the sale is directly between buyer and
          seller.
        </p>
        <p>
          We have no control over, and do not guarantee the existence, quality,
          safety, or legality of, items advertised; the ability of sellers to
          sell items; the ability of buyers to pay for items; or that a buyer or
          seller will actually complete a transaction. We do our absolute best
          to verify and clear all art works and will work diligently to rectify
          any issues that arise.
        </p>
        <p>
          In this respect, you acknowledge and agree that Sombra Network is a
          non-custodial software provider that has developed this distributed
          and decentralized environment where you can autonomously and directly
          access the app without any involvement whatsoever by Sombra Network or
          any third party.
        </p>
        <p>
          Artists who elect to have Sombra manage their sales and conversion
          from BNB to USD are subject so management fees of 3% in addition to
          the network.
        </p>

        <h2>4. How can I use Sombra Network?</h2>
        <p>
          Sombra utilizes web 3 provider services on desktop users need to use a
          web 3 enabled browser such as Google Chrome or a web browser inside a
          web 3 enabled wallet browser such as Trust Wallet, Metamask or Sombra
          Wallet (October 2021)
        </p>
        <p>
          Once installed either on mobile or browser you will need to use
          Metamask / SW to interact with any smart contract and subsequently any
          NFT or game on the Sombra Network. Sombra does contain a transaction
          log per wallet/profile, but these web 3 applications can also track
          your transactions locally. 
        </p>
        <p>
          Sombra NEVER will have access or request any wallet information aside
          from your address or transaction hash. Your 12 word security phrase is
          NEVER to be given out to anyone including Sombra Staff.
        </p>
        <p>
          Your web 3 account and Metamask wallet/Sombra Wallet is your identity
          and keycard to the Sombra Network. Without it you cannot create a
          profile or gain entry to the Network. If you lose your account
          information, we cannot retrieve it nor any NFT attached to the
          account. User accounts live on the blockchain and Sombra does have
          access to change or alter user accounts aside from display features
          within your user profile on the Sombra Network. The App gives you the
          option to use, amongst others, MetaMask, WalletConnect, Portis or
          Fortmatic to connect your account to your web 3 compatible browser.
        </p>
        <p>
          Transactions that take place on the App are managed and confirmed via
          the Binance Smart blockchain. Your BSC wallet address and ledger will
          be made publicly visible whenever you engage in a transaction on
          Sombra Network. This is a function of the blockchain not Sombra
          Network. 
        </p>
        <p>
          Any transactions on Sombra Network are facilitated and run by
          MetaMask, WalletConnect, Fortis or Portmatic or the other web 3
          compatible browser you have chosen. These transactions are governed by
          the terms and conditions of those other browsers. 
        </p>

        <h2>5. Your Sombra User Profile</h2>
        <p>
          You must create an account with sombra.app to use the Services or the
          app. We will ask you specific information for the account to be
          verified. Once complete you can register to begin trading. By creating
          an account, you agree to provide accurate, current, and complete
          account information about yourself, and to maintain and promptly
          update, as necessary, your account information. For further
          information on how we use that information, please see our Privacy
          Policy.
        </p>
        <p>
          You understand that your Sombra Network account is limited solely to
          you and your wallet address/metamask or SW account. You agree that all
          transactions done through your wallet and account are your own
          responsibility and any 3rd party fraud or attempted theft is not the
          responsibility of Sombra Network. You understand that you are solely
          responsible for maintaining the security of your account and control
          over any usernames, private keys, or any other authentications or
          codes that you use to access the Services. Any unauthorized access to
          your account could result in the loss or theft of tokens and/or funds
          in any linked accounts. You understand and agree that you will not
          hold Sombra Network responsible for any issues related to managing and
          maintaining the security of your account and your web 3 wallet or
          blockchain accounts. You further understand and agree that we are not
          responsible (and you will not hold us responsible) for any
          unauthorized access to, or use of, your account or your MetaMask
          wallet (or other blockchain wallets and accounts) through Sombra
          Network or otherwise. 
        </p>
        <p>
          You alone are responsible for monitoring your account. If you notice
          any unauthorized or suspicious activity in your account, please notify
          us immediately at support@sombra.app
        </p>

        <h2>6. NFT Creation</h2>
        <p>
          On Sombra Network, users can create limited-edition digital files
          called NFTs, that contain unique metadata and content data. These
          files can be anything that is transferable as a digital file. NFTs
          range from video content, 3D files formats, audio, text, and code, and
          more. This content is owned by the user that created it (the
          “Artist”). When an Artist creates content, it is linked to a tokenized
          smart contract in the form of an NFT. Sombra Network and its artist
          mint their works on the blockchain which means that a contract is
          executed between the file and the blockchain which allows it to be
          uploaded and stored forever. Once on the blockchain the files cannot
          be removed but can be transferred to new owners as per their injected
          web 3 account. This is explained more in Section 10 below about
          intellectual property rights.
        </p>
        <p>
          Users must be approached by Sombra Network to list artworks. Users can
          apply to be reviewed to expedite the process if they wish
        </p>
        <h3>To apply for a review:</h3>
        <ul>
          <li>fill out prospective artist form on https://sombra.app</li>
          <li>Include social media and or website links</li>
          <li>provide an example of original artwork.</li>
        </ul>
        <p>
          Sombra Network holds the right to refuse any participant now or in the
          future for any reason it deems appropriate. Sombra Network does not
          guarantee success as an artist or sales. Sombra Network holds the
          right to remove artists if needed. 
        </p>
        <p>
          The Tokens can be sold or transferred to other people. When a Token is
          purchased or transferred by a person, the Ethereum token that it
          represents is automatically transferred to that person’s Ethereum
          address.
        </p>

        <h2>7. I am an artist. What should I know?</h2>
        <p>If you are an artist, you understand and agree that:</p>
        <ul>
          <li>
            you alone are responsible for the accuracy and integrity of your
            content uploaded either by yourself or as you direct Sombra Network
            to do
          </li>
          <li>
            any Content that violates any of Sombra Network’s policies may be
            delisted by Sombra Network and legal action taken if necessary 
          </li>
          <li>
            you must respect the intellectual property rights of others; and
          </li>
          <li>
            Artists will suggest the listing prices, but Sombra has final say in
            listing. This is to ensure realistic artist sales and a
            user-friendly experience. Sombra will always consult with each
            artist prior to final listing if the sale price differs from their
            request. 
          </li>
          <li>
            You reserve the right to remove unsold art from the platform but
            please not once minted to the blockchain the files are immutable. 
          </li>
        </ul>
        <p>
          Artists will not infringe on any known copyright, trademark, patent,
          moral, database or other intellectual property rights (the
          “Intellectual Property Rights”) that belong to other individuals,
          corporations, or estates. 
        </p>
        <p>
          Sombra Network reserves the right, at our sole discretion, to prohibit
          artists from using the Sombra Network services. We monitor all content
          from creators to prevent fraudulent activity or violation of these
          Terms. We DO NOT take responsibility for any infringements of
          associated artists, but we do attempt to rectify any issues or claims
          as best as possible and will disseminate associated information as per
          law enforcement requirements. In the event that you choose to burn
          your NFT tokens (e.g. by sending them to the zero address) you agree
          to bear the entire cost of any blockchain fees (such as gas) as well
          as the responsibility of burning them. Sombra is not able to burn NFT
          tokens on your behalf. Burning is the act of destroying a token by
          sending it to a contract address on the blockchain that is a zero
          address. When tokens are sent to zero addresses, they are not
          retrievable and are considered gone forever. Think of burning as
          sending your token to the phantom zone. It still exists but it is
          never coming back. Superman said so.
        </p>

        <h2>8. Ownership of NFTs and Original Artwork</h2>
        <p>
          Owning an NFT is just like owning a physical artwork, like an original
          painting or a print. Artists can choose how many versions are created
          of each artwork and based on that the rarity and price will change. In
          owning an NFT, you understand that no intellectual property is passed
          on to you from the artist unless specifically stated in the listing
          and subsequent smart contract.
        </p>
        <p>
          Users on the Sombra Network cannot assume any rights or ownership of
          the source works unless otherwise stated by the artist. In some
          instances, artists will commission Sombra to act as a broker to sell
          not only the NFT in question, but the IP associated with that NFT and
          source work. Only under this circumstance can an NFT owner assume the
          rights for reproduction and monetary gain from the content outside of
          a resale of that specific NFT. 
        </p>
        <p>
          Owning and downloading content via Sombra Network or directly from
          IFPS (Interplanetary File System), does not give you any intellectual
          property rights for the content unless context is as stated above. 
        </p>
        <p>
          As a buyer and owner of NFTs on the Sombra Network, you accept all
          risk associated with the purchase and abdicate Sombra Network of any
          liability associated with that and any transaction or purchase on the
          Sombra Network. It is up to the individual and artist to verify the
          authenticity of the artwork. Sombra Network and its staff will always
          assist to the best of their ability but cannot guarantee proper IP
          clearance.
        </p>
        <p>
          If you believe that any content hosted on the website infringes yours
          or someone else’s’ intellectual property rights, please let us know at
          support@sombra.app and prompt action will be taken.
        </p>
        <p>
          It is our policy to terminate or suspend the accounts of rulebreakers
          at any point. Don’t steal art and you won't get the ban hammer.
        </p>

        <h2>9. Intellectual property</h2>
        <p>When you buy an NFT with Sombra Network:</p>
        <ul>
          <li>
            you do not acquire any intellectual property rights with the NFT
            (unless otherwise stated). In NFT ownership, you receive a digital
            version of that artwork that lives on the blockchain and can only be
            accessed by you.
          </li>
          <li>
            As the owner, you have the right to display the NFT and to resell it
            on the secondary Sombra market.
          </li>
          <li>
            The original artist of the NFT retains the rights to the source
            derivative 
          </li>
          <li>You have no right to use the content for commercial purposes.</li>
        </ul>
        <p>
          In using the Sombra Network or its services, whether as a buyer or a
          seller, you agree you will not infringe any Copyright or Intellectual
          Property Rights of other entities. 
        </p>

        <h2>10. Sombra Token </h2>
        <p>
          Sombra Token was not created to give investors the opportunity to
          profit financially but to gain the ability to access unique
          one-of-a-kind artworks from the world’s top VFX artists as well as to
          create a self-sustaining globally connected ecosystem so artists can
          reach collectors worldwide. 
        </p>
        <p>
          Sombra Network and Sombra Token do not offer financial advice to
          holders or community members and cannot guarantee any profit from the
          derivative itself. SMBR Token is intended for on network use only and
          aims to become the currency of digital art dealing. 
        </p>
        <p>Sombra adheres to all US tax laws. </p>
        <p>
          Smart Contract Token details of the SMBR token are as follows: Sombra
          Network Utility is a custom contract. Every transaction that occurs on
          Pancake swap incurs a 6% fee. These fees are deactivated on the
          marketplace to maintain clean transactions, environmentally efficient
          gas usages, and accounting. 
        </p>
        <ul>
          <li>
            3% of the transaction is taxed and then re-distributed to the
            Liquidity Pool backing the token. This 3% fee helps maintain
            stability at the early stages of trading. Once the token reaches a
            stable point that fee can be removed.{" "}
          </li>
          <li>
            3% of the transaction is taxed and then re-distributed to holders of
            the SMBR token in correlation to the amount of SMBR token they are
            holding. More tokens held yields more tokens rewarded. 
          </li>
        </ul>

        <h2>11. US Customers</h2>
        <p>
          Sombra Network Utility Token is not intended for US customers and
          Sombra Network cannot assist US customers with issues that arise from
          purchase of tokens or subsequent trading on the Sombra Network. While
          we understand that via the nature of decentralized systems, we do not
          offer support to US customers and strongly warn against active
          trading. 
        </p>

        <h2>12. Fees and Payments</h2>
        <p>
          If you elect to purchase, trade, or create art on the Sombra Network
          in any way and/or engage in any financial transactions on the network
          you must use MetaMask (or other BSC MainNet compatible wallets and
          browsers). 
        </p>
        <p>
          Sombra app does not as a policy refund buyer. Sombra Network reserves
          the right to refund customers at their discretion and can assess each
          situation as needed. In general, Sombra cannot reverse transactions
          unless Sombra is acting as the direct broker for an artist. Even in
          that case, where Sombra is acting as a broker for said artist it is
          extremely rare that a reversal will occur. Please do not email us
          furious for a refund. We will do our best to accommodate your request,
          but we cannot guarantee it and as a policy do not give refunds. 
        </p>
        <p>
          Binance Smart Chain requires the payment of a transaction fee (gas
          fee) for every transaction that occurs on the BSC network. Gas fees
          power the blockchain network and are allocated to supporting nodes
          that accelerate and verify block transactions. 
        </p>
        <p>
          Sombra Network pays all gas fees for Artists minting artwork if said
          artwork has been approved for minting. 
        </p>
        <p>
          Sombra Network has a unique financial ecosystem that utilizes multiple
          tokens and in time multiple blockchains. At launch Sombra Network will
          run on BNB, SMBR and BUSD. 
        </p>
        <p>BNB - Binance Smart Chain SMBR - Sombra Token</p>
        <p>
          With time WBTC and WETH (wrapped) on the BSC chain will be added as
          well as full Ethereum support once V3 is live and stable.
        </p>
        <p>Sombra Network sale transactions are broken down as follows:</p>
        <ul>
          <li>80% of NFT sales go to artists (BNB--&gt;BUSD--&gt;USD)</li>
          <li>10% of NFT sales go to Sombra Network (BNB)</li>
          <li>
            10% of NFT sales go to Sombra Network Utility Token holders (BNB
            --&gt;SMBR)
          </li>
        </ul>
        <p>
          You acknowledge and agree that these % breakdowns as listed above are
          automatically executed at the sale of each NFT as per marketplace
          smart contracts and that unless requested by the artist are handled by
          the artists themselves. Sombra does offer artists the option to manage
          their sales, but all sales are immediately converted into BUSD and
          then USD for payment. Artists who choose this payment method must also
          submit a 1099 for tax purposes if a US resident. In addition to the
          commission above, for all proceeding secondary sales on the Sombra
          Network Marketplace, the artist receives 12% commission of the total
          value of that transaction.
        </p>
        <p>For any proceeding secondary sales made on the Sombra Network:</p>
        <ul>
          <li>85% of NFT sales goes to reseller (BNB)</li>
          <li>11% of NFT sale goes to artist (BUSD)</li>
          <li>2% of NFT sales go to Sombra Network (BNB)</li>
          <li>
            2% of NFT sales go to Sombra Network Utility Token holders (BNB
            --&gt;SMBR)
          </li>
        </ul>

        <h2>13. Risks associated with Cryptography</h2>
        <p>You accept and acknowledge each of the following risks:</p>
        <ul>
          <li>
            The prices of blockchain assets are extremely volatile and cannot be
            predicted nor controlled by the Sombra Network in any way.
            Fluctuations in the price of other digital assets could result in a
            material adverse effect on the value of Sombra Network Utility Token
            as well as any underlying assets such as BNB, which may also be
            subject to significant price volatility. 
          </li>
          <li>
            Sombra Network cannot guarantee that any purchasers of Sombra
            Network Utility Token or NFTs will not lose their investment or a
            portion thereof. 
          </li>
          <li>
            You also acknowledge that the cost of performing transactions on
            emerging technologies such as blockchain is variable and may change
            at any time based on global and governmental factors which in turn
            could cause impact to any activities taking place on any supported
            blockchain.
          </li>
          <li>
            You acknowledge these risks and agree that Sombra Network cannot be
            held liable for such fluctuations, losses, or changes to policy,
            internally as well as externally. 
          </li>
          <li>
            You expressly understand and agree that your access to and use of
            the services, the website and the app is done at your own risk and
            that the Sombra Network is provided on an “as is” basis without any
            warranties or guarantees of any kind. 
          </li>
          <li>
            To the fullest extent possible pursuant to applicable law, we, our
            subsidiaries, affiliates, and licensors make no warranties regarding
            the Sombra Network, website, app or related services.
          </li>
          <li>
            The Sombra Network browser and app does not store, send, or receive
            NFT token for ownership. Virtue of ownership record is maintained on
            Sombra Network supported blockchains such as Binance and Ethereum.
            All transfers of ownership are done via blockchain and through your
            web 3 injected account that Sombra Network has no control over. 
          </li>
          <li>
            NFTs are intangible in nature but can be upgraded to physical
            displays. These displayed are 2ndary objects that have no connection
            to the NFT aside from being a vehicle of delivery for that or any
            NFT asset as recorded by the connected web 3 wallet. Sombra Network
            is not obligated to provide a physical representation of any NFT
            listed on its marketplace unless specifically stated.
          </li>
          <li>
            Digital assets exist only by virtue of the ownership record
            maintained in the Binance and Ethereum blockchains. All Smart
            Contracts are executed on the decentralized ledgers within these
            blockchains Sombra supports. Sombra Network has no control over, and
            makes no guarantees, warranties, or promises to any smart contract
            executed on the Sombra Network. 
          </li>
          <li>
            A full code audit will be conducted but will not serve as a
            guarantee of any kind. 
          </li>
          <li>
            Sombra Network is a non-custodial software service provider. In this
            respect we do not own or control NFTs or the content being sold. Due
            to the digital nature of NFTs, and the nature of cryptography, NFTs
            can become corrupted, and links associated with those NFTs can
            disappear. As a buyer and seller, you understand that this is a risk
            when using the services of Sombra Network and Sombra Network will in
            no way be held liable due to any loss or corruption of an NFT or its
            content. Furthermore, you understand that Sombra Network has no
            control whatsoever over the Interplanetary Filing System or the
            risks associated with using that system.
          </li>
          <li>
            Sombra Network and its affiliated partners/collaborators will not be
            liable to any user for losses incurred as the result of use of any
            supported blockchain networks. This includes, but is not limited to,
            losses, damages or claims arising from (i) user error (e.g.
            forgotten access information (such as passwords, private keys, seeds
            or mnemonics or incorrectly construed Smart Contracts or other
            transactions); (ii) server failure or data loss; (iii) corrupted
            wallet files; (iv) unauthorized access or activities by third
            parties, including but not limited to the use of viruses, phishing,
            or other means of attack against the Sombra Network, the Binance
            Smart Chain/Ethereum network, MetaMask or any other web 3 compatible
            browser or wallet. 
          </li>
          <li>
            Sombra Network is not responsible for losses due to blockchains or
            any other features of the Binance Smart Chain, Ethereum network or
            any blockchain used via MetaMask or any other web 3 compatible
            browser or wallet, including but not limited to late report (or no
            report) by developers or representatives of any issues with the
            blockchain supporting the blockchain networks used on the Sombra
            Network, including forks, technical node issues, API backend
            problems or any other issues having fund losses as a result.
          </li>
          <li>
            There are inherent security risks in providing information and
            dealing online over the internet. Sombra Network implements
            appropriate technological and internal safeguards to reduce user
            risks which could result in the accidental or unlawful destruction,
            loss, alteration, disclosure, or access to your information. We also
            consistently assess our network safety and release periodic updates
            to further improve safety.
          </li>
          <li>
            There are risks associated with using an internet associated digital
            currency, including, but not limited to, the risk of hardware
            problems, software and internet connections problems, the risk of
            malicious software introduction, the risk that third parties may
            obtain unauthorized access to your information or wallet if proper
            precautions are not taken on the user end. 
          </li>
          <li>
            You accept and acknowledge that Sombra Network will not be
            responsible for any communication failures, disruptions, errors,
            distortions or delays you may experience when using any blockchain
            network or associated service for any reason.
          </li>
          <li>
            A lack of use or public interest in the creation and development of
            distributed ecosystems could negatively impact the development of
            the Sombra Network ecosystem, and therefore the potential utility or
            value of NFTs minted on our ecosystem. This is true for all NFTs.
          </li>
          <li>
            The Sombra Network, Binance Smart Chain, Ethereum Network, and any
            digital assets could be impacted by one or more regulatory inquiries
            or regulatory action, which could impede or limit the ability of
            Sombra Network to continue to develop, and/or could impede or limit
            your ability to access or use the Sombra Network or supported
            blockchains, including access to your NFTs, digital assets, or
            funds. New government regulations worldwide or newly implemented
            policies may materially adversely affect the development of the
            Sombra ecosystem, and therefore the potential utility or value of
            Sombra Network.
          </li>
          <li>
            Sombra Network maintains an API backend that is used to connect to
            different applications such as AR platforms and art frame displays.
            Sombra Network makes no guarantees or promises regarding future
            maintenance of this API backend, and you assume full risk of your
            NFT becoming incompatible with said connections in the future.
          </li>
          <li>
            Upgrades by Ethereum to the Ethereum platform, a hard fork in the
            Ethereum platform, or a change in how transactions are confirmed on
            the Ethereum platform may have unintended, adverse effects on all
            blockchains using the standards as ERC-20, ERC-721, EIP-2981,
            EIP-998, EIP-2309 or other bespoke smart contracts, including the
            Sombra Network ecosystem. This is true as well for any updates,
            forks, or changes to the Binance ecosystem. Sombra will not support
            the Ethereum Blockchain until V3 has been released and tested.
          </li>
          <li>
            You acknowledge and understand that cryptography is a progressing
            field. Advances in code cracking or technical advances such as the
            development of quantum computers may present risks to
            cryptocurrencies, digital assets, and the Sombra Network, which
            could result in the theft or loss of your digital assets or NFTs.
            Sombra Network intends to update the protocol underlying the
            application and services offered to account for any advances in
            cryptography and to incorporate additional security measures
            presented. Additional security technology being available or
            implemented does not guarantee or otherwise represent full security
            of the system. By using Sombra Network, you acknowledge these
            inherent risks. As stated in this document. 
          </li>
          <li>
            Our smart contracts are fully open-source and verified on the
            blockchain for analysis. That said, you acknowledge that the Sombra
            Network is subject to flaws and acknowledge that you are solely
            responsible for evaluating any code provided by the Sombra Network.
            This warning and others provided in these terms and conditions as
            presented by Sombra Network LLC is in no way evidence or represents
            an ongoing requirement to alert you to all potential risks of
            utilizing or accessing the Sombra Network.
          </li>
          <li>
            Any use or interaction with the Sombra Network requires a
            comprehensive understanding of applied cryptography and computer
            science to appreciate inherent risks, including those listed above.
            You represent and warrant that you possess relevant knowledge and
            skills. Any reference to a type of digital asset on the Sombra
            Network does not indicate our approval or disapproval of the
            underlying technology regarding the digital asset and should not be
            used as a substitute for your own understanding of the risks
            specific to each type of digital asset. We make no warranty as to
            the suitability of the digital assets referenced on the Sombra
            Network and assume no fiduciary duty in our relations with users.
          </li>
          <li>
            Use of the Sombra Network, for creating, buying or selling and
            trading digital assets, may carry financial risk. Digital assets
            are, by their nature, highly experimental, subject to risk and
            volatile. You acknowledge and agree that you will access and use the
            Sombra Network at your own risk. The risk of loss in trading digital
            assets can be substantial. You should, therefore, carefully consider
            whether creating, buying, or selling digital assets is suitable for
            you. 
          </li>
          <li>
            By using the Sombra Network, you represent that you have been, are
            and will be solely responsible for making your own independent
            appraisal and investigations into the risks of any transaction and
            the underlying digital assets associated with them. 
          </li>
          <li>
            You represent that you have sufficient knowledge, market
            sophistication, professional advice, and experience to make your own
            evaluation of the merits and risks of any transaction conducted via
            the Sombra Network or any underlying digital asset. 
          </li>
          <li>
            You accept all risks and consequences of using the Sombra Network,
            including the risk that you may lose access to your digital assets
            indefinitely. All transaction decisions are made solely by you.
            Notwithstanding anything in these terms, we accept no responsibility
            whatsoever for and will under no circumstances be liable to you in
            connection with the use of the Sombra Network for performing digital
            asset transactions.
          </li>
          <li>
             Sombra Network cannot give financial advice and cannot make any
            predictions of price, financial change or otherwise engage in
            discussions about finances aside from facts regarding transactions.
            Sombra Network makes no claims or guarantees of profit and demands
            each user understand the risks associated with using the Sombra
            Network and blockchain technologies as stated in this document. The
            underlying technology powering the Sombra Network is new and
            evolving and as such users acknowledge that using Sombra Network can
            present extreme risks that are inherent to the use of decentralized
            networks and ledgers and cannot be controlled by Sombra Network or
            its staff.
          </li>
          <li>
            You are aware of and accept the risk of operational challenges on
            the Sombra Network. The Sombra Network may experience sophisticated
            cyber-attacks, unexpected surges in activity or other operational or
            technical difficulties that may cause interruptions to or delays on
            the app. You agree to accept the risk of technology failure
            resulting from unanticipated or heightened network and difficulties.
            We do not guarantee that Sombra Network is or will remain updated,
            complete, correct, or secure, or that access to the App will be
            uninterrupted. Sombra Network may include inaccuracies, errors and
            materials that violate or conflict with these terms.  Users must
            verify all information on the app each time using it and must use it
            at their own risk. 
          </li>
        </ul>

        <h2>14. Acceptable use</h2>
        <p>
          In connection with using or accessing the services or the Sombra
          Network, you will not:
        </p>
        <ul>
          <li>
            breach or circumvent any laws, third-party rights or our systems or
            policies.
          </li>
          <li>
            sell any items that infringe on the copyright, trademark, or
            intellectual property of third parties.
          </li>
          <li>
            use our services if you are not able to form legally binding
            contracts (for example if you are under 18).
          </li>
          <li>
            manipulate the price of any item or interfere with any other user’s
            listings.
          </li>
          <li>
            post false, inaccurate, misleading, defamatory, or libelous content.
          </li>
          <li>transfer your account to another party without our consent.</li>
          <li>
            use the contact information of other users for any purpose other
            than in relation to a specific transaction on the website (which
            includes using this information to send marketing materials directly
            to Sombra Network users unless the user has given explicit consent
            to receiving these materials).
          </li>
          <li>
            distribute viruses or any other technologies that may harm Sombra
            Network, or the interests or property of Sombra Network users.
          </li>
          <li>incite violence or self-harm</li>
          <li>
            use any robot, sniper, spider, scraper, or other automated means to
            access our services for any purpose.
          </li>
          <li>
            bypass our robot exclusion headers, interfere with the working of
            our Services, or impose an unreasonable or disproportionately large
            load on our infrastructure.
          </li>
          <li>
            export or re-export any application or tools except in compliance
            with the export control laws of any relevant jurisdictions and in
            accordance with posted rules and restrictions.
          </li>
          <li>
            commercialize any Sombra Network application or any information or
            software associated with such application.
          </li>
          <li>
            harvest or otherwise collect information about users without their
            consent; or
          </li>
          <li>
            circumvent any technical measures we use to provide the Services.
          </li>
        </ul>
        <p>
          You agree that we will commence supplying our Services to you as soon
          as you accept these Terms. Additionally, we reserve the right to vary
          or terminate all or part of our Services and/or not provide all or
          part of our Services to anyone for any reason and/or period, at our
          discretion.
        </p>

        <h2>15. Content</h2>
        <p>
          When providing content, you grant Sombra Network a non-exclusive,
          worldwide, perpetual (or for the duration of any copyright or other
          rights in such content), irrevocable, royalty-free, sublicensable
          (through multiple tiers) right to use the content (including, without
          limitation, creating and using derivative works), and you authorize
          Sombra Network to exercise any and all copyright, trademark,
          publicity, database or other intellectual property rights you have in
          or to the content in any media known now or developed in the future.
          Furthermore, to the fullest extent permitted under applicable law, you
          waive your rights in the content and promise not to assert such rights
          or any other intellectual property rights you have in the content
          against Sombra Network or affiliated partners, our sublicensees or our
          assignees.
        </p>
        <p>
          You agree that we are not responsible for examining or warranting the
          listings or content provided by artists or third parties through the
          services, and that you will not hold or attempt to hold Sombra
          Network, partners, affiliates or any associated brand and organization
          liable for inaccuracies or infringement of any kind. 
        </p>
        <p>
          We neither own nor control any third-party browsers, wallets, wallet
          connectors or blockchain networks, or any other third-party site,
          product, or service that you might access, visit, or use for the
          purpose of enabling you to use the various features of the App (the
          “Third Party Sites”). You understand and agree that your use of any
          third-party site is subject to any terms of use and/or privacy policy
          provided by such third-party site. We are not a party to any such
          agreement. You should review any terms of use and privacy policy
          provided by such third-party site and should make whatever
          investigation you feel necessary or appropriate with any transaction
          with any third party.
        </p>
        <p>
          Sombra Network provides these third-party sites only as a convenience
          and we do not review, approve, monitor, endorse, warrant, or make any
          representations with respect to third party sites, or their products
          or services. All third-party sites are used by you at your own risk.
        </p>
        <p>
          We will not be liable for the acts or omissions of any third-party
          sites, nor will we be liable for any damage that you may suffer
          because of your transactions or any other interaction with any
          third-party site.
        </p>
        <p>
          Where our site contains links to other sites and resources provided by
          any third parties, these links are provided to you for information
          only. Such links should not be interpreted as approval by us of those
          linked websites or information you may obtain from them. We have no
          control over the contents of those sites or resources. Please follow
          those external 3rd party links at your own risk. Sombra network is not
          liable for anything that occurs via a 3rd party site. 
        </p>

        <h2>16. Content upload and Storage</h2>
        <p>
          Sombra Network maintains an internal API via IFPS and a Binance node
          strictly for visualization purposes on the app and for users
          connecting to external products sold and supported by Sombra. Sombra
          as stated above makes no guarantees of service functionality and
          future use of the node or any other nodes on Binance Smart Chain or
          Ethereum once supported.
        </p>
        <p>The API backend and Binance node </p>

        <h2>17. Changes to these Terms</h2>
        <p>
          Sombra Network may amend these terms at any time. The most current
          version of these terms will be posted on the site tagged with the
          date. You shall be responsible for reviewing and becoming familiar
          with any such modifications. You waive any right you may have to
          receive specific notice of such changes or modifications. Use of the
          Sombra Network by you after any modification to the Terms constitutes
          your acceptance of the terms as modified. If you do not agree to the
          terms in effect when you access or use Sombra Network, you must not
          use Sombra services. We may, at any time and without liability or
          prior notice, modify or discontinue all or part of the Sombra Network
          (including access to the browser and app via any third-party links).
        </p>
        <p>These terms may not be otherwise amended.</p>

        <h2>18. Privacy</h2>
        <p>
          Sombra Network is the data controller for your information, and we
          have specific obligations to you under data protection law. You can
          find out more about what information we use, what we use it for and
          who we share it with in the Sombra Network privacy policy. Our privacy
          policy also contains details about your legal rights in relation to
          your information (but your legal rights will vary depending on where
          you are located).
        </p>

        <h2>19. Indemnity</h2>
        <p>
          You agree to indemnify, defend and hold harmless Sombra Network and
          its subsidiaries, affiliates, officers, agents, employees,
          advertisers, licensors, suppliers or partners from and against any
          claim, liability, loss, damage (actual and consequential) of any kind
          or nature, suit, judgment, litigation cost, and lawyers’ fees arising
          out of or in any way related to (i) your breach of these Terms, (ii)
          your misuse of the service or the Sombra Network, (iii) your violation
          of applicable laws, rules, regulations or the rights of any third
          party and (iv) any of your acts or omissions that implicate publicity
          rights, defamation or invasion of privacy in connection with your
          access to or use of the Sombra Network. Sombra Network reserves the
          right, at its own expense, to assume exclusive defense and control of
          any matter otherwise subject to indemnification by you and, in such
          case, you agree to cooperate with Sombra Network in the defense of
          such matter.
        </p>

        <h2>20. Liability</h2>
        <p>
          We try to keep the services and the app safe, secure, and functioning
          properly, but we cannot guarantee the continuous operation of or
          access to our services as explained in the above terms and conditions
          as well as below.
        </p>
        <p>
          We (including our parent, subsidiaries, affiliates, directors, agents,
          and employees) shall not be liable to you in contract, tort (including
          negligence) or otherwise for any business losses, such as loss of
          data, profits, revenue, business, opportunity, goodwill, libel,
          reputation, or business interruption or for any losses which are not
          reasonably foreseeable by us arising, directly or indirectly from:
        </p>
        <ul>
          <li>your use of or your inability to use our Services.</li>
          <li>delays or disruptions in our Services.</li>
          <li>
            viruses or other malicious software obtained by accessing, or
            linking to, our Services.
          </li>
          <li>
            glitches, bugs, errors, or inaccuracies of any kind in Sombra
            Services.
          </li>
          <li>damage to your hardware device from the use of any service.</li>
          <li>
            the content, actions, or inactions of third parties, including items
            listed using our Services.
          </li>
          <li>
            a suspension or other action taken with respect to your account; or
          </li>
          <li>
            your need to modify practices, content, or behavior or your loss of
            or inability to do business, because of changes to these terms or
            our policies.
          </li>
        </ul>
        <p>
          Some jurisdictions do not allow the disclaimer of warranties or
          exclusion of damages, so such disclaimers and exclusions may not apply
          to you. This is especially so if you are a consumer.
        </p>
        <p>
          You accept sole responsibility for the legality of your actions under
          laws applying to you and the legality of any items you list on any of
          our sites. 
        </p>
        <p>
          Although we use techniques that aim to verify the accuracy and truth
          of the information provided by our users, user verification on the
          internet is difficult. We cannot and do not confirm, and are not
          responsible for ensuring, the accuracy or truthfulness of users’
          purported identities or the validity of the information which they
          provide to us or post on our sites.
        </p>
        <p>
          Regardless of the previous paragraphs, if we are found to be liable,
          our liability to you or to any third party is limited to the greater
          of (a) the amount of fees in dispute not to exceed the total fees
          which you paid to us in the 12 months prior to the action giving rise
          to the liability, or © $100
        </p>

        <h2>21. Compensation</h2>
        <p>
          You will compensate Sombra Network in full (and our officers,
          directors, agents, subsidiaries, joint ventures, and employees) for
          any losses or costs, including reasonable legal fees, we incur arising
          out of any breach by you of these terms, your improper use of the
          services or your breach of any law or the rights of a third party as
          well as any related use to your account.
        </p>

        <h2>22. Legal Disputes</h2>
        <p>
          Any claim, dispute or matter arising under or in connection with these
          terms shall be governed and construed in all respects by the laws of
          the United States of America.
        </p>
        <p>
          In simple terms, “non-exclusive jurisdiction of the USA courts” means
          that if you were able to bring a claim arising from or in connection
          with these terms against Sombra Network in court, an acceptable court
          would be a court located within the United States and all
          international cases shall use United States law as a base of operation
          and legal guidance.{" "}
        </p>

        <h2>23. General</h2>
        <p>
          If any provision of these terms is held to be invalid, void or for any
          reason unenforceable, such provision shall be struck out and shall not
          affect the validity and enforceability of the remaining provisions.
        </p>
        <p>
          We may assign our rights and obligations under these terms (but
          without your prior express consent) if we assign these terms on the
          same terms or terms that are no less advantageous to you.
        </p>
        <p>
          If you have a dispute with one or more users, you release us (and our
          affiliates and subsidiaries, and our and their respective officers,
          directors, employees, and agents) from claims, demands and damages
          (actual and consequential) of every kind and nature, known and
          unknown, arising out of or in any way connected with such disputes.
        </p>
        <p>
          No agency, partnership, joint venture, employee-employer, or
          franchiser-franchisee relationship is intended or created by these
          terms. A person who is not a party to these terms has no right to
          enforce any part of these terms. 
        </p>
        <p>
          No agency, partnership, joint venture, employee-employer, or
          franchiser-franchisee relationship established now or in the future
          shall bear any responsibility to any user transactions or conduct on
          the Sombra Network.
        </p>
        <p>
          No 3rd party product or vendor associated with Sombra Network is the
          responsibility of Sombra Network and its staff. Physical NFT product
          upgrades are technology created, curated, and supported by 3rd party
          vendors and cannot be altered or updated by Sombra Network now or in
          the future. For all issues related to 3rd party products you must
          consult the vendor even if the product is resold by Sombra Network.
          This is true for products made by partnered vendors Looking Glass
          Factory, TokenFrame and Infinite Objects. 
        </p>
        <p>
          If any provision or section of these terms becomes invalid, illegal,
          or unenforceable, it shall be deemed modified to the minimum extent
          necessary to make it valid, legal, and enforceable. If such
          modification is not possible, the relevant provision or part-provision
          shall be deemed deleted. Any modification to or deletion of a
          provision or part-provision under this section 18 shall not affect the
          validity and enforceability of the rest of these terms.
        </p>

        <h2>24. Feedback and contact details</h2>
        <p>
          You may choose to submit comments, bug reports, ideas or other
          feedback about Sombra Network and associated games about how to
          improve the platform (collectively, “Feedback”) by emailing us. We are
          free to use ideas from the community to improve the platform. If you
          submit an idea, please note you are offering it up without expectation
          of royalties. 
        </p>
        <p>
          Users with questions, complaints or claims with respect to the
          website, the Services or the App can be made by contacting us at
          support@sombra.app
        </p>
      </div>
    </div>
  );
};

export default Terms;
