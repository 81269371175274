import React from "react";
import styled from "styled-components";
import logo from "../../assets/images/SINS_LOGO_WHite.png";
import { colors } from "../../styles";
import { useHistory } from "react-router-dom";

const LogoContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  @media (max-width: 600px) {
  }
  &:hover {
    cursor: pointer;
  }
`;

const LogoTitle = styled.h3`
  font-weight: 700;
  color: rgb(${colors.fontColor});
  font-size: 25px;
  margin: 0 0.5em;
  font-family: Utopia Std;
  font-weight: 400;
`;

const LoagImage = styled.img`
  height: 60px;
  margin-left: 30px;
  @media (max-width: 600px) {
    width: 50px;
    margin-left: 10px;
    display: none;
  }
`;

const imgStyle = {
  width: 100,
};

function LogoArea() {
  let history = useHistory();
  return (
    <LogoContainer
      onClick={() => {
        history.push(`/`);
      }}
    >
      <LoagImage src={logo} alt="logo" />
      {/* <LogoTitle>LOGO</LogoTitle> */}
    </LogoContainer>
  );
}

export default LogoArea;
