import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

import { colors } from "../../styles";
import discordImage from "../../assets/images/discordImage.png";
import ethImage from "../../assets/images/ethimage.png";
import sombraImage from "../../assets/images/sombraImage.png";
import SINS_tag from "../../assets/images/SINS_LOGO.png";

interface MenuItemProps {
  isActive: string;
  current: string;
}

const MenuContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in-out;
  justify-content: space-between;
  flex-direction: column;
  opacity: 1;
  width: 100%;
  height: 630px;
  left: calc((100% - 300px) / 2);
  padding-top: 30px;
  padding-bottom: 30px !important;
  z-index: 1000;
  backgound-color: transparent;
  background-image: url(/images/menuBackImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media (max-width: 960px) {
    height: 100%;
    padding-bottom: 30px !important;
    width: 100%;
    left: 0;
  }
`;
const MmenuItem = styled.li<MenuItemProps>`
  color: rgb(${colors.fontColor});
  font-size: 20px;
  font-weight: bold;
  font-family: HRobotikSYSTM-Regular;
  margin: 0 1em;
  text-shadow: ${(props) =>
    props.current === props.isActive
      ? "1px 1px 2px white, 0 0 1em white, 0 0 0.2em darkblue"
      : "none"};

  @media (max-width: 1100px) {
    margin: 0 0.3em;
  }
  @media (max-width: 960px) {
    margin: 0.5em 0;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
    text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.2em darkblue;
  }
`;
const TokenValueArea2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const TelegramImage = styled.img`
  width: 44px;
  margin-right: 5px;
`;
const MintButton2 = styled.button`
  background-color: transparent;
  margin: 0;
  padding-left: 3px;
  font-size: 20px;
  color: white;
  font-family: HRobotikSYSTM-Regular;
  font-weight: 700;
  @media (max-width: 600px) {
    height: 40px;
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
    text-shadow: 0 4px 8px 0 rgba(${colors.main}),
      0 6px 10px 0 rgba(${colors.main});
  }
`;
const MintButton1 = styled.button`
  background-color: transparent;
  margin: 0;
  font-size: 18px;
  color: white;
  font-family: MonumentExtended-Regular;
  padding: 0;
  @media (max-width: 600px) {
    height: 40px;
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
    text-shadow: 0 4px 8px 0 rgba(${colors.main}),
      0 6px 10px 0 rgba(${colors.main});
  }
`;
const MediumImage2 = styled.img`
  width: 22px;
  margin-right: 7px;
  @media (max-width: 1035px) {
    width: 20px;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

const MenuContent = ({}) => {
  const [currentWidth, setCurrentWidth] = useState(0);
  const [isActive, setIsActive] = useState("");
  const location = useLocation();
  const [ethePrice, setEthePrice] = useState("");
  const [sombraPrice, setSombraPrice] = useState("");
  const [menuFlag, setMenuFlag] = useState(true);
  useEffect(() => {
    setIsActive(location.pathname);
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
    )
      .then((response) => response.json())
      .then((data) => {
        let ethereumUsd = data["ethereum"].usd;
        setEthePrice(parseFloat(ethereumUsd).toFixed(2));
      });
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=sombra-network&vs_currencies=usd"
    )
      .then((response) => response.json())
      .then((data) => {
        let sombraUsd = data["sombra-network"].usd;
        console.log("data.price1", sombraUsd);
        setSombraPrice(parseFloat(sombraUsd).toFixed(2));
      });
  }, [location.pathname]);
  useEffect(() => {
    function updateSize() {
      setCurrentWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  let history = useHistory();
  const navigateTo = (targetPath: string) => {
    history.push(`/${targetPath}`);
  };
  const handleClose = () => {
    setMenuFlag(false);
  };
  return (
    <MenuContainer>
      <img src={SINS_tag} style={{ width: 170 }} alt="" />
      <MmenuItem onClick={() => navigateTo("")} isActive={isActive} current="/">
        Mint
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("rarity")}
        isActive={isActive}
        current="/rarity"
      >
        Rarity
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("browse")}
        isActive={isActive}
        current="/browse"
      >
        Browse
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("story")}
        isActive={isActive}
        current="/story"
      >
        Story
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("stake")}
        isActive={isActive}
        current="/stake"
      >
        Staking
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("smbr")}
        isActive={isActive}
        current="/smbr"
      >
        SMBR
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("game")}
        isActive={isActive}
        current="/game"
      >
        Game
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("tvshow")}
        isActive={isActive}
        current="/mynfts"
      >
        TV Show
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("bodega")}
        isActive={isActive}
        current="/bodega"
      >
        Bodega
      </MmenuItem>
      <MmenuItem
        onClick={() => navigateTo("litepaper")}
        isActive={isActive}
        current="/litepaper"
      >
        Lite Paper
      </MmenuItem>
      <TokenValueArea2
        onClick={() => {
          window.open("https://discord.gg/TFax3brq52", "_blank");
        }}
      >
        <TelegramImage src={discordImage} alt="" />
        <MintButton2>Discord</MintButton2>
      </TokenValueArea2>
      <TokenValueArea2
        onClick={() => {
          window.open(
            "https://coinmarketcap.com/currencies/ethereum/",
            "_blank"
          );
        }}
      >
        <MediumImage2 src={ethImage} alt="" />
        <MintButton1>ETH = ${ethePrice}</MintButton1>
      </TokenValueArea2>
      <TokenValueArea2
        onClick={() => {
          window.open(
            "https://coinmarketcap.com/currencies/sombra-network/",
            "_blank"
          );
        }}
      >
        <MediumImage2 src={sombraImage} alt="" />
        <MintButton1>SMBR = ${sombraPrice}</MintButton1>
      </TokenValueArea2>
    </MenuContainer>
  );
};

export default MenuContent;
