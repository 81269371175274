import React from "react";
import styled from "styled-components";

import smbrImage from "../../assets/images/smbrImage.png";
import smbrTitle from "../../assets/images/smbrTitle.png";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

interface Props {
  ownNFT: Array<any>;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // @media (max-width: 600px) {
  //   margin-top: 50px;
  // }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const TitleImage = styled.img`
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    margin-top: 30px;
  }
`;
const TopImage = styled.img`
  width: 200px;
  margin: 0 auto;
`;

const SmbrContainer: React.FC<Props> = ({}) => {
  return (
    <Content>
      {/* <TopTitle>SMBR TOKEN</TopTitle> */}
      <TitleImage src={smbrTitle} alt="" />
      <TopImage src={smbrImage} alt="" />
    </Content>
  );
};

export default SmbrContainer;
