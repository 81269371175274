import { useEffect, useState } from "react";
import styled from "styled-components";
import RarityHeader from "../components/Home/RarityHeader";
import { colors } from "../styles";
import HomeContainer from "../components/Home/HomeContainer";
import { useHistory } from "react-router-dom";
import stakingTitle from "../assets/images/stakingTitle.png";
import logo from "../assets/images/sombraImage.png";
import darkImage from "../assets/images/darkImage.png";
import stakingInstBtn from "../assets/images/stakingInstBtn.png";
import stakingConnectBtn from "../assets/images/stakingConnectBtn.png";
import stakingSwitchBtn from "../assets/images/stakingSwitchBtn.png";
import stakedNftImage from "../assets/images/stakedNftImage.png";
import seeAllBtnImage from "../assets/images/seeAllBtnImage.png";
import stakeBtnBackImage from "../assets/images/stakeBtnBackImage.png";
import withdrawBtnImage from "../assets/images/withdrawBtnImage.png";
import harvestBtnImage from "../assets/images/harvestBtnImage.png";
import Container from "@material-ui/core/Container";
import styles from "../components/Home/Footer.module.css";
import Dialog from "@material-ui/core/Dialog";
import sinsImage from "src/assets/images/SINS_LOGO_WHite.png";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  amount: number;
  totalPrice: number;
  balance: number;
  ownNFT: Array<any>;
  add_chain_with_web3: () => void;
  onCallHarvest: () => void;
  onCallStake: (stakePrice: any) => void;
  onCallWithDraw: (stakePrice: any) => void;
  connectFlag: boolean;
  isloading: boolean;
  smbrTokenBalance: number;
  stakedTokenBalance: number;
  earnedTokenBalance: number;
  stakeLoading: number;
  withdrawLoading: number;
  harvestLoading: number;
  bonuses: any;
  lockedNFT: Array<any>;
  lockLoading: number;
  contractAddress: any;
  stakeFlag: string;
  onCallLock: (tokenId: any) => void;
  onCallUnlock: (tokenId: any) => void;
  onCallCheckStakable: (tokenId: any) => void;
  onCallBoostStaking: (tokenId: any) => void;
}

const MintContainer = styled.div`
  background-image: url(/images/stakingBackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  @media (max-width: 600px) {
    padding-bottom: 70px;
    padding-top: 50px;
  }
`;

const WallectConnectionArea = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  flex-direction: column;
  .instruction{
    display: flex;
    position: absolute;
    right: 0;
    img{
      width: 160px;
      &:hover{
        cursor: pointer;
      }
    }
  }
  @media (max-width: 620px) {
    img{
      width: 180px;
    }
    .instruction{
      display: flex;
      position: relative;
      width: 100%;
      flex-direction: column;
      align-items: end;
      img{
        margin-left: auto;
        width: 130px;
        margin-top: 0px;
        margin-bottom: -50px;
        align-self: end;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
`

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 0 0 0;
  @media (max-width: 720px) {
    ppadding: 0;
  }
  @media (max-width: 550px) {
    height: 100%;
  }
`;
const StackInfoArea = styled.div`
  background-image: url(/images/stakingPanelBackImg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 1100px;
  //   height: 357px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 95%;
  }
`;
const TokenNameArea = styled.div`
  displa: flex;
  flex-direction: column;
  font-family: MonumentExtended-Regular;
  margin-left: 20px;
  margin-top: 10px;
`;
const TokenNameArea1 = styled.div`
  displa: flex;
  flex-direction: column;
  margin-left: 20px;
  @media (max-width: 720px) {
    margin-left: 0px;
  }
`;
const TokenNameArea2 = styled.div`
  displa: flex;
  flex-direction: column;
  margin-left: 20px;
  @media (max-width: 720px) {
    margin-left: 0px;
    margin-right: 40px;
  }
`;
const ValueInputBox = styled.input`
  font-size: 17px;
  font-family: MonumentExtended-Regular;
  padding-left: 15px;
  border-width: 0px;
  width: 50%;
  background-color: transparent;
  color: white;
  &:focus {
    outline: none;
  }
`;
const JumpImage = styled.img`
  width: 12px;
  height: 10px;
`;
const MaxValueButton = styled.button`
  background-color: transparent;
  color: #3ee6ff;
  font-size: 15px;
  font-family: Animal-Silence;
  &:hover {
    cursor: pointer;
  }
`;
const ApproveButton = styled.button`
  padding: 0;
  background-color: transparent;
  width: 100px;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
`;
const ApproveButton1 = styled.button`
  padding: 0;
  background-color: transparent;
  width: 100px !important;
  margin-right: 10px;
  opacity: 0.5;
  &:hover {
    cursor: not-allowed;
  }
`;
const NameArea = styled.h2`
  font-size: 19px;
  color: white;
  font-family: MonumentExtended-Regular;
  font-weight: 100;
  margin: 0;
`;
const NameArea1 = styled.h2`
  font-size: 13px;
  color: white;
  font-family: MonumentExtended-Regular;
  font-weight: 100;
  margin: 0;
`;
const StakingTitle1 = styled.p`
  font-size: 13px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 5px 0;
`;
const HeaderArea = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 80px;
  justify-content: space-between;
  @media (max-width: 1100px) {
    margin-bottom: 40px;
  }
  @media (max-width: 720px) {
    flex-direction: column;
    height: 150px;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 0px;
  }
`;
const DetailArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 720px) {
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
`;
const ContentArea = styled.div`
  width: 100%;
  margin-top:140px;
  margin-bottom:120px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    height: 300px;
    padding-top: 85px;
    margin-top:50px;
    margin-bottom:120px;
  }
  @media (max-width: 720px) {
    padding-top: 35px;
  }
`;
const DetailItemArea = styled.div`
  width: 300px;
  margin-left: 10px;
  margin-right: 10px;
  @media (max-width: 1100px) {
    flex-direction: column;
    height: 500px;
    width: 321px;
  }
`;
const ActionArea = styled.div`
  width: 300px;
  height: 70px;
  background-image: url(/images/stakingInputBackImg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const imgStyle = {
  width: 49,
  height: 49,
  marginLeft: 20,
};

const SubArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-around;
  @media (max-width: 860px) {
    width: 70%;
    justify-content: space-between;
  }
`;
const Description4 = styled.p`
  font-size: 20px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  width: 100%;
  text-transform: uppercase;
  text-shadow: 3px 2px #7c0718;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0%;
    margin-top: 20px;
    font-size: 16px;
  }
`;
const MediumImage = styled.img`
  width: 100%;
  text-align: center;
  margin-top: -170px !important;
  @media screen and (max-width: 750px) {
    margin-top: -100px !important;
  }
  &:hover {
  }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 20px auto;
  margin-top: 100px;
  @media screen and (max-width: 600px) {
    font-size: 40px;
    margin-bottom: 0px;
  }
`;
const TopTitle1 = styled.p`
  font-family: Orbitron-Regular;
  font-size: 50px;
  color: white;
  margin: 50px auto;
  margin-bottom: 20px;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    margin: 20px auto;
  }
`;
const TopTitle2 = styled.p`
  font-family: Orbitron-Regular;
  font-size: 24px;
  color: white;
  margin: 0px;
  margin-left: auto;
  text-align: end;
  @media screen and (max-width: 600px) {
    font-size: 18px;
    margin: 0px auto;
  }
  &:hover {
    cursor: pointer;
  }
`;
const SeeAllNFT = styled.div`
  display:flex;
  flex-direction: column;
  align-items: end;
  img{
    width: 230px;
    margin-left: auto;
    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 600px) {
    img{
      width: 180px;
    }
  }
`
const Description = styled.p`
  font-size: 24px;
  color: white;
  font-family: BaiJamjuree-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  max-width: 1000px;
  width: 90%;
  @media (max-width: 980px) {
    font-size: 16px;
  }
`;
const ProcessingBar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 0 auto;
`;
const ProcessingLine = styled.div`
  width: 50%;
  height: 3px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
`;
const ProcessingLine1 = styled.div`
  width: 100%;
  height: 3px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
`;
const ProcessingArea = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  align-items: flex-start;
  @media screen and (max-width: 959px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 2em;
  }
`;
const ContainerVideo = styled.video`
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
`;
const TitleImage = styled.img`
  margin-top: 80px;
  margin-bottom: 50px;
  max-width: 450px;
  width: 80%;
  display: flex;
  align-self: center;
  @media screen and (max-width: 560px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
const SinsImage1 = styled.img`
  width: 185px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 600px) {
    width: 75px;
    display: flex;
    margin-top: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StakingScreen = (props: Props) => {
  const [walletTokenVal, setWalletTokenVal] = useState(0);
  const [depositeTokenVal, setDepositeTokenVal] = useState(0);
  const [earnAmount, setEarnAmount] = useState(0);
  const [stakingVal, setStakingVal] = useState(0);
  const [withdrawVal, setWithdrawVal] = useState(0);
  const [loading, setLoading] = useState(0);
  const [lockedNftData, setLockedNftData] = useState<any[]>([]);
  const [bonus, setBonus] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    setWalletTokenVal(props.smbrTokenBalance);
    setDepositeTokenVal(props.stakedTokenBalance);
    setEarnAmount(props.earnedTokenBalance);
    setLockedNftData(props.lockedNFT);
    setLoading(props.stakeLoading);
    setBonus(props.bonuses);
  }, [props]);

  let history = useHistory();

  let render = (bonus: any) => {
    if (bonus) {
      if (bonus.length === 0) {
        return 0;
      } else {
        return (parseFloat(bonus[0]) / parseFloat(bonus[1])) * 100;
      }
    }
    return 0;
  };

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SinsImage1
          onClick={() => {
            history.push(`/`);
          }}
          src={sinsImage}
          alt=""
        />
      </div>
      <TitleImage src={stakingTitle} alt="" />
      <WallectConnectionArea>
        {props.connected ? (
          props.connectFlag ? (
            <div />
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                onClick={props.add_chain_with_web3}
                className={styles.social_item}
              >
                <img src={stakingSwitchBtn} alt="" width="230px" />
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div onClick={props.connect} className={styles.social_item}>
              <img src={stakingConnectBtn} alt="" width="230px" />
            </div>
          </div>
        )}
        <div
          onClick={() => {
            setOpenModal(true);
          }}
          className="instruction"
        >
          <img src={stakingInstBtn} alt="" />
        </div>
      </WallectConnectionArea>
      <BannerContainer>
        <StackInfoArea>
          <HeaderArea>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
              }}
            >
              <img src={logo} style={imgStyle} alt="logo" />
              <TokenNameArea>
                <NameArea>SMBR</NameArea>
              </TokenNameArea>
            </div>
            <DetailArea>
              <SubArea>
                <TokenNameArea1>
                  <StakingTitle1 style={{ textAlign: "start" }}>
                    Boost
                  </StakingTitle1>
                  <NameArea1>{render(bonus)}%</NameArea1>
                </TokenNameArea1>
                <TokenNameArea1>
                  <StakingTitle1 style={{ textAlign: "start" }}>
                    Your Stake
                  </StakingTitle1>
                  <NameArea1>
                    {parseFloat(depositeTokenVal.toString()).toFixed(4)} SMBR
                  </NameArea1>
                </TokenNameArea1>
              </SubArea>
            </DetailArea>
          </HeaderArea>
          <ContentArea>
            <DetailItemArea>
              <StakingTitle1 style={{ margin: 0, textAlign: "start" }}>
                In Your Wallet:{" "}
                <span style={{ color: " white" }}>
                  {" "}
                  {parseFloat(walletTokenVal.toString()).toFixed(4)} SMBR
                </span>
              </StakingTitle1>
              <ActionArea>
                <ValueInputBox
                  value={stakingVal}
                  onChange={(e) => {
                    setStakingVal(parseFloat(e.target.value));
                  }}
                  type="number"
                  placeholder="0"
                ></ValueInputBox>
                <MaxValueButton onClick={() => setStakingVal(walletTokenVal)}>
                  MAX
                </MaxValueButton>
                <ApproveButton
                  onClick={() => {
                    props.onCallStake(stakingVal);
                  }}
                >
                  <img src={stakeBtnBackImage} alt="" width="100%" />
                </ApproveButton>
              </ActionArea>
            </DetailItemArea>
            <DetailItemArea>
              <StakingTitle1 style={{ margin: 0, textAlign: "start" }}>
                Your Stake:{" "}
                <span style={{ color: " white" }}>
                  {" "}
                  {parseFloat(depositeTokenVal.toString()).toFixed(4)} SMBR
                </span>
              </StakingTitle1>
              <ActionArea>
                <ValueInputBox
                  value={withdrawVal}
                  onChange={(e) => setWithdrawVal(parseFloat(e.target.value))}
                  type="number"
                  placeholder="0"
                ></ValueInputBox>
                <MaxValueButton
                  onClick={() => {
                    setWithdrawVal(depositeTokenVal);
                  }}
                >
                  MAX
                </MaxValueButton>
                {depositeTokenVal == 0 || isNaN(withdrawVal) ? (
                  <ApproveButton1><img src={withdrawBtnImage} alt="" width="100%" /></ApproveButton1>
                ) : (
                  <ApproveButton
                    onClick={() => props.onCallWithDraw(withdrawVal)}
                  >
                    <img src={withdrawBtnImage} alt="" width="100%" />
                  </ApproveButton>
                )}
              </ActionArea>
            </DetailItemArea>
            <DetailItemArea>
              <StakingTitle1
                style={{ margin: 0, color: " white", textAlign: "start" }}
              >
                SMBR Earned
              </StakingTitle1>
              <ActionArea style={{justifyContent:'space-between'}}>
                  <ValueInputBox
                    disabled={true}
                    value={earnAmount}
                    type="number"
                    placeholder="0"
                  ></ValueInputBox>
                {earnAmount == 0 ? (
                  <ApproveButton1><img src={harvestBtnImage} alt="" width="100%"/></ApproveButton1>
                ) : (
                  <ApproveButton onClick={() => props.onCallHarvest()}>
                    <img src={harvestBtnImage} alt="" width="100%" />
                  </ApproveButton>
                )}
              </ActionArea>
            </DetailItemArea>
          </ContentArea>
          {loading === 0 ? null : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#19192a",
              }}
            >
              <Description style={{ textAlign: "center" }}>
                {loading === 1
                  ? "Approving transaction..."
                  : "Transaction finished successfully."}
              </Description>

              <ProcessingArea>
                {loading === 1 ? <ProcessingLine /> : <ProcessingLine1 />}
                <ProcessingBar />
              </ProcessingArea>
            </div>
          )}
        </StackInfoArea>
      </BannerContainer>
      <TitleImage src={stakedNftImage} style={{ maxWidth: 750, width: '90%' }} alt="" />
      <Container>
        <SeeAllNFT>
          <img src={seeAllBtnImage} alt="" onClick={() => {
            history.push("/rarity");
          }} />
        </SeeAllNFT>
        {/* <TopTitle2
          onClick={() => {
            history.push("/rarity");
          }}
        >
          See all NFTs
        </TopTitle2> */}
      </Container>
      <HomeContainer
        ownNFT={lockedNftData}
        openFlag={false}
        onCallLock={props.onCallLock}
        lockLoading={props.lockLoading}
        stakeFlag={props.stakeFlag}
        onCallCheckStakable={props.onCallCheckStakable}
        onCallUnlock={props.onCallUnlock}
        onCallBoostStaking={props.onCallBoostStaking}
        ownFlag={true}
        lockedFlag={true}
      />
      <Container>
        <Description4>
          <span style={{ fontWeight: 'bold' }}>SMBR STAKING</span>
          <br />
          <br />
          Your APY is proportional to your staked amount so the larger your pool
          the larger your rewards. Staked SMBR can be withdrawn at any time. You
          can also stake your Sins NFT for a 0.1% boost on your staked SMBR if you
          stake the NFT for 3 months. You must activate your boost on the sins
          web dapp once the time duration is up. It will not auto activate. Once
          boosted it will stay boosted until you unstake the NFT. Once you
          unstake the duration restarts.
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>NFT STAKING</span>
          <br />
          <br />
          SOS NFT staking is also available. Each PFP a user stakes increases
          their APY by 0.1% after 3 months - make sure you activate it manually
          after the duration.
          <br />
          <br />
          <br />
          {/* <span style={{ fontFamily: "Gilroy-ExtraBold" }}>
            *Specifics for annual staking rewards and tier system coming soon*
          </span> */}
        </Description4>
        <MediumImage style={{ margin: 0 }} src={darkImage} alt="" />
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={openModal}
          onClose={handleClose}
        >
          <iframe
            width="100%"
            src="https://www.youtube.com/embed/wsm3ntTxlMo?autoplay=1&mute=1"
            title="YouTube video player"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </Dialog>
      </Container>
    </MintContainer>
  );
};

export default StakingScreen;
