import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import { useLocation, useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";

import LogoArea from "./GameLogoArea";
import MenuArea from "./MenuArea";
import SocialLinkArea from "./SocialLinkArea";
import ToggleMenuIcon from "./ToggleMenuIcon";
import { colors } from "../../styles";
import teamBtn from "../../assets/images/teamBtn.png";
import mintBtn from "../../assets/images/mintBtn.png";
import roadMapBtn from "../../assets/images/roadMapBtn.png";
import whyMintBtn from "../../assets/images/whyMintBtn.png";
import phase2Btn from "../../assets/images/phase2Btn.png";
import MenuContent from "./MenuContent";

interface MenuAreaProps {
  displayMenu: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  killSession: () => void;
  openModal: () => void;
}

interface MenuContainerProps {
  status: boolean;
}
interface MenuItemProps {
  isActive: string;
  current: string;
}

const Wrapper = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    height: 50px;
  }
`;
interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  killSession: () => void;
}

const MenuContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in-out;
  height: 76px;
  justify-content: space-between;
  flex-direction: column;
  background: black;
  opacity: 1;
  width: 100%;
  height: 600px;
  left: calc((100% - 300px) / 2);
  padding-top: 20px;
  padding-bottom: 20px !important;
  z-index: 1000;
  border: 1px solid rgba(${colors.border});
  @media (max-width: 960px) {
    height: 100%;
    padding-bottom: 30px !important;
    width: 100%;
    left: 0;
  }
`;
const MenuContainer1 = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in-out;
  height: 76px;
  width: 70%;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 600px) {
    margin-left: 2%;
    width: 80%;
    height: 50px;
  }
`;
const MmenuItem = styled.li<MenuItemProps>`
  color: rgb(${colors.fontColor});
  font-size: 20px;
  font-weight: bold;
  font-family: "Orbitron-Regular";
  margin: 0 1em;
  text-shadow: ${(props) =>
    props.current === props.isActive
      ? "1px 1px 2px white, 0 0 1em white, 0 0 0.2em darkblue"
      : "none"};

  @media (max-width: 1100px) {
    margin: 0 0.3em;
  }
  @media (max-width: 960px) {
    margin: 0.5em 0;
  }
  &:hover {
    cursor: pointer;
    text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.2em darkblue;
  }
`;
const MmenuItem1 = styled.li<MenuItemProps>`
  color: rgb(${colors.fontColor});
  font-size: 20px;
  font-weight: bold;
  font-family: "Orbitron-Regular";
  margin: 0 1em;
  img {
    height: 40px;
  }
  @media (max-width: 1100px) {
    margin: 0 0.3em;
  }
  @media (max-width: 960px) {
    margin: 0.5em 0;
    font-size: 12px;
    img {
      height: 15px;
    }
  }
  &:hover {
    cursor: pointer;
    text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.2em darkblue;
  }
`;
const TokenValueArea2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const TelegramImage = styled.img`
  width: 44px;
  margin-right: 5px;
`;
const MintButton2 = styled.button`
  background-color: black;
  margin: 0;
  padding-left: 3px;
  font-size: 20px;
  color: white;
  font-family: "Orbitron-Regular";
  font-weight: 700;
  @media (max-width: 600px) {
    height: 40px;
  }
  &:hover {
    cursor: pointer;
    text-shadow: 0 4px 8px 0 rgba(${colors.main}),
      0 6px 10px 0 rgba(${colors.main});
  }
`;
const MintButton1 = styled.button`
  background-color: black;
  margin: 0;
  font-size: 18px;
  color: white;
  font-family: Gilroy-Light;
  padding: 0;
  @media (max-width: 600px) {
    height: 40px;
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
    text-shadow: 0 4px 8px 0 rgba(${colors.main}),
      0 6px 10px 0 rgba(${colors.main});
  }
`;
const MediumImage2 = styled.img`
  width: 22px;
  margin-right: 7px;
  @media (max-width: 1035px) {
    width: 20px;
  }
  &:hover {
    transform: scale(1.1);
  }
`;

const BuccaneerHeader: React.FC<Props> = ({
  connect,
  killSession,
  setIsHide,
  isHide,
  connected,
  address,
  chainId,
}) => {
  const [currentWidth, setCurrentWidth] = useState(0);
  const [isActive, setIsActive] = useState("");
  const location = useLocation();
  const [ethePrice, setEthePrice] = useState("");
  const [sombraPrice, setSombraPrice] = useState("");
  const [menuFlag, setMenuFlag] = useState(true);
  useEffect(() => {
    setIsActive(location.pathname);
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
    )
      .then((response) => response.json())
      .then((data) => {
        let ethereumUsd = data["ethereum"].usd;
        setEthePrice(parseFloat(ethereumUsd).toFixed(2));
      });
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=sombra-network&vs_currencies=usd"
    )
      .then((response) => response.json())
      .then((data) => {
        let sombraUsd = data["sombra-network"].usd;
        console.log("data.price1", sombraUsd);
        setSombraPrice(parseFloat(sombraUsd).toFixed(2));
      });
  }, [location.pathname]);
  useEffect(() => {
    function updateSize() {
      setCurrentWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  useEffect(() => {
    setIsHide(true);
    // eslint-disable-next-line
  }, [currentWidth]);
  let history = useHistory();
  const navigateTo = (targetPath: string) => {
    history.push(`/${targetPath}`);
  };
  const handleClose = () => {
    setMenuFlag(false);
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Wrapper>
        <LogoArea />
        <MenuContainer1>
          <MmenuItem1
            onClick={() => {
              window.open(
                "https://opensea.io/collection/sinsofshadow",
                "_blank"
              );
            }}
            isActive={isActive}
            current="/"
          >
            <img src={mintBtn} alt="" />
          </MmenuItem1>
          {/* <MmenuItem1
            onClick={() => {
              const mint = document.querySelector("#team");
              if (mint) {
                mint.scrollIntoView({ behavior: "smooth", block: "center" });
              }
            }}
            isActive={isActive}
            current="/mynfts"
          >
            <img src={teamBtn} alt="" />
          </MmenuItem1> */}
          <MmenuItem1
            onClick={() => {
              const mint = document.querySelector("#roadmap");
              if (mint) {
                mint.scrollIntoView({ behavior: "smooth", block: "center" });
              }
            }}
            isActive={isActive}
            current="/mynfts"
          >
            <img src={roadMapBtn} alt="" />
          </MmenuItem1>
          <MmenuItem1
            onClick={() => {
              const mint = document.querySelector("#whymint");
              if (mint) {
                mint.scrollIntoView({ behavior: "smooth", block: "center" });
              }
            }}
            isActive={isActive}
            current="/mynfts"
          >
            <img src={whyMintBtn} alt="" />
          </MmenuItem1>
          <MmenuItem1
            onClick={() => {
              const mint = document.querySelector("#parse");
              if (mint) {
                mint.scrollIntoView({ behavior: "smooth", block: "center" });
              }
            }}
            isActive={isActive}
            current="/mynfts"
          >
            <img src={phase2Btn} alt="" />
          </MmenuItem1>
        </MenuContainer1>
        <ToggleMenuIcon openMenu={() => setMenuFlag(!menuFlag)} />
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          open={menuFlag}
          onClose={handleClose}
        >
          <MenuContent />
        </Dialog>
      </Wrapper>
    </div>
  );
};

export default BuccaneerHeader;
