import { useEffect, useState } from "react";
import styled from "styled-components";
import RarityHeader from "../components/Home/RarityHeader";
import HomeContainer from "../components/Home/HomeContainer";
import { useHistory } from "react-router-dom";
import loading from "../assets/images/loading.gif";
import searchBtn from "../assets/images/searchBtn.png";
import rarityTitle from "../assets/images/rarityTitle.png";
import smbrStakingBtn from "../assets/images/smbrStakingBtn.png";
import backWalletBtn from "../assets/images/backWalletBtn.png";
import inputBackImage from "../assets/images/inputBackImage.png";
import connectBtn from "../assets/images/connectBtn.png";
import switchBtn from "../assets/images/switchBtn.png";
import sinsImage from "src/assets/images/SINS_LOGO_WHite.png";
import styles from "../components/Home/Footer.module.css";
import { MINT_CONTRACT } from "../constants/contracts";
import {
  rarityLink,
  rairityImageLink,
  rairityJsonLink,
  testNet,
} from "../sombra_config";
import Web3 from "web3";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  amount: number;
  totalPrice: number;
  balance: number;
  ownNFT: Array<any>;
  lockedNFT: Array<any>;
  add_chain_with_web3: () => void;
  connectFlag: boolean;
  isloading: boolean;
  lockLoading: number;
  stakeFlag: string;
  onCallLock: (tokenId: any) => void;
  onCallUnlock: (tokenId: any) => void;
  onCallCheckStakable: (tokenId: any) => void;
  onCallBoostStaking: (tokenId: any) => void;
  contractAddress: any;
}

interface TapContainerProps {
  tapStatus: boolean;
}

const MintContainer = styled.div`
  background-image: url(/images/rarityBackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 130vh;
  padding-top: 70px;
  padding-bottom: 60px;
  @media (max-width: 600px) {
    padding-top: 60px;
  }
`;
const SinsImage1 = styled.img`
  width: 185px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 600px) {
    width: 75px;
    display: flex;
    margin-top: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;
const LoadingImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 150px;
`;
const TapArea = styled.div<TapContainerProps>`
  // background-image: url(/images/browseBackImage.jpg);
  background-image: url(${(props) =>
    props.tapStatus
      ? "/images/myNftBackImage.png"
      : "/images/stackedNftBackImage.png"});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 400px;
  height: 67px;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    width: 300px;
    height: 50px;
  }
`;
const TapItem = styled.div<TapContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 10px;
  margin-left: 15px;
  margin-bottom: 15px;
  font-family: MonumentExtended-Regular;
  font-size: 17px;
  // background-color: ${(props) => (props.tapStatus ? "#e9a82b" : "balck")};
  padding: 10px 20px;
  border-radius: 50px;
  @media (max-width: 600px) {
    font-size: 11px;
    margin-bottom: 12px;
  }
  &:hover {
    cursor: pointer;
  }
`;
const TapSwitchAddress = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 1400px;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
  .inputArea {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 350px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 600px) {
    .inputArea {
      width: 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
const SwitchAddress = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 1400px;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
  .inputArea {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 350px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 600px) {
    .inputArea {
      width: 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
const AddressInput = styled.input`
  background-color: transparent;
  position: absolute;
  margin-right: 30px;
  width: 320px;
  height: 50px;
  border-width: 0;
  outline: none;
  color: black;
  font-family: Gilroy-Light;
  font-size: 18px;
  padding: 0 10px;
  @media screen and (max-width: 600px) {
    width: 200px;
    margin-right: 10px;
  }
`;
const OwnerButton = styled.div`
  display: flex;
  right: 0;
  @media screen and (max-width: 800px) {
    position: relative;
    margin-top: 20px;
  }
`;
const TokenLinkButton = styled.div`
  display: flex;
  @media screen and (max-width: 800px) {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const TitleImage = styled.img`
  margin-top: 80px;
  margin-bottom: 50px;
  max-width: 500px;
  width: 80%;
  display: flex;
  align-self: center;
  @media screen and (max-width: 560px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const RarityScreen = (props: Props) => {
  const [nftData, setNftData] = useState<any[]>([]);
  const [otherWalletAddress, setOtherWalletAddress] = useState("");
  const [lockedNftData, setLockedNftData] = useState<any[]>([]);
  const [isloading, setIsLoading] = useState(false);
  const [switchFlag, setSwitchFlag] = useState(false);
  const [tapStatus, setTapStatus] = useState(true);
  const [openFlag, setOpenFlag] = useState(false);
  const [clothesDrop, setClothesDrop] = useState(false);

  useEffect(() => {
    console.log("props.lockedNFT", props.lockedNFT);
    setNftData(props.ownNFT);
    setLockedNftData(props.lockedNFT);
    setIsLoading(props.isloading);
  }, [props.ownNFT, props.lockedNFT]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const initialWeb3 = async () => {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      }
    };
    initialWeb3();
  }, []);

  let history = useHistory();

  const networkWarning = () => {
    alert("Not quite ready yet");
  };

  const clothesHandler = () => {
    setClothesDrop(!clothesDrop);
  };

  const fetchNFT = async (address: any) => {
    if (address === "") {
      window.alert("Please type address");
    } else {
      setSwitchFlag(true);
      // if (!props.connectFlag) {
      //   await props.add_chain_with_web3();
      // }
      let j = 0;
      let keep_going = true;
      let current_nfts: any = [];
      setIsLoading(true);
      while (keep_going) {
        let tempObj: any = {};
        try {
          let nft_call: any = await fetchNFTItemData(j, address);
          if (!nft_call) {
            break;
          }
          tempObj.tokenId = nft_call[2];
          tempObj.metaDataLink = nft_call[0];
          let link = rairityJsonLink + tempObj.tokenId;
          let rarityLik = rarityLink + tempObj.tokenId + ".json";
          let rarityData: any;
          await fetch(rarityLik)
            .then((res) => res.json())
            .then((json) => {
              rarityData = json.rarity;
            });
          fetch(link)
            .then((res) => res.json())
            .then((json) => {
              tempObj.name = json.name;
              tempObj.description = json.description;
              tempObj.attributes = json.attributes;
              tempObj.rarityData = rarityData;
              // tempObj.image = json.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
              tempObj.image = rairityImageLink + tempObj.tokenId + ".png";
            });

          current_nfts.unshift(tempObj); // changed this to after break to avoid double of the previsous
        } catch (err) {
          console.log(err);
          keep_going = false;
        }
        j++;
      }
      setIsLoading(false);
      setNftData(current_nfts);
    }
  };

  const fetchNFTItemData = async (j: any, address: any) => {
    let provideAddress = "https://rpc.ankr.com/eth";
    if (testNet) {
      provideAddress =
        "https://rinkeby.infura.io/v3/522640124384455690d796f8eb334569";
    }
    let web3 = new Web3(provideAddress);
    const contract_token = new web3.eth.Contract(
      MINT_CONTRACT.abi,
      props.contractAddress.MINT_CONTRACT
    );
    let current_nft;
    let owners_balance;
    try {
      current_nft = await contract_token.methods
        .tokenOfOwnerByIndex(address, j)
        .call();
      owners_balance = await contract_token.methods.balanceOf(address).call();
      // nft_info = await contract_token.methods.nft_info(current_nft).call()
    } catch (err) {
      console.log(err);
      return false;
    }
    try {
      if (current_nft) {
        let nft_uri = await contract_token.methods.tokenURI(current_nft).call();

        let just_hash = nft_uri.slice(7); // change to appropriate length

        let meta_data_link = "https://sins.mypinata.cloud/ipfs/" + just_hash;

        return [meta_data_link, owners_balance, current_nft];
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SinsImage1
          onClick={() => {
            history.push(`/`);
          }}
          src={sinsImage}
          alt=""
        />
      </div>
      <TitleImage src={rarityTitle} alt="" />
      <TapSwitchAddress>
        <TapArea tapStatus={tapStatus}>
          <TapItem
            onClick={() => {
              setOpenFlag(false);
              setTapStatus(true);
            }}
            tapStatus={tapStatus}
          >
            My NFTs
          </TapItem>
          <TapItem
            onClick={() => {
              setOpenFlag(false);
              setTapStatus(false);
            }}
            tapStatus={!tapStatus}
          >
            Staked NFTs
          </TapItem>
        </TapArea>
        <OwnerButton>
          <div
            onClick={() => {
              setSwitchFlag(false);
              setNftData(props.ownNFT);
            }}
            style={{ margin: 0 }}
            className={styles.social_item}
          >
            <img src={backWalletBtn} alt="" width="330px" />
          </div>
        </OwnerButton>
      </TapSwitchAddress>
      <SwitchAddress>
        <TokenLinkButton>
          <div
            onClick={() => {
              history.push("/stake");
            }}
            style={{ margin: 0 }}
            className={styles.social_item}
          >
            <img src={smbrStakingBtn} alt="" width="200px" />
          </div>
        </TokenLinkButton>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="inputArea">
            <img src={inputBackImage} alt="inputBackImage" />
            <AddressInput
              placeholder="Please type addres..."
              onChange={(e) => {
                setOtherWalletAddress(e.target.value);
              }}
            />
          </div>
          <div
            onClick={() => {
              fetchNFT(otherWalletAddress);
            }}
            style={{ margin: 0 }}
          >
            <img src={searchBtn} alt="" width="160px" />
            {/* <p
                style={{
                  fontFamily: "Gilroy-ExtraBold",
                  fontSize: 20,
                  margin: 0,
                }}
                className={styles.bidButtonLabel}
              >
                Search
              </p> */}
          </div>
        </div>
      </SwitchAddress>
      {switchFlag ? (
        isloading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingImage src={loading} alt="" />
          </div>
        ) : (
          <HomeContainer
            stakeFlag={props.stakeFlag}
            openFlag={openFlag}
            ownNFT={nftData}
            onCallLock={props.onCallLock}
            lockLoading={props.lockLoading}
            onCallCheckStakable={props.onCallCheckStakable}
            onCallUnlock={props.onCallUnlock}
            onCallBoostStaking={props.onCallBoostStaking}
            ownFlag={false}
            lockedFlag={tapStatus ? false : true}
          />
        )
      ) : props.connected ? (
        props.connectFlag ? (
          isloading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <LoadingImage src={loading} alt="" />
            </div>
          ) : (
            <HomeContainer
              stakeFlag={props.stakeFlag}
              openFlag={openFlag}
              ownNFT={tapStatus ? nftData : lockedNftData}
              onCallLock={props.onCallLock}
              lockLoading={props.lockLoading}
              onCallCheckStakable={props.onCallCheckStakable}
              onCallUnlock={props.onCallUnlock}
              onCallBoostStaking={props.onCallBoostStaking}
              ownFlag={true}
              lockedFlag={tapStatus ? false : true}
            />
          )
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              onClick={props.add_chain_with_web3}
              className={styles.social_item}
            >
              <img src={switchBtn} alt="" width="230px" />
            </div>
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div onClick={props.connect} className={styles.social_item}>
            <img src={connectBtn} alt="" width="230px" />
          </div>
        </div>
      )}
    </MintContainer>
  );
};

export default RarityScreen;
