import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles";
import Dialog from "@material-ui/core/Dialog";
import Web3 from "web3";

import styles from "../../components/Home/Footer.module.css";
import close from "../../assets/images/close.png";
import CUCUMBER from "../../assets/images/CUCUMBER.png";
import Eliete from "../../assets/images/Eliete.png";
import Alpha from "../../assets/images/Alpha.png";
import Epic from "../../assets/images/Epic.png";
import Legendary from "../../assets/images/Legendary.png";
import gotoMintBtn from "../../assets/images/gotoMintBtn.png";
import { Rarity_data } from "../../rarity";
import {
  num_revealed,
  rarityLink,
  rarityRankingLink,
  rairityPreImageLink,
  testNet,
} from "../../sombra_config";

import { LOCK_CONTRACT } from "../../constants/contracts";
import TimeIndicate from "./TimeIndicate";

import {
  createTheme,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { getValue } from "../../helpers/utility";

interface Props {
  ownNFT: Array<any>;
  onCallLock: (tokenId: any) => void;
  onCallUnlock: (tokenId: any) => void;
  onCallCheckStakable: (tokenId: any) => void;
  onCallBoostStaking: (tokenId: any) => void;
  ownFlag: boolean;
  openFlag: boolean;
  lockedFlag: boolean;
  lockLoading: number;
  stakeFlag: string;
}
interface ContainerProps {
  isHide: boolean;
}

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  justify-content: center;
`;
const ItemContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 20px 20px;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    width: 40%;
    margin: 0;
    margin: 10px 2.5%;
  }
`;
const NftImage = styled.img`
  width: 300px;
  height: 300px;
  align-self: center;
  border-radius: 15px;
  border: 3px solid white;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;
const Title = styled.p`
  font-size: 18px;
  text-align: start;
  font-family: Orbitron-Regular;
  color: white;
  @media screen and (max-width: 560px) {
    font-size: 12px;
    display: flex;
  }
`;
const LoadingImage = styled.img`
  width: 100px;
  height: 100px;
`;
const ModalContent = styled.div`
  position: relative;
  width: 100%;
  background-color: black;
  border: 2px solid #ff56f6;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
`;
const ModalCloseBtn = styled.div`
  width: 100%;
  height: 30px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }
`;
const CloseBtn = styled.img`
  margin-top: 15px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid gray;
`;
const SideContent = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  @media (max-width: 750px) {
    width: 90%;
  }
`;
const SideContent1 = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  @media (max-width: 750px) {
    width: 90%;
  }
`;
const RarityPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 12%;
  padding-left: 7%;
  @media (max-width: 750px) {
    display: none;
  }
`;
const RarityPart1 = styled.div`
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10%;
  @media (max-width: 750px) {
    display: flex;
    padding-right: 0%;
    margin: 10px auto;
  }
`;
const PropertyArea = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  margin-bottom: 30px;
  margin-top: 20px;
  border-top: 1px solid #ff56f6;
`;
const PropertyItemArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;
const PropertyItem = styled.div`
  width: 155px;
  height: 60px;
  border-radius: 2px;
  border-width: 2px;
  margin: 10px auto;
  border-image: linear-gradient(to right, #3c79d4 50%, #ffa6fa 100%);
  border-style: solid;
  border-image-slice: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    #d900f9 20%,
    #d500f8 60%,
    #4200ba 105%,
    #390063 120%
  );
  opacity: 0.5f;
  filter: drop-shadow(0 0px 1.2vw rgba(255, 86, 246, 0.51));
  cursor: pointer;
  @media (max-width: 750px) {
    width: 130px;
    height: 60px;
  }
`;
const ButtonItem = styled.button`
  width: 155px;
  height: 35px;
  border-radius: 15px;
  border-width: 1px;
  margin: 0px auto;
  // border-image: linear-gradient(to right, #3c79d4 50%, #ffa6fa 100%);
  border-style: solid;
  border-image-slice: 1;
  border-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    #6c01cc 20%,
    #6c01cc 60%,
    #6c01cc 105%,
    #6c01cc 120%
  );
  opacity: 0.5f;
  filter: drop-shadow(0 0px 1.2vw rgba(255, 86, 246, 0.51));
  cursor: pointer;
  @media (max-width: 750px) {
    width: 130px;
    height: 30px;
  }
`;
const ButtonItem1 = styled.button`
  width: 155px;
  height: 35px;
  border-radius: 15px;
  border-width: 1px;
  margin: 0px auto;
  // border-image: linear-gradient(to right, #3c79d4 50%, #ffa6fa 100%);
  border-style: solid;
  border-image-slice: 1;
  border-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    #4200ba 20%,
    #4200ba 60%,
    #4200ba 105%,
    #4200ba 120%
  );
  opacity: 0.5f;
  filter: drop-shadow(0 0px 1.2vw rgba(255, 86, 246, 0.51));
  cursor: pointer;
  @media (max-width: 750px) {
    width: 130px;
    height: 30px;
  }
`;
const PropertyTitle = styled.p`
  font-family: RobotoMono-Medium;
  font-size: 24px;
  color: white;
  text-align: center;
`;
const PropertyValue = styled.p`
  font-family: RobotoMono-Bold;
  font-size: 13px;
  text-align: center;
  margin: 0;
  color: #ffcd6c;
  @media (max-width: 750px) {
    font-size: 13px !important;
  }
`;
const RarityImage = styled.img`
  width: 133px;
  @media (max-width: 750px) {
    width: 80px;
  }
`;
const AvartarImg1 = styled.img`
  width: 90%;
  margin: auto;
  display: flex;
`;
const ModalItemTitle = styled.p`
  font-size: 24px;
  margin-left: 10%;
  font-family: Roboto-Bold;
  font-weight: bold;
  color: white;
  @media (max-width: 750px) {
    font-size: 18px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p{
    font-family: MonumentExtended-Regular;
  }
  // @media (max-width: 600px) {
  //   margin-top: 50px;
  // }
`;
const ModalContentArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const OwnerArea = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1250px;
  width: 90%;
`;
const Description = styled.p`
  font-size: 24px;
  color: white;
  font-family: BaiJamjuree-Regular;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1000px;
  width: 90%;
  @media (max-width: 980px) {
    font-size: 16px;
  }
`;
const ProcessingBar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 0 auto;
`;
const ProcessingLine = styled.div`
  width: 50%;
  height: 3px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
`;
const ProcessingLine1 = styled.div`
  width: 100%;
  height: 3px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
`;
const ProcessingArea = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  align-items: flex-start;
  @media screen and (max-width: 959px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 2em;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 689,
      width: 1000,
      marginTop: 200,
    },
    paper: {
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      padding: theme.spacing(2, 4, 3),
      width: "100%",
    },
    dialog: {
      maxWidth: 600,
      height: 689,
    },
    itemTitle: {
      textAlign: "center",
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 16,
      fontWeight: 700,
    },
  })
);

// const TimeIndicator = (tokenId: any) => {
//   let time_locked = "0";
//   let lock_period = "0";
//   let current_time = Math.floor(new Date().getTime() / 1000.0);
//   useEffect(() => {
//     getValue();
//   }, [time_locked, lock_period]);

//   const getValue = () => {
//     let provideAddress = "https://rpc.ankr.com/eth";
//     if (testNet) {
//       provideAddress =
//         "https://rinkeby.infura.io/v3/522640124384455690d796f8eb334569";
//     }
//     let web3 = new Web3(provideAddress);
//     console.log("current_time", current_time);
//     const contract_lock = new web3.eth.Contract(
//       LOCK_CONTRACT.abi,
//       LOCK_CONTRACT.address
//     );
//     try {
//       time_locked = contract_lock.methods.time_locked(tokenId).call();
//     } catch (err) {
//       console.log(err);
//     }
//     try {
//       lock_period = contract_lock.methods.lock_period().call();
//     } catch (err) {
//       console.log(err);
//     }
//     // return 1000;
//     console.log(
//       "time_locked",
//       parseInt(time_locked),
//       parseInt(lock_period),
//       current_time,
//       parseInt(time_locked) + parseInt(lock_period) - current_time
//     );
//     // setLockTime(parseInt(time_locked) + parseInt(lock_period) - current_time);
//   };
//   return parseInt(time_locked) + 100000 - current_time;
// };

const HomeContainer: React.FC<Props> = ({
  ownNFT,
  ownFlag,
  onCallLock,
  lockedFlag,
  onCallUnlock,
  onCallCheckStakable,
  onCallBoostStaking,
  lockLoading,
  stakeFlag,
  openFlag,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = useState<any>({});
  const [rarityValue, setrarityValue] = useState("");
  const [rarityRanking, setrarityRanking] = useState("");
  const [openseaLink, setOpenseaLink] = useState("");
  const [raribleLink, setRaribleLink] = useState("");
  const [rarityImage, setRarityImage] = useState("");
  const [rarityName, setRarityName] = useState("");
  const [loading, setLoading] = useState(0);
  const [boostFlag, setboostFlag] = useState("");
  const [unloadFlag, setUnloadFlag] = useState(false);
  const [lockTime, setLockTime] = useState(0);

  useEffect(() => {
    setLoading(lockLoading);
  }, [lockLoading]);

  useEffect(() => {
    setOpen(false);
  }, [openFlag, ownFlag, ownNFT.length === 0]);

  useEffect(() => {
    setboostFlag(stakeFlag);
  }, [stakeFlag]);

  useEffect(() => {
    const initialWeb3 = async () => {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      }
    };
    initialWeb3();
  }, []);

  const handleClose = () => {
    setLoading(0);
    setOpen(false);
  };

  const handleOpen = async (item: any) => {
    setLoading(0);
    setrarityRanking("");
    console.log("item", item);
    await onCallCheckStakable(item.tokenId);
    let raribleLink =
      "https://rarible.com/token/0x7fdc538356b1c137dc238001e92d480f197dedeb:" +
      item.tokenId +
      "?tab=details";
    let openseaLink =
      "https://opensea.io/assets/0x7fdc538356b1c137dc238001e92d480f197dedeb/" +
      item.tokenId;
    setOpenseaLink(openseaLink);
    setRaribleLink(raribleLink);
    let rarityLik = rarityLink + item.tokenId + ".json";
    let rarityRankingLik = rarityRankingLink + item.tokenId + ".json";
    if (item.tokenId < num_revealed) {
      await fetch(rarityRankingLik)
        .then((res) => res.json())
        .then((json) => {
          setrarityRanking(json.ranking);
        });
    }
    await fetch(rarityLik)
      .then((res) => res.json())
      .then((json) => {
        console.log("item", json.rarity);
        setrarityValue(json.rarity);
        if (json.rarity >= 330) {
          setRarityImage(CUCUMBER);
          setRarityName("Cucumber");
        } else if (json.rarity >= 264 && json.rarity < 330) {
          setRarityImage(Alpha);
          setRarityName("Alpha");
        } else if (json.rarity >= 198 && json.rarity < 264) {
          setRarityImage(Legendary);
          setRarityName("Legendary");
        } else if (json.rarity >= 132 && json.rarity < 198) {
          setRarityImage(Eliete);
          setRarityName("Elite");
        } else {
          setRarityImage(Epic);
          setRarityName("Epic");
        }
      });
    setModalContent(item);
    // if (boostFlag == "need more time to pass untill") {
    //   await timeIndicator(item.tokenId);
    // }
    setOpen(true);
  };
  const calculateRarity = (rarityData: any) => {
    if (rarityData === undefined) {
      return "";
    }
    if (parseInt(rarityData) >= 0 && parseInt(rarityData) <= 4) {
      return "COMMON";
    } else if (parseInt(rarityData) >= 5 && parseInt(rarityData) <= 6) {
      return "RARE";
    }
    return "EPIC";
  };
  const getChance = (trait: string, value: string) => {
    console.log("trait", trait, value);
    let rarityData = Rarity_data;
    let tempObj = rarityData[trait as keyof typeof rarityData];
    let chanceValue = tempObj[value as keyof typeof tempObj];
    return chanceValue["chance" as keyof typeof chanceValue];
  };

  return (
    <Content>
      {/* <TopTitle>RARITY</TopTitle> */}
      {ownNFT.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={styles.social_item}>
            <p
              style={{
                color: "white",
                fontSize: 22,
                textAlign:'center',
                fontFamily: 'MonumentExtended-Regular',
                textTransform: "uppercase"
              }}
            >
              {lockedFlag
                ? "You don't have any locked NFTs."
                : "You don't have any NFTs."}
            </p>
            {!lockedFlag && (
              <a
                href="/#mint"
                style={{ margin: "0, 20px", width: 230 }}
              >
                <img src={gotoMintBtn} alt="" width="230px" />
              </a>
            )}
          </div>
        </div>
      ) : (
        <BannerContainer>
          {ownNFT.map((item, index) => {
            return (
              <ItemContainer
                key={index}
                onClick={() => {
                  handleOpen(item);
                }}
              >
                <NftImage
                  src={
                    item.tokenId >= num_revealed
                      ? rairityPreImageLink
                      : item.image
                  }
                  alt=""
                />
                <Title>SINS #{item.tokenId}</Title>
                {/* <Title>{item.description}</Title> */}
              </ItemContainer>
            );
          })}
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
            disableBackdropClick
          >
            <ModalContent>
              <ModalCloseBtn
                onClick={handleClose}
                id="transition-modal-description"
              >
                <CloseBtn src={close} />
              </ModalCloseBtn>
              <ModalContentArea>
                <SideContent>
                  <RarityPart1>
                    <div>
                      <ModalItemTitle style={{ margin: 0 }}>
                        RARITY VALUE :{" "}
                        {modalContent.tokenId >= num_revealed ? (
                          ""
                        ) : (
                          <span
                            style={{
                              fontFamily: "RobotoMono-Regular",
                              color: "#FF00F5",
                              fontWeight: 400,
                            }}
                          >
                            {/* {calculateRarity(modalContent.rarityData)} */}
                            {parseFloat(modalContent.rarityData).toFixed(0)}
                          </span>
                        )}
                      </ModalItemTitle>
                      <ModalItemTitle style={{ margin: 0 }}>
                        RARITY RANKING :{" "}
                        {modalContent.tokenId >= num_revealed ? (
                          ""
                        ) : (
                          <span
                            style={{
                              fontFamily: "RobotoMono-Regular",
                              color: "#FF00F5",
                              fontWeight: 400,
                            }}
                          >
                            {/* {calculateRarity(modalContent.rarityData)} */}
                            {parseFloat(rarityRanking).toFixed(0)}
                          </span>
                        )}
                      </ModalItemTitle>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <RarityImage src={rarityImage} alt="" />
                      <p style={{ color: "white", fontSize: 20, margin: 0 }}>
                        {rarityName}
                      </p>
                    </div>
                  </RarityPart1>
                  <AvartarImg1
                    src={
                      modalContent.tokenId >= num_revealed
                        ? rairityPreImageLink
                        : modalContent.image
                    }
                    alt=""
                  />
                  <ModalItemTitle>SINS #{modalContent.tokenId}</ModalItemTitle>
                </SideContent>
                <SideContent1>
                  <RarityPart>
                    <div>
                      <ModalItemTitle style={{ margin: 0 }}>
                        RARITY VALUE :{" "}
                        {modalContent.tokenId >= num_revealed ? (
                          ""
                        ) : (
                          <span
                            style={{
                              fontFamily: "RobotoMono-Regular",
                              color: "#FF00F5",
                              fontWeight: 400,
                            }}
                          >
                            {/* {calculateRarity(modalContent.rarityData)} */}
                            {parseFloat(modalContent.rarityData).toFixed(0)}
                          </span>
                        )}
                      </ModalItemTitle>
                      <ModalItemTitle style={{ margin: 0 }}>
                        RARITY RANKING :{" "}
                        {modalContent.tokenId >= num_revealed ? (
                          ""
                        ) : (
                          <span
                            style={{
                              fontFamily: "RobotoMono-Regular",
                              color: "#FF00F5",
                              fontWeight: 400,
                            }}
                          >
                            {/* {calculateRarity(modalContent.rarityData)} */}
                            {parseFloat(rarityRanking).toFixed(0)}
                          </span>
                        )}
                      </ModalItemTitle>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <RarityImage src={rarityImage} alt="" />
                      <p style={{ color: "white", fontSize: 20, margin: 0 }}>
                        {rarityName}
                      </p>
                    </div>
                  </RarityPart>
                  <PropertyArea>
                    <PropertyTitle>Properties</PropertyTitle>
                    <PropertyItemArea>
                      {open &&
                        modalContent.attributes.map((item: any, index: any) => {
                          return (
                            <PropertyItem key={index}>
                              <PropertyValue>{item.trait_type}</PropertyValue>
                              <PropertyValue
                                style={{ fontSize: 15, color: "white" }}
                              >
                                {item.value.toLowerCase() == "none"
                                  ? "n/a"
                                  : item.value}
                              </PropertyValue>
                              {/* <PropertyValue
                                style={{
                                  fontSize: 11,
                                  color: "white",
                                  fontFamily: "RobotoMono-Regular",
                                }}
                              >
                                {getChance(item.trait_type, item.value)}% have
                                this trait
                              </PropertyValue> */}
                            </PropertyItem>
                          );
                        })}
                      {/* {ownFlag && ( */}
                      <>
                        <div
                          style={{
                            width: "100%",
                            height: 2,
                            backgroundColor: "#ff56f6",
                          }}
                        />
                        <ButtonItem
                          onClick={() => {
                            window.open(raribleLink, "_blank");
                          }}
                          style={{ height: 45, marginTop: 20 }}
                        >
                          <PropertyValue
                            style={{ fontSize: 20, color: "white" }}
                          >
                            Rarible
                          </PropertyValue>
                        </ButtonItem>
                        <ButtonItem
                          onClick={() => {
                            window.open(modalContent.metaDataLink, "_blank");
                          }}
                          style={{ height: 45, marginTop: 20 }}
                        >
                          <PropertyValue
                            style={{ fontSize: 20, color: "white" }}
                          >
                            IPFS
                          </PropertyValue>
                        </ButtonItem>
                        <ButtonItem
                          onClick={() => {
                            window.open(openseaLink, "_blank");
                          }}
                          style={{ height: 45, marginTop: 20 }}
                        >
                          <PropertyValue
                            style={{ fontSize: 20, color: "white" }}
                          >
                            Opensea
                          </PropertyValue>
                        </ButtonItem>
                        {ownFlag &&
                          (lockedFlag ? (
                            <>
                              <ButtonItem1
                                onClick={() => {
                                  onCallUnlock(modalContent.tokenId);
                                }}
                                style={{ height: 45, marginTop: 20 }}
                              >
                                <PropertyValue
                                  style={{ fontSize: 20, color: "white" }}
                                >
                                  Unstake
                                </PropertyValue>
                              </ButtonItem1>
                              {boostFlag == "already used as a boost" ? (
                                <div />
                              ) : (
                                <ButtonItem1
                                  onClick={() => {
                                    if (
                                      boostFlag ==
                                      "need more time to pass untill"
                                    ) {
                                      window.alert(
                                        "Boost availible 3 months after NFT is staked"
                                      );
                                    } else
                                      onCallBoostStaking(modalContent.tokenId);
                                  }}
                                  style={{
                                    height: 45,
                                    marginTop: 20,
                                    width: 230,
                                  }}
                                >
                                  <PropertyValue
                                    style={{ fontSize: 19, color: "white" }}
                                  >
                                    Boost SMBR Staking
                                  </PropertyValue>
                                </ButtonItem1>
                              )}
                              {/* {boostFlag == "need more time to pass untill" && (
                                <ButtonItem1
                                  style={{
                                    height: 45,
                                    marginTop: 20,
                                    width: 230,
                                  }}
                                >
                                  <PropertyValue
                                    style={{
                                      fontSize: 18,
                                      color: "white",
                                      lineHeight: 1,
                                    }}
                                  >
                                    <TimeIndicate
                                      tokenId={modalContent.tokenId}
                                    />
                                    {lockTime} seconds until boostable
                                  </PropertyValue>
                                </ButtonItem1>
                              )} */}
                              {/* {boostFlag == "true" && ( */}
                              {/* )} */}
                            </>
                          ) : (
                            <ButtonItem1
                              onClick={() => {
                                onCallLock(modalContent.tokenId);
                              }}
                              style={{ height: 45, marginTop: 20 }}
                            >
                              <PropertyValue
                                style={{ fontSize: 20, color: "white" }}
                              >
                                Stake
                              </PropertyValue>
                            </ButtonItem1>
                          ))}
                      </>
                      {/* )} */}
                    </PropertyItemArea>
                  </PropertyArea>
                </SideContent1>
              </ModalContentArea>
              {loading === 0 ? null : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Description style={{ textAlign: "center" }}>
                    {loading === 1
                      ? "Approving transaction..."
                      : "Transaction finished successfully."}
                  </Description>

                  <ProcessingArea>
                    {loading === 1 ? <ProcessingLine /> : <ProcessingLine1 />}
                    <ProcessingBar />
                  </ProcessingArea>
                </div>
              )}
            </ModalContent>
          </Dialog>
        </BannerContainer>
      )}
    </Content>
  );
};

export default HomeContainer;
