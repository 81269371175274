export const GenesisData = [
    {
        "name": "SINS #0",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/0.png",
        "dna": "28b9b5532c3ba3c45cee80ef8b3ffa1e10146167",
        "edition": "0",
        "date": 1650992706837,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #1",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/1.png",
        "dna": "650e87150ad4d7fd1adc2439fdc6bf7831a1b58a",
        "edition": "1",
        "date": 1650999042242,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #2",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/2.png",
        "dna": "6951772329f210e0f13fa79ae6d7131369515de7",
        "edition": "2",
        "date": 1651001870477,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #3",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/3.png",
        "dna": "763b0958ae896a5a88d88ff0674ac221d27139cf",
        "edition": "3",
        "date": 1650992831815,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #4",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/4.png",
        "dna": "f2808c3a2887eaeb167a1a2e14c46ee991bcf689",
        "edition": "4",
        "date": 1650989682131,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Bandaid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Commando"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #5",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/5.png",
        "dna": "99ec1e80327897c607b8f3eb4d6d43a7508691fc",
        "edition": "5",
        "date": 1650993327889,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #6",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/6.png",
        "dna": "ce807fdc6ffaa1c8e69aa6bde409a7d8782cb99a",
        "edition": "6",
        "date": 1651002388716,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #7",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/7.png",
        "dna": "f091d68a3bb7c14e64e4392840cf5186c895983d",
        "edition": "7",
        "date": 1651002860779,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #8",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/8.png",
        "dna": "1b937b2f362ade734204b2f1ad31338bcf9f7c1c",
        "edition": "8",
        "date": 1650999219118,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #9",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/9.png",
        "dna": "14aaae1d77551f57f4e39c2234d826ee50f5ce1b",
        "edition": "9",
        "date": 1650989893320,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #10",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/10.png",
        "dna": "56aaf67ad0e25134222e8844b476c52dba6da4e6",
        "edition": "10",
        "date": 1650993995956,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Triangle Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #11",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/11.png",
        "dna": "f7767a2d551bc60fa42bbd7aafb40a0766181496",
        "edition": "11",
        "date": 1650994807925,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #12",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/12.png",
        "dna": "cc9d62dec556fadc8cb51b4b41450f3f7bc849f3",
        "edition": "12",
        "date": 1650994225132,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Club Shirt"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #13",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/13.png",
        "dna": "917ca6384eb849cf3a1898d7a5ba38a8ecdbf021",
        "edition": "13",
        "date": 1650993945480,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #14",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/14.png",
        "dna": "103e15f633ef0d77bbf6195b73c9468409087992",
        "edition": "14",
        "date": 1650998674875,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 13"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #15",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/15.png",
        "dna": "f9692e486fd99171e0b4d82eb1e1a203feb99a12",
        "edition": "15",
        "date": 1651000057325,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Blue Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Robo Fem"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #16",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/16.png",
        "dna": "53c1cbc3f5c2e85b0aeca0ebe158f7abea31df8e",
        "edition": "16",
        "date": 1650998580900,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #17",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/17.png",
        "dna": "feb7a3d906e60eafdba43412f28b801cf08cc876",
        "edition": "17",
        "date": 1650990319076,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #18",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/18.png",
        "dna": "6a66cbcb2f58c011f825a53367e1c20324fecb69",
        "edition": "18",
        "date": 1651000828395,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 14"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #19",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/19.png",
        "dna": "1e963b3c4ae22f7d34bddbb674e67c38bd6bf406",
        "edition": "19",
        "date": 1650998963358,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #20",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/20.png",
        "dna": "e9314aaa39eb8d88c859556c4bbb570802ee3fd9",
        "edition": "20",
        "date": 1650992765659,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Orange Fur"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #21",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/21.png",
        "dna": "6b31685810d12eb1a0918704fdb29db6b2093ad5",
        "edition": "21",
        "date": 1650995396649,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #22",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/22.png",
        "dna": "9c2cc57e581bf7a7db59852756736031311bae7b",
        "edition": "22",
        "date": 1651003478285,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Sword of Power"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #23",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/23.png",
        "dna": "7c19d51f3b71b8698ec78e18c66f029978932567",
        "edition": "23",
        "date": 1650994205149,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Sword of Power"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Poly Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #24",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/24.png",
        "dna": "0c6985bdf335330a194b54cd7d5cd4028a33a11e",
        "edition": "24",
        "date": 1650998543596,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #25",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/25.png",
        "dna": "ac2cd2d61d1f0e5f48790775cbab71e5c01187f8",
        "edition": "25",
        "date": 1650994619728,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #26",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/26.png",
        "dna": "b3207839d5de77666a0fcfec332d6c0907260082",
        "edition": "26",
        "date": 1650997399831,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Metal Head"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #27",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/27.png",
        "dna": "cd5abd423ff1b09779085552f0bb4f06e4ef1c5a",
        "edition": "27",
        "date": 1651003337279,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #28",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/28.png",
        "dna": "da4dec2a9b0efa6f07cf7d97544374326b0e0db2",
        "edition": "28",
        "date": 1650997928257,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Undercut Green Tip"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #29",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/29.png",
        "dna": "92e004e94bb7a955943242c7f9e89feba0e09be7",
        "edition": "29",
        "date": 1651001357512,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #30",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/30.png",
        "dna": "3014ef213e53796a6109d9e78332b4f483565e8f",
        "edition": "30",
        "date": 1650994772736,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Neck Tattoo"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #31",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/31.png",
        "dna": "eaaf3931f72cce11352fe43e2218e184ff626bf2",
        "edition": "31",
        "date": 1651003318474,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Black Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #32",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/32.png",
        "dna": "f02d7bd3cbb73ac0b121879b7e3829ed30379b70",
        "edition": "32",
        "date": 1651001722975,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #33",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/33.png",
        "dna": "8720d4185a54033867ed55d16c9aee511182416d",
        "edition": "33",
        "date": 1650992399608,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Blue Balls"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #34",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/34.png",
        "dna": "f50a9843a5ef018b39b5e48e7e7fc965aade29ad",
        "edition": "34",
        "date": 1651002045512,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #35",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/35.png",
        "dna": "32c1c4e56a3f2841f9ecf610f694d4a6eb09ab20",
        "edition": "35",
        "date": 1650997095966,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #36",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/36.png",
        "dna": "6fcfa7eb03489244b4535b267c192019a7c8dfb4",
        "edition": "36",
        "date": 1650997758299,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #37",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/37.png",
        "dna": "c7d066bc8a50625519dbc6c386920110788b17d4",
        "edition": "37",
        "date": 1650990719265,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 5"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #38",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/38.png",
        "dna": "0055ed188cdb5c450723fb6d1444804af1fbdcdb",
        "edition": "38",
        "date": 1651000309274,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #39",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/39.png",
        "dna": "cbff1c20419cb7bb9f9266b242a265f1f825c8cf",
        "edition": "39",
        "date": 1650999699825,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #40",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/40.png",
        "dna": "b4b01e15e9db5eb53e9fbbff1694c9a3ab43707d",
        "edition": "40",
        "date": 1651000254310,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #41",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/41.png",
        "dna": "68d845a21dcca8bbc8739d4c00c5b346eb2b0612",
        "edition": "41",
        "date": 1650994244430,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Triangle Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #42",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/42.png",
        "dna": "6aa49bdce52396bb23b7de5eeeda4c6f204d67be",
        "edition": "42",
        "date": 1650991699536,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #43",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/43.png",
        "dna": "94da005ebf7ebcb9fc44ac8abfa3f1bcb28e9e0d",
        "edition": "43",
        "date": 1650991101591,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Glass Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #44",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/44.png",
        "dna": "c7444ac037c0ee7feb6bb84d6e2c4f15bddc8713",
        "edition": "44",
        "date": 1650989477182,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 5"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Pink Fur"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #45",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/45.png",
        "dna": "2520be88f78e2f3473cff9ba62b16c2d7ff72855",
        "edition": "45",
        "date": 1650997045147,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #46",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/46.png",
        "dna": "eb87e0752e7210706e95fba00e17e6f3a5688b76",
        "edition": "46",
        "date": 1650995446734,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #47",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/47.png",
        "dna": "5091fe483f97f47a5e7c6761095a2c700e3cf79f",
        "edition": "47",
        "date": 1650993269025,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #48",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/48.png",
        "dna": "c9b7844ba1b25d61102efc2fd13458423c547451",
        "edition": "48",
        "date": 1650995033534,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #49",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/49.png",
        "dna": "60ab47707314e4a022f947400ff581b418f3f396",
        "edition": "49",
        "date": 1651001661823,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Sword of Power"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #50",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/50.png",
        "dna": "298739317e4f295b4ff1501c14ce44af8f0940c7",
        "edition": "50",
        "date": 1650997767108,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Rabbit"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #51",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/51.png",
        "dna": "0a77ad12ffbf92e872291084773caad95c44ccdf",
        "edition": "51",
        "date": 1650990316661,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #52",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/52.png",
        "dna": "3e59a88dbb0d8b145f84a67b698cc7c00a2ddfb9",
        "edition": "52",
        "date": 1650992763070,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Diamond Wings"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #53",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/53.png",
        "dna": "b6d7f7cba97411d04b051b35c522dba2d7f4b8f2",
        "edition": "53",
        "date": 1650994583047,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #54",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/54.png",
        "dna": "a982ef969cfb3a6e5b9b79e2e3d297696b003ad0",
        "edition": "54",
        "date": 1651003907431,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #55",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/55.png",
        "dna": "137621717a4d0d9020275716764606cfe711fc08",
        "edition": "55",
        "date": 1650992374300,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #56",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/56.png",
        "dna": "74c5eff7d23ba025fe3caab1f737d90f47d50c24",
        "edition": "56",
        "date": 1650994265921,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "From Hell"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #57",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/57.png",
        "dna": "c5017f76c96b10e65d7f026a9a697f14b9953922",
        "edition": "57",
        "date": 1651000986987,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Ancient 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Blue Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Crop Top"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #58",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/58.png",
        "dna": "bbe02312058aa6afaf3187945866e4436f8a67aa",
        "edition": "58",
        "date": 1650993695362,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Purple Cyber Neck"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #59",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/59.png",
        "dna": "a66ab3fa3dc00a1da164ff3fc86324beb3bfe4f4",
        "edition": "59",
        "date": 1650999225463,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #60",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/60.png",
        "dna": "d01845d203c4402539170e1f0620ecb1a5902e85",
        "edition": "60",
        "date": 1650997627373,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #61",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/61.png",
        "dna": "a08718ea170bdd9dbdd9bdc3376be3e99212796b",
        "edition": "61",
        "date": 1650999617651,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #62",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/62.png",
        "dna": "60cf3e7fb94ad0d4ea656d5ee6d6e6ce1c67d57c",
        "edition": "62",
        "date": 1651000521910,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Blue Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #63",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/63.png",
        "dna": "44d307491a9e3cacb544048602e508ecae29fb74",
        "edition": "63",
        "date": 1650994792154,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #64",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/64.png",
        "dna": "040f53864a125ddd7e6028596aa7b17ea6e8acc0",
        "edition": "64",
        "date": 1650993663459,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #65",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/65.png",
        "dna": "c75fd901aa8779e1df67b270991584a332b71154",
        "edition": "65",
        "date": 1650999158690,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #66",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/66.png",
        "dna": "0c97de0102c50ef01f60c1bb165ab3fa19ec6651",
        "edition": "66",
        "date": 1650991954204,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #67",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/67.png",
        "dna": "6edc3f74784f68e50a2b32a0d5d6075117c59ce7",
        "edition": "67",
        "date": 1650991162107,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Bandaid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Bio Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #68",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/68.png",
        "dna": "fda673abff7a7f55bcdffa2d6d8b569e221d1828",
        "edition": "68",
        "date": 1651001796112,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Blue Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #69",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/69.png",
        "dna": "ba162bb68092880ed4cef2c51bfbd286d15a1126",
        "edition": "69",
        "date": 1650995345083,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #70",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/70.png",
        "dna": "3830716b522c89c33ae171563c8144118428a6cb",
        "edition": "70",
        "date": 1650998316784,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 13"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Bandaid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #71",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/71.png",
        "dna": "45a42cf65df72043d8c432cc34ad9d6e336ba697",
        "edition": "71",
        "date": 1650994921329,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 5"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Club Shirt"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #72",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/72.png",
        "dna": "9b768326323322824dfd3fed08ba3e68d4035858",
        "edition": "72",
        "date": 1650998435088,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #73",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/73.png",
        "dna": "bf6bdce298e2310d6b1a5e4b4abb1eb5a3053eec",
        "edition": "73",
        "date": 1650995365524,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #74",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/74.png",
        "dna": "ae7382822db0640aee053d10b3486ca73c19d559",
        "edition": "74",
        "date": 1651002856653,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Black Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #75",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/75.png",
        "dna": "d8c9278ab79d2a939ba6d99611009d35c2af02f0",
        "edition": "75",
        "date": 1651003529121,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 14"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Red Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #76",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/76.png",
        "dna": "3d76c75a0d245096b8904125e7ab567bbe729995",
        "edition": "76",
        "date": 1650994896143,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Metal Head"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Bonfire"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #77",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/77.png",
        "dna": "4c6026b2603d1e19fdd6136cc18d7ec7db59e5dd",
        "edition": "77",
        "date": 1650999038396,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #78",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/78.png",
        "dna": "cddc05b1106af5a427bfb5c7243b58dfdc31cdfa",
        "edition": "78",
        "date": 1651001413469,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Crop Top"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #79",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/79.png",
        "dna": "398eae49cf534455714d73ab0a51d91a58d97632",
        "edition": "79",
        "date": 1650997104873,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Robo Fem"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #80",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/80.png",
        "dna": "671a2836f0a818d6096e4409bf1bbf0021710ad6",
        "edition": "80",
        "date": 1650997332402,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #81",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/81.png",
        "dna": "5df24cdc372fa897e2a4db4d9906cfb41c4d7873",
        "edition": "81",
        "date": 1650991741013,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Red Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #82",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/82.png",
        "dna": "515a57823b265370dc8be896866334b9a4114719",
        "edition": "82",
        "date": 1650990447675,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Neck Tattoo"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #83",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/83.png",
        "dna": "4b84989800c59b3a78152f95f661948242672d2a",
        "edition": "83",
        "date": 1650990727471,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #84",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/84.png",
        "dna": "ac5bf3c59a6ea820bada6f08796efb167314adbf",
        "edition": "84",
        "date": 1650997919951,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Bandaid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #85",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/85.png",
        "dna": "6c75d7b74122dafbc3304c394c631754951941b6",
        "edition": "85",
        "date": 1650990868066,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Watchmen"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #86",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/86.png",
        "dna": "30a2eab0edcd57d9e60aeb6127e82231f60780e5",
        "edition": "86",
        "date": 1650991647131,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Club Shirt"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #87",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/87.png",
        "dna": "37498abdef13b0be9fe278f940e993b55219ef0f",
        "edition": "87",
        "date": 1650994063772,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Blue Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Commando"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Merry Peppins"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #88",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/88.png",
        "dna": "6ce6dce2120a959402302f08c90095eee3b2cb41",
        "edition": "88",
        "date": 1650993881594,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cool White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Biker Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #89",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/89.png",
        "dna": "7d19205aebc5fc3c40f0ad9efec441c116e29764",
        "edition": "89",
        "date": 1650991401560,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #90",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/90.png",
        "dna": "681e229caeb406515dc8dd7f6038b12f91634dd9",
        "edition": "90",
        "date": 1650997595789,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #91",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/91.png",
        "dna": "86e819a47a9400b49c11b3a6dda39a077dbe0e0c",
        "edition": "91",
        "date": 1650991739070,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #92",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/92.png",
        "dna": "5b2e6d3ec18527c841740a0c1fc5d373b144a823",
        "edition": "92",
        "date": 1651003763177,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Undercut Green Tip"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #93",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/93.png",
        "dna": "1c62c230b517cd82e15eb8e22470f52b69dbf2cd",
        "edition": "93",
        "date": 1650999611621,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #94",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/94.png",
        "dna": "6ed528739d4d583bcf0be7250903c5f6985ce0dc",
        "edition": "94",
        "date": 1651001129995,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #95",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/95.png",
        "dna": "cadf3d3cb3699a3349575d64fcd343dafc7288f2",
        "edition": "95",
        "date": 1650991789769,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #96",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/96.png",
        "dna": "8521e604fd9cf639a591df28bf60e8c225339905",
        "edition": "96",
        "date": 1651001709763,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #97",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/97.png",
        "dna": "02c66246aae3e921c243827ea832179221165ed8",
        "edition": "97",
        "date": 1650999449177,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #98",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/98.png",
        "dna": "0b41c654c73360ee971f30e168cd1dc2ba7ef898",
        "edition": "98",
        "date": 1650990026923,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #99",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/99.png",
        "dna": "f8cdc38e643a12de592993a11b4a4818480776a0",
        "edition": "99",
        "date": 1651001917237,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Rabbit"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #100",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/100.png",
        "dna": "98af66aa457447e9768cbf8266f7678273857968",
        "edition": "100",
        "date": 1650993697625,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Ancient 1"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #101",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/101.png",
        "dna": "769b0d7efdb7ec74b4bd7c11df14660f8a88ff1e",
        "edition": "101",
        "date": 1650992946550,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #102",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/102.png",
        "dna": "66091326fd38393396f3b0fe514d2d77ef78a5fd",
        "edition": "102",
        "date": 1650999599660,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #103",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/103.png",
        "dna": "9c422e21552fb6e1cc47c6e8e9d2b8d1e5886ab6",
        "edition": "103",
        "date": 1650993298442,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Poly Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Glass Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #104",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/104.png",
        "dna": "a46a196ef2cc97b85b7536066ed03e43330823ce",
        "edition": "104",
        "date": 1650995321908,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #105",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/105.png",
        "dna": "6c30b8050f2aa74177024b9c9115f449bd7758d5",
        "edition": "105",
        "date": 1651002140902,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #106",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/106.png",
        "dna": "7b76e4556fee9c0763d5732d86a909b52aea8a52",
        "edition": "106",
        "date": 1650999077633,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #107",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/107.png",
        "dna": "cf2fc722fe55bea39d0dde4ab4cea02c625100ea",
        "edition": "107",
        "date": 1651000633656,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #108",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/108.png",
        "dna": "6d005fbdead7c3c4e62aaa38a2a4d06cb6d0cfcb",
        "edition": "108",
        "date": 1650999456005,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "FKU"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Rabbit"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #109",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/109.png",
        "dna": "0a6f6fbbb9d530c6637e37ef1aec60ac7821f662",
        "edition": "109",
        "date": 1650990464703,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #110",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/110.png",
        "dna": "dbc11e2823399cbffcb9352f167d42e6961f909e",
        "edition": "110",
        "date": 1651003029514,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #111",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/111.png",
        "dna": "6091f9368c4dd3e534b08c4c2ec734f86e68bcf0",
        "edition": "111",
        "date": 1650997690881,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #112",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/112.png",
        "dna": "ed35688ec783fe853ab89601f47db489212c9095",
        "edition": "112",
        "date": 1650992779953,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #113",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/113.png",
        "dna": "c57dc8063e47ac46f688a37428853b5c47584c49",
        "edition": "113",
        "date": 1650998793825,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #114",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/114.png",
        "dna": "d8dbfaa05baf257e4b238b3235cacb2d8106be45",
        "edition": "114",
        "date": 1651001849923,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "AK_47s"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Crop Top"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #115",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/115.png",
        "dna": "1458b127c42b9cb83a9c5cba2bc7b0773b164762",
        "edition": "115",
        "date": 1650998359775,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Robo Fem"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Merry Peppins"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #116",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/116.png",
        "dna": "6cee9c7dd72f29d74f17409d7c7e0d6b7d010877",
        "edition": "116",
        "date": 1650992730806,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Bio Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #117",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/117.png",
        "dna": "ef87dc70fb3d9c0a0c6d03821283f48c785e7240",
        "edition": "117",
        "date": 1650994095382,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #118",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/118.png",
        "dna": "18f3f88ab9e91f9836e0637b4558e0da2bda822b",
        "edition": "118",
        "date": 1650997695928,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 13"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #119",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/119.png",
        "dna": "6cec7939c35636208d9b4e2997033549f4d11083",
        "edition": "119",
        "date": 1650999284245,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Crystal Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Merry Peppins"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #120",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/120.png",
        "dna": "f1ae44fec4efa285f975fa7900b85376ffc3fdfc",
        "edition": "120",
        "date": 1650995331314,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #121",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/121.png",
        "dna": "0dacf6af004c64c21b7a9eab3be98b7e75afc856",
        "edition": "121",
        "date": 1651002648839,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #122",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/122.png",
        "dna": "18201b78667c715a8b967adfa3311ba64064ef86",
        "edition": "122",
        "date": 1650995253677,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 5"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Bio Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #123",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/123.png",
        "dna": "5c647afccd4ba63cb5db04189abda8a13b5c036d",
        "edition": "123",
        "date": 1650994087621,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Poly Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #124",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/124.png",
        "dna": "a9d87887d7c83bfa7e941ecb8f6235f9fc428aaa",
        "edition": "124",
        "date": 1651001375408,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Chun Li"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #125",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/125.png",
        "dna": "05aee6a3de652a030e998c0d8248cab8d4d8e8dd",
        "edition": "125",
        "date": 1650993850643,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #126",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/126.png",
        "dna": "41b01bc1244c8dec6ed6d298a3adb63558446962",
        "edition": "126",
        "date": 1651002654616,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #127",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/127.png",
        "dna": "bfc13ef3b67be243bab3eb566091e4dfbcd60275",
        "edition": "127",
        "date": 1651002788091,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #128",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/128.png",
        "dna": "2b44d1ce1023b49540f5a0643650b3896aef8416",
        "edition": "128",
        "date": 1650998679140,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #129",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/129.png",
        "dna": "0d3d2422e63300b25922b55a78d38177bcd38954",
        "edition": "129",
        "date": 1650991318589,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Broad Sword"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Pink Fur"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #130",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/130.png",
        "dna": "8c4c7acd41445d3634762c3e5a30ca64677a5b45",
        "edition": "130",
        "date": 1650993125373,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Purple Cyber Neck"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Rabbit"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #131",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/131.png",
        "dna": "858b5c5277ec959786ea603e6a5ef1aec59dd929",
        "edition": "131",
        "date": 1650997215802,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Black Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #132",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/132.png",
        "dna": "1f4138814aea3cb617586343b2f79e036fe407a9",
        "edition": "132",
        "date": 1651002115903,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Watchmen"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #133",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/133.png",
        "dna": "2201da88cd59c2df18fdd8e84b50bc6734ed18dd",
        "edition": "133",
        "date": 1651000118640,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #134",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/134.png",
        "dna": "7d9f55d0f6043471c2ce76b507d53857127bad20",
        "edition": "134",
        "date": 1650991673722,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #135",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/135.png",
        "dna": "3f3cf317fe032f90cc7cdf337c5d163ff0deef0f",
        "edition": "135",
        "date": 1650991132588,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Ancient 1"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #136",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/136.png",
        "dna": "71f4bf823bffdf9839dbf913932c36d6a615c143",
        "edition": "136",
        "date": 1650991262751,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Rabbit"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #137",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/137.png",
        "dna": "7412ddaf46cebc50ca07e7c689dde7734e915b70",
        "edition": "137",
        "date": 1651000689128,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 13"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #138",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/138.png",
        "dna": "a480e39ca465599651ab924719db9a6928e80ecc",
        "edition": "138",
        "date": 1651000513795,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #139",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/139.png",
        "dna": "4e333ea852ccef6630ad35a7b9cbe3a4f87fe10d",
        "edition": "139",
        "date": 1650994404455,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #140",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/140.png",
        "dna": "039a16b614fc5f3ac5afc82baf7af566bee690f4",
        "edition": "140",
        "date": 1650998532817,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #141",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/141.png",
        "dna": "ae4eaa08a9cdfb675b03578c951c7e0551b48de0",
        "edition": "141",
        "date": 1650992114760,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Pomp"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #142",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/142.png",
        "dna": "29b71ee45d911ea252e9fc9eafb11ea63565d8b2",
        "edition": "142",
        "date": 1650998978499,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Bandaid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #143",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/143.png",
        "dna": "748f825054210a9db73d06272c4ac421c84ff878",
        "edition": "143",
        "date": 1651003221659,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Robo Fem"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #144",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/144.png",
        "dna": "77be47534edd41cee783848cf475b2a79ff89d41",
        "edition": "144",
        "date": 1651000560735,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #145",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/145.png",
        "dna": "060400d97f4dc7f394c822ecab29ddafaadc7d5d",
        "edition": "145",
        "date": 1650995414931,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #146",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/146.png",
        "dna": "e7d5c1b87499bdc3ff9b03d007998f26d25ab47a",
        "edition": "146",
        "date": 1650992029495,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Poly Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #147",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/147.png",
        "dna": "eaa466dc9785ba4a4a6ae2efc8cac6e4d7555511",
        "edition": "147",
        "date": 1650993170107,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Rabbit"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #148",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/148.png",
        "dna": "ff0d64bca5aa9cf5916492fe5eee6c71bcc22745",
        "edition": "148",
        "date": 1650990278322,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #149",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/149.png",
        "dna": "ce6851b64496f32e9fb6c23599a9e7a800157311",
        "edition": "149",
        "date": 1651002883745,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Watchmen"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Crop Top"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #150",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/150.png",
        "dna": "9453d15b88230f0e6d362c1bd19f3e71597db728",
        "edition": "150",
        "date": 1650995239971,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #151",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/151.png",
        "dna": "9b09128ffee6a0797081a48b7a60f1507d1d9d98",
        "edition": "151",
        "date": 1650992506732,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #152",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/152.png",
        "dna": "ce19f31ff1af618252b9c0e3c1daa2d1c52f40d1",
        "edition": "152",
        "date": 1650989788722,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #153",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/153.png",
        "dna": "42ecf099cdaae0f874752d18d344103579ce434e",
        "edition": "153",
        "date": 1651003018827,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Triangle Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #154",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/154.png",
        "dna": "458154289f21a75651d63c98f58cb5058958d71c",
        "edition": "154",
        "date": 1651003241104,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Poly Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Glass Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #155",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/155.png",
        "dna": "ee5faaaf73e723306d424949de798bf4ce670f4b",
        "edition": "155",
        "date": 1650994809986,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Pink Fur"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #156",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/156.png",
        "dna": "f0788ebb33303ea06e76a802dd64cbd8b30c613f",
        "edition": "156",
        "date": 1650999527912,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #157",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/157.png",
        "dna": "6bb64fdf0665c1d86ccbebdd604d8f1e78c6b56f",
        "edition": "157",
        "date": 1650998933874,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Daddys Gun"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Poly Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #158",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/158.png",
        "dna": "2481977329b5c3d93fd63e022ce5fdb187c9ce99",
        "edition": "158",
        "date": 1650992075186,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Dread Rockets"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Neck Tattoo"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #159",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/159.png",
        "dna": "fb60772a1948c429818e81ee3c23c20e59dedcb9",
        "edition": "159",
        "date": 1650994798463,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #160",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/160.png",
        "dna": "43963aa3482a852faf425689749f91f410b19cf9",
        "edition": "160",
        "date": 1650994142258,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #161",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/161.png",
        "dna": "bc6e750a6d65ebc1df068620bcc6d1c62a23706e",
        "edition": "161",
        "date": 1650991040423,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Biker Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #162",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/162.png",
        "dna": "b43d3d62b2b88c2ef5e66a69bc313a90f8af7325",
        "edition": "162",
        "date": 1651002008786,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #163",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/163.png",
        "dna": "7cd1564258fd22c2b8314803e423ef53221d3cfd",
        "edition": "163",
        "date": 1650998102959,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #164",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/164.png",
        "dna": "4ac7f1ef099f4933054e0bff0efa38b429449eeb",
        "edition": "164",
        "date": 1650993516530,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Angel Wings"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #165",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/165.png",
        "dna": "27300b05ad63cde42e82386b7044bb73ae48a062",
        "edition": "165",
        "date": 1650997252243,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #166",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/166.png",
        "dna": "fc7f35695271bbc8f8e168eed6b9e56398699915",
        "edition": "166",
        "date": 1650995112009,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #167",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/167.png",
        "dna": "3b78e9e07dc1989d1f3e7c0a968215c91742d27a",
        "edition": "167",
        "date": 1650997633479,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #168",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/168.png",
        "dna": "54faf3e33fc5e05acd2851bbb164c0585e5f3943",
        "edition": "168",
        "date": 1651001587348,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #169",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/169.png",
        "dna": "54b3b0fbb6589630ce641fca396f460178465f31",
        "edition": "169",
        "date": 1651003330944,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Dread Rockets"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Robo Fem"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #170",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/170.png",
        "dna": "d6febb61452e519d688ca311fb1f4db262dc0d14",
        "edition": "170",
        "date": 1650998655892,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Neck Tattoo"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #171",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/171.png",
        "dna": "5bc9f0afeda1b2569bc7847634df54ffa4ea3ba2",
        "edition": "171",
        "date": 1650991228710,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Club Shirt"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #172",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/172.png",
        "dna": "ef56cbd191274628ab447eaf60ca80031cea0521",
        "edition": "172",
        "date": 1651000738717,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #173",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/173.png",
        "dna": "2d5ad51df53baea1c404095ff25ee1080b3bb866",
        "edition": "173",
        "date": 1650994048894,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Bandaid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #174",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/174.png",
        "dna": "7a9d504ebd9aa066262811641e26811269515ec5",
        "edition": "174",
        "date": 1650994089432,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #175",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/175.png",
        "dna": "993ac5379d0508812773e6517484a8fec6c5cf04",
        "edition": "175",
        "date": 1650995419246,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #176",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/176.png",
        "dna": "8abd649105b4734656a1210c377ab648e0c3acbd",
        "edition": "176",
        "date": 1650993503029,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #177",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/177.png",
        "dna": "bf577256177960db8f0f5747a0aa454d684bf673",
        "edition": "177",
        "date": 1651003706173,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #178",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/178.png",
        "dna": "6c07839e2fb757f22f6a35248531b22c82830322",
        "edition": "178",
        "date": 1650991825072,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Bandaid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Watchmen"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #179",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/179.png",
        "dna": "105989b98e23228b3080d24b614da66c21f020e0",
        "edition": "179",
        "date": 1651001735191,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #180",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/180.png",
        "dna": "e3aca3eb6ca59150c16f7974ae1495180fd435b5",
        "edition": "180",
        "date": 1650993242721,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Rabbit"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #181",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/181.png",
        "dna": "920c878e7894c635aa7ae0904bdaa8af3c5cb69c",
        "edition": "181",
        "date": 1651003452937,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #182",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/182.png",
        "dna": "af312c7c6bb5f7d4d87b6216bfae96908323771d",
        "edition": "182",
        "date": 1651003957948,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #183",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/183.png",
        "dna": "8a6129147c46af662fa91219e59da84a01598dad",
        "edition": "183",
        "date": 1650998163064,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #184",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/184.png",
        "dna": "90b28ad537ae9b70c32327caf73dcab7d90d4737",
        "edition": "184",
        "date": 1650994328274,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Commando"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #185",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/185.png",
        "dna": "695d02ec2dae25376f8645cdfaeee7c6ea6a840f",
        "edition": "185",
        "date": 1650992076995,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #186",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/186.png",
        "dna": "fe3ef590836e045dcf6be936e22e377634a4d6c6",
        "edition": "186",
        "date": 1650997506961,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #187",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/187.png",
        "dna": "a18a62a6f0b1a3b5dd0a6f47c986c678b75b5336",
        "edition": "187",
        "date": 1650994513797,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #188",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/188.png",
        "dna": "3064709e6eb6ba756de89dc0587fa86ed8733b5e",
        "edition": "188",
        "date": 1651000025881,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Crop Top"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #189",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/189.png",
        "dna": "d3b75939e35c40ffaffabfbdd683c6c67e2ef4c7",
        "edition": "189",
        "date": 1650998822811,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Undercut Green Tip"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #190",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/190.png",
        "dna": "366e7a8ae35599739b10b1e9ce70d8d733b6861d",
        "edition": "190",
        "date": 1650989985530,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #191",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/191.png",
        "dna": "ee1ac9496ec08800009e321ea85628b0232940b4",
        "edition": "191",
        "date": 1650999048217,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Sable de Sombra"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #192",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/192.png",
        "dna": "bd6ea34cd9b4fecbfae83306a9157ebd503aaed1",
        "edition": "192",
        "date": 1650998986568,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #193",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/193.png",
        "dna": "6914211b0c9275465a853e1fd9f5e10937844c03",
        "edition": "193",
        "date": 1651003245309,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Robo Fem"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #194",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/194.png",
        "dna": "637f71a3b8215be1477a6e44d6afff6f0dffdd9d",
        "edition": "194",
        "date": 1650998864721,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #195",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/195.png",
        "dna": "254f5323820ae755ed06ea4b266b577ba3660517",
        "edition": "195",
        "date": 1651002957512,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "FKU"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #196",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/196.png",
        "dna": "f580ca4526fa09aa93addf0d0106944750f22e88",
        "edition": "196",
        "date": 1650997244025,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Cool White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #197",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/197.png",
        "dna": "0147d87e210254ac8d15341d8ecf104a375958af",
        "edition": "197",
        "date": 1651000882175,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #198",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/198.png",
        "dna": "3e1506420fa54d5004e39dff5d590efffe2a082e",
        "edition": "198",
        "date": 1650998098885,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #199",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/199.png",
        "dna": "5c599825812d2c9a4935488ab4b6ba755c94b4a3",
        "edition": "199",
        "date": 1650998687418,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Excaliber"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Crop Top"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #200",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/200.png",
        "dna": "3a3f383d2f96c6018d93f85c9c555c1340e8b709",
        "edition": "200",
        "date": 1650994223071,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #201",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/201.png",
        "dna": "ab6078b74ae11ce722487ca000e4d6919ce07793",
        "edition": "201",
        "date": 1650993769279,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "From Hell"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyberclops"
            },
            {
                "trait_type": "HAIR",
                "value": "Cool White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #202",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/202.png",
        "dna": "f9d4b6378aee91279e4a1631aae01844e31ef25c",
        "edition": "202",
        "date": 1650991326226,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #203",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/203.png",
        "dna": "ff23fa38866bad49e66767f10c4f6fedc3e05b65",
        "edition": "203",
        "date": 1651001379699,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #204",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/204.png",
        "dna": "050a12f3c5d190045edd95dec735dee234606f28",
        "edition": "204",
        "date": 1651001163901,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #205",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/205.png",
        "dna": "574c547440abba0184a90596fea04f34ad49338c",
        "edition": "205",
        "date": 1651001749197,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #206",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/206.png",
        "dna": "7ecc09a0f8b1d48ecee47667f9f5166d2f8d3d5d",
        "edition": "206",
        "date": 1651003778563,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Teddy Love u"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #207",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/207.png",
        "dna": "44a04db1eccef3a5c383e19ca3535a6c38822ded",
        "edition": "207",
        "date": 1650992423629,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Daddys Gun"
            },
            {
                "trait_type": "BASE",
                "value": "Ancient 1"
            },
            {
                "trait_type": "EYES",
                "value": "From Hell"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #208",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/208.png",
        "dna": "b5e509cc70f0ec490efd6419b93d96e30070458f",
        "edition": "208",
        "date": 1650990819403,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "AK_47s"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Biker Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #209",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/209.png",
        "dna": "6c312940941fcac67a383de4e84bcce7449aa9fb",
        "edition": "209",
        "date": 1651001626780,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #210",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/210.png",
        "dna": "8961931b18f2a9634459c51adbe26c67a25b6c18",
        "edition": "210",
        "date": 1651003984429,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #211",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/211.png",
        "dna": "97c04468fb1224775a10bb972a32a5cc656d0be5",
        "edition": "211",
        "date": 1650992870320,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #212",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/212.png",
        "dna": "e23c0f4d584157f5d182a07bfbcf110e4d896ecd",
        "edition": "212",
        "date": 1650998283344,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #213",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/213.png",
        "dna": "42545c08d5ced29dafbb4ff24c05ed4e6a498ed1",
        "edition": "213",
        "date": 1651000112421,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #214",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/214.png",
        "dna": "0370dea96b724d66d913bc0d27e610896f259e82",
        "edition": "214",
        "date": 1651002097557,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #215",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/215.png",
        "dna": "c9ad9822d3fe881d34b7ccc8bada59f702b66ad2",
        "edition": "215",
        "date": 1650990625252,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Watchmen"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #216",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/216.png",
        "dna": "9c7383fee42d4c3623ceb6890ec8be1267c9e98d",
        "edition": "216",
        "date": 1650991383683,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Excaliber"
            },
            {
                "trait_type": "BASE",
                "value": "Type 5"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #217",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/217.png",
        "dna": "e7ee82f2fa855d2a067c2bede303403dd48bc6b0",
        "edition": "217",
        "date": 1650989511912,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Sinister Cyborg"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #218",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/218.png",
        "dna": "fb583639aa84c9291ab2fa179e975584a5dd4d3b",
        "edition": "218",
        "date": 1650992243079,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #219",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/219.png",
        "dna": "0bb6b8c253935bc8679ae1fda487269891053d37",
        "edition": "219",
        "date": 1651003736196,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #220",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/220.png",
        "dna": "9dcf0e4bb30e6664f91525b57bb838a85c02c0e7",
        "edition": "220",
        "date": 1650994699682,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyberclops"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #221",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/221.png",
        "dna": "08bc84f41b6d85ce7aa7fc4af812a1da6416edab",
        "edition": "221",
        "date": 1650994505770,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "From Hell"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #222",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/222.png",
        "dna": "f21a30f76ced0dc2ce547616f44b17a20c8f4601",
        "edition": "222",
        "date": 1650993189414,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Pomp"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Orange Fur"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Blue Balls"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #223",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/223.png",
        "dna": "5b70ee742449961887f9000ecec229c4d7183c62",
        "edition": "223",
        "date": 1651002892138,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #224",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/224.png",
        "dna": "15e85eb40524f63e76aae73d77954eb0d0754e49",
        "edition": "224",
        "date": 1651002996969,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Watchmen"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #225",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/225.png",
        "dna": "5b0ac896cc3d869e9dfe7ce1dc0007e9089b6165",
        "edition": "225",
        "date": 1651002055126,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #226",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/226.png",
        "dna": "96e1b3cfaf77fd6ac3a76b37cb2b18a26d4a84cb",
        "edition": "226",
        "date": 1650991411990,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Club Shirt"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #227",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/227.png",
        "dna": "69df670f01d5e2b9d7e2d2bcb0e3ab198809542a",
        "edition": "227",
        "date": 1650991828624,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #228",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/228.png",
        "dna": "c72bc9d7039a00b674d2428f022e3ba362612f4c",
        "edition": "228",
        "date": 1650992039755,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 5"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "FKU"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #229",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/229.png",
        "dna": "760290498fd365c1bf6e7676bd23aadbcc3ea553",
        "edition": "229",
        "date": 1650991495364,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Neck Tattoo"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Biker Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #230",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/230.png",
        "dna": "1c3078d08d706fa27c0a895844447fdf868d7a3d",
        "edition": "230",
        "date": 1651001522195,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #231",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/231.png",
        "dna": "8c36fb1b72bd78319910f7bb596bef3d1b0592ee",
        "edition": "231",
        "date": 1650998728991,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #232",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/232.png",
        "dna": "983c1554b8c58518ac17defd22fbaaf5e1a8805e",
        "edition": "232",
        "date": 1650993870630,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Daddys Gun"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #233",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/233.png",
        "dna": "0a479b3c337cfc683493d03c4d1d7be64b2bcde8",
        "edition": "233",
        "date": 1650998292240,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Undercut Green Tip"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #234",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/234.png",
        "dna": "f3a60702b0c4939b0a9a58218b09b6dbc618748f",
        "edition": "234",
        "date": 1650999580325,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #235",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/235.png",
        "dna": "8fbfb7d5d8a95e8e29db275242979168cb3632b8",
        "edition": "235",
        "date": 1650997321633,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "FKU"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #236",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/236.png",
        "dna": "7ce9b4e9cb6951dca10f76fa56cc2f888e2adc97",
        "edition": "236",
        "date": 1650994074354,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Biker Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #237",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/237.png",
        "dna": "852fe76b0bd472eda18fbe2c0b579c446d23eae2",
        "edition": "237",
        "date": 1650998842452,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Chun Li"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #238",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/238.png",
        "dna": "1707ac23afb9928bea1a195ef3d8529dd6b8b4ea",
        "edition": "238",
        "date": 1650989601577,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Pomp"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #239",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/239.png",
        "dna": "05a65524527617c9a257d435f098af9a48dcbfc7",
        "edition": "239",
        "date": 1650991399859,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "From Hell"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Sinister Cyborg"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #240",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/240.png",
        "dna": "60e36a071ababd4431b84434ab04039fcbbff6a4",
        "edition": "240",
        "date": 1650998618085,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #241",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/241.png",
        "dna": "6f42855c1617d19ba64d904338ab73201c6ed21b",
        "edition": "241",
        "date": 1650992484723,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #242",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/242.png",
        "dna": "d03035841a169ac6b0cb4fdec67cce0b0688d305",
        "edition": "242",
        "date": 1651000216575,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #243",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/243.png",
        "dna": "fdbd59d0f65d08d5e8bb67b5947fc166a8143586",
        "edition": "243",
        "date": 1650990947859,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #244",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/244.png",
        "dna": "f6c4914af784a4ab7a2d74784ab3ac367882630b",
        "edition": "244",
        "date": 1650997066210,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Glass Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #245",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/245.png",
        "dna": "98048c517e48bc4d056878a18137befd9023e467",
        "edition": "245",
        "date": 1650993765243,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #246",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/246.png",
        "dna": "7bb4e398e2671f6a21ad1132c2f804e9be40e419",
        "edition": "246",
        "date": 1651001455636,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Commando"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #247",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/247.png",
        "dna": "c2b3bd3bff3578aadacec5147b7a70f154494f5c",
        "edition": "247",
        "date": 1650990424898,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #248",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/248.png",
        "dna": "44ad01a6fff44044bd823c43b2c470529e5613c6",
        "edition": "248",
        "date": 1650994873278,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "White Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #249",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/249.png",
        "dna": "84f36a049011e6f39e07616fe5be12eb50e85994",
        "edition": "249",
        "date": 1650995008386,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "From Hell"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Bonfire"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #250",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/250.png",
        "dna": "72b3d141d16eb73a3dd0600420efde4365c94384",
        "edition": "250",
        "date": 1650992108489,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #251",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/251.png",
        "dna": "d330f01cfad7cd2366a194b26be83441c9a20ea8",
        "edition": "251",
        "date": 1650994051097,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 6"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #252",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/252.png",
        "dna": "0387ac1f1ec7b426ad375c43febf909d5112fb93",
        "edition": "252",
        "date": 1650994440241,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Purple Cyber Neck"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Club Shirt"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Commando"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #253",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/253.png",
        "dna": "df060072667bb199cb3f65de21ec72c366eaa8cb",
        "edition": "253",
        "date": 1650991487748,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Bio Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #254",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/254.png",
        "dna": "eb802ed3556198d08bcbc613ea41525c17949411",
        "edition": "254",
        "date": 1650990140177,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Orange Fur"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #255",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/255.png",
        "dna": "84e04c59c28b93e31700e95dfdc0f63b5f9f56fb",
        "edition": "255",
        "date": 1650997073143,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Merry Peppins"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #256",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/256.png",
        "dna": "80bc636331b1ebd64755f7228c4cbd83b71b1689",
        "edition": "256",
        "date": 1651002252175,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Chun Li"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Captain"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #257",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/257.png",
        "dna": "2593e986425ceef1daaf8a1390a7a3b3b3cdabc2",
        "edition": "257",
        "date": 1650997515835,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #258",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/258.png",
        "dna": "2b0630ce78bb0d2e816b45267d3324601fa02dfd",
        "edition": "258",
        "date": 1651000294296,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Angel Wings"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #259",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/259.png",
        "dna": "92ed12b5f393d571e2fa1e8335eae4daa5a211f4",
        "edition": "259",
        "date": 1651003346710,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #260",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/260.png",
        "dna": "09b928ea02269ccdec97d6aa5f1eaecb1e5a6d59",
        "edition": "260",
        "date": 1651001445381,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #261",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/261.png",
        "dna": "61372b6da07dbe2970eee5615a853659338c876d",
        "edition": "261",
        "date": 1650994273919,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Sword of Power"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #262",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/262.png",
        "dna": "a67ffa8122064921bd7a265eaf7a37b874517c34",
        "edition": "262",
        "date": 1650994573816,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #263",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/263.png",
        "dna": "0b6ece4a690a191a8a0563bf396d477846093d64",
        "edition": "263",
        "date": 1650997350464,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #264",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/264.png",
        "dna": "64f644edd34c939ebc9c3ca4fab23436c753178a",
        "edition": "264",
        "date": 1650995306064,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #265",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/265.png",
        "dna": "1fde468cb6215b80d03ad708f75bb46b8de93b80",
        "edition": "265",
        "date": 1651001345691,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #266",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/266.png",
        "dna": "0ee96841a4271ff4b0d7557fa3047c673a9f5222",
        "edition": "266",
        "date": 1650989735714,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "FKU"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #267",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/267.png",
        "dna": "20db9fcc920ecf15a9046829f21852a936b29dc4",
        "edition": "267",
        "date": 1650998385890,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Red Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #268",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/268.png",
        "dna": "b516b3fb4ad69b391c84b59e5c240adbcb31573a",
        "edition": "268",
        "date": 1650997854514,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Hybrid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #269",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/269.png",
        "dna": "bca54b8f74f24ad8c062cc8bdaf011b81c72b9b7",
        "edition": "269",
        "date": 1650994538053,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Black Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #270",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/270.png",
        "dna": "2a020f0ac89651a9f80799d193d3d2cff4b88209",
        "edition": "270",
        "date": 1651002263810,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Daddys Gun"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #271",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/271.png",
        "dna": "c9691cb74a5c52bbad42522a8eb8c381c6251474",
        "edition": "271",
        "date": 1650994665836,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #272",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/272.png",
        "dna": "867ec488dd8a8efeb4ced423ac2e3a70fd0b43df",
        "edition": "272",
        "date": 1650993862485,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #273",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/273.png",
        "dna": "9d350343819467dc89897367bb0677ab5e0d7b39",
        "edition": "273",
        "date": 1650994213963,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #274",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/274.png",
        "dna": "c0525906ae0d4fafaa120bb3b626159ed5b1e647",
        "edition": "274",
        "date": 1651000353647,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyberclops"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #275",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/275.png",
        "dna": "bbfbe8685aa559188c326bafa5a46e7459aa0a65",
        "edition": "275",
        "date": 1650992858170,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #276",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/276.png",
        "dna": "36e5897169c11e5d84cb4118afb4fde34fbf8e67",
        "edition": "276",
        "date": 1651003326369,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #277",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/277.png",
        "dna": "273799b50d3dedc8c16cfa94a878110bcb74fb74",
        "edition": "277",
        "date": 1651003003729,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #278",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/278.png",
        "dna": "c5f1494c1f6cbce2a77c31726a27d24306cb694d",
        "edition": "278",
        "date": 1650989466440,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #279",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/279.png",
        "dna": "3aa4ecaf9404010e790e5391ddfb7a3ad6e9bb85",
        "edition": "279",
        "date": 1650995217628,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #280",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/280.png",
        "dna": "80504ed03070a103f874337f00bb82089c4fb9fe",
        "edition": "280",
        "date": 1650990291548,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #281",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/281.png",
        "dna": "e36e845353b627f40923ca2fbcb4f3551c686008",
        "edition": "281",
        "date": 1650994093472,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Excaliber"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #282",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/282.png",
        "dna": "3ef283de47e286c22d67772df16f45b14c0b4bb2",
        "edition": "282",
        "date": 1650995459700,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #283",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/283.png",
        "dna": "d9b3d0869332de47e980051055bafa69e20c7d84",
        "edition": "283",
        "date": 1650989962794,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #284",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/284.png",
        "dna": "d74d3f33be6d7ee12171c79fa8739dbaaecf42a8",
        "edition": "284",
        "date": 1651002504956,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #285",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/285.png",
        "dna": "c28c643ccc56c95081188034286469b520dac43d",
        "edition": "285",
        "date": 1650995281990,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Ancient 1"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Club Shirt"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #286",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/286.png",
        "dna": "9bce9519e8ae4a0da9cb483b84587a2129ab3963",
        "edition": "286",
        "date": 1651001005299,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #287",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/287.png",
        "dna": "501f40603656b50f578bebfa848f714d678f6a09",
        "edition": "287",
        "date": 1650992182893,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "FKU"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #288",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/288.png",
        "dna": "becd5976ff6689314e02153bfc13ddeedc06843a",
        "edition": "288",
        "date": 1650999264742,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #289",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/289.png",
        "dna": "04e1f75f4f92492ef24fd55603ca34488b7ae4ac",
        "edition": "289",
        "date": 1651002215361,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 14"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #290",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/290.png",
        "dna": "aff4c49fcd81c48f76ff98b90b7e8fd697d3c578",
        "edition": "290",
        "date": 1651000762885,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyberclops"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #291",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/291.png",
        "dna": "d0c419bb011b00504d6f064e8d0e72534be6a840",
        "edition": "291",
        "date": 1650998121111,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Bandaid"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Commando"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #292",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/292.png",
        "dna": "02112bebd0240e2f97f1f8f491ace097ce498f3f",
        "edition": "292",
        "date": 1650997540274,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #293",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/293.png",
        "dna": "b89547459f8f0107acd6bebafc2e8bb3121b0dcd",
        "edition": "293",
        "date": 1650993212362,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #294",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/294.png",
        "dna": "6fd3a6f0f60de9a60e27c91c09bb6e3d9bd74046",
        "edition": "294",
        "date": 1650994509972,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Triangle Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #295",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/295.png",
        "dna": "9fa3463f1e3160719fe3de47ee0b85d94dc22251",
        "edition": "295",
        "date": 1651000157969,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Neck Tattoo"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #296",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/296.png",
        "dna": "a03636bf272dbbb90a49f5e2c8a1cb7f03caf486",
        "edition": "296",
        "date": 1650990815149,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Broad Sword"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #297",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/297.png",
        "dna": "bec78cd3c012f871a025fd40ec9c102ea7efe5ca",
        "edition": "297",
        "date": 1650994577435,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #298",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/298.png",
        "dna": "efe46f24c67a796cac8ee61b3ef13a1dadd6444e",
        "edition": "298",
        "date": 1650993253927,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 6"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Neck Tattoo"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #299",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/299.png",
        "dna": "35b44b43c7c8301053a453eb59194ddc4ac188c8",
        "edition": "299",
        "date": 1650997488507,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #300",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/300.png",
        "dna": "9c0a3e6c6c38d12beb9386a4ca8ac9afcb78ac5d",
        "edition": "300",
        "date": 1651002371270,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #301",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/301.png",
        "dna": "f24bc598e8a752c0b7847b435738380d453a0e16",
        "edition": "301",
        "date": 1651000357430,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Crop Top"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #302",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/302.png",
        "dna": "1770efe66989e94dcce688c7451e46aa4bc55407",
        "edition": "302",
        "date": 1650998416415,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #303",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/303.png",
        "dna": "10db71aa1233054a2e2d157248794efc10fedc15",
        "edition": "303",
        "date": 1651000723602,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #304",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/304.png",
        "dna": "3adf62b13021f9c5c4bd8931b311159187f1681d",
        "edition": "304",
        "date": 1650991344280,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #305",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/305.png",
        "dna": "9da7dc85ffaa2c676b6a8e6cf322c9f1aa41a78f",
        "edition": "305",
        "date": 1650991657842,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Rad Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #306",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/306.png",
        "dna": "76c0ad7c438f47040b7a72f286eaa9ffc3836fe1",
        "edition": "306",
        "date": 1650998294666,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #307",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/307.png",
        "dna": "4d05929b5d72a0879107c087beeb897fa47b1611",
        "edition": "307",
        "date": 1650991515616,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Excaliber"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Dreads"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Black Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #308",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/308.png",
        "dna": "d3d2e6e4724ba9b4bc17fd26ccef6c1e38072d8f",
        "edition": "308",
        "date": 1650991073579,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Fire Fur"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #309",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/309.png",
        "dna": "ca005d198431ca5c55d70253879b2793f2b31e5f",
        "edition": "309",
        "date": 1651001019613,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Ancient 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Black Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #310",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/310.png",
        "dna": "6bbc79907fa399070d293cf26117b096efb74bbc",
        "edition": "310",
        "date": 1651001032474,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #311",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/311.png",
        "dna": "89da8ea74365f1b24bd524b5137fd8273f95b3e3",
        "edition": "311",
        "date": 1650997079995,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #312",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/312.png",
        "dna": "ff6be6529324f9f99bb826b1104909edb7971f65",
        "edition": "312",
        "date": 1650994749984,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #313",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/313.png",
        "dna": "cc717d423765b58af8aea31d34b5e833c081b074",
        "edition": "313",
        "date": 1651001890445,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #314",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/314.png",
        "dna": "d6f903cff0eee05c66779d50db67c8e561bafd91",
        "edition": "314",
        "date": 1650991296196,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Sinister Cyborg"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #315",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/315.png",
        "dna": "8e20af29ff392dc018095241ffccece9e34d1aa3",
        "edition": "315",
        "date": 1650998713090,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #316",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/316.png",
        "dna": "3022d681c2de5e00fb5a4466758050219336131e",
        "edition": "316",
        "date": 1650997809400,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lemon Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #317",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/317.png",
        "dna": "585614ca346e57a50ebefad90db500dc128a719b",
        "edition": "317",
        "date": 1650990765049,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Fuchsia"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Teddy Love u"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Sable de Sombra"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #318",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/318.png",
        "dna": "5cfe86b588f1390b1b98a83a2ecff654ce3dc7c4",
        "edition": "318",
        "date": 1650992654176,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Alpha Flames"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #319",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/319.png",
        "dna": "666e9fda30146a5048e790fdcc7bf60f054c0cc5",
        "edition": "319",
        "date": 1650993140419,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Red Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "Mercenary"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #320",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/320.png",
        "dna": "fe4c3b148c4bd31b85ea825591131296aa31f42f",
        "edition": "320",
        "date": 1650994904785,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mullet"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #321",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/321.png",
        "dna": "d1f04f8dbee5309b6bd698937cca7c730635315a",
        "edition": "321",
        "date": 1651002448459,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Punk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #322",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/322.png",
        "dna": "d709750393c28d5f31d45fa1bd1515457553ed6b",
        "edition": "322",
        "date": 1651001443422,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Triangle Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #323",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/323.png",
        "dna": "34bb3975b50da9b9befeea4139951b72606862ec",
        "edition": "323",
        "date": 1651002942763,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Robo Fem"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #324",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/324.png",
        "dna": "9c5a621c8132b1a7e7c61a2cb1313266707071ef",
        "edition": "324",
        "date": 1650991005873,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #325",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/325.png",
        "dna": "aa089a726c477df29a16ce9699546ba963021f27",
        "edition": "325",
        "date": 1650994041723,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 5"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #326",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/326.png",
        "dna": "e14dbdf5e206c22f52f1ba6bb5bbb4ef88c4ef40",
        "edition": "326",
        "date": 1650992296695,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Dread Rockets"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Commando"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Baby Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #327",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/327.png",
        "dna": "ab2815d4e0e26ca5116c103751ccc72f6baef159",
        "edition": "327",
        "date": 1650999318893,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Blue Balls"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #328",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/328.png",
        "dna": "3ef92c873a04faa4b0477dc483634a8a5fcc831e",
        "edition": "328",
        "date": 1651003200318,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #329",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/329.png",
        "dna": "adfb83d158788e54c3049334dd0c8868c7055dde",
        "edition": "329",
        "date": 1651001046086,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Thermals"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #330",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/330.png",
        "dna": "c392c53fcbfb2d99a34734b33ab5d29468b44334",
        "edition": "330",
        "date": 1650995283876,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "From Hell"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Metal Head"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #331",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/331.png",
        "dna": "f4adc1a3bc86284de1e144a73c0dfc377f746348",
        "edition": "331",
        "date": 1651003187018,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #332",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/332.png",
        "dna": "deb11212b1cf2e7f9be42d42f5606b3fe5eeb049",
        "edition": "332",
        "date": 1650998184329,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Tattoo"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #333",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/333.png",
        "dna": "5190402b7975229cb2a38d2854e574253712f075",
        "edition": "333",
        "date": 1651003388342,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Fem"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Warrior Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #334",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/334.png",
        "dna": "779df8a1a47ae44478dd2f9270d9af97bd90cb93",
        "edition": "334",
        "date": 1650989684328,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Ancient 1"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Skull"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Moody Eye"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #335",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/335.png",
        "dna": "de4731bbd74f3c355e5d31d6d8c6e39fe3720fbb",
        "edition": "335",
        "date": 1650995246368,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Dread Rockets"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Heart Throb"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #336",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/336.png",
        "dna": "9a9ac556b240bc2485f8092b493fb2a3d9f367ed",
        "edition": "336",
        "date": 1650992512694,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brain Implants"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #337",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/337.png",
        "dna": "7099c8c239f1bea2a7d09a4e7286dfe9a0a46301",
        "edition": "337",
        "date": 1650992878098,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #338",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/338.png",
        "dna": "c4bfdb6091a31fc14b3f9158a2edc54f721f978e",
        "edition": "338",
        "date": 1651003525217,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Triangle Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "Hydrogen Commando"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #339",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/339.png",
        "dna": "42a2a5a7c728fe833b683516aa79ae586b33c5f3",
        "edition": "339",
        "date": 1650998135498,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #340",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/340.png",
        "dna": "23721424bf31a2cddd5863afec1cb727e043e15a",
        "edition": "340",
        "date": 1650994780785,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyber Shades"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #341",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/341.png",
        "dna": "c643c9bd1cb03449e37caeee63596279faacae63",
        "edition": "341",
        "date": 1650991377384,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "FKU"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #342",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/342.png",
        "dna": "0a9d548798d34c6152a26e10d9c5ed5daa40b733",
        "edition": "342",
        "date": 1650994484326,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Scars"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Green"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "Yogi Bear"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #343",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/343.png",
        "dna": "f104327bc209b75de17c74dbcce408993d5d4c09",
        "edition": "343",
        "date": 1650992825831,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Bio Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #344",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/344.png",
        "dna": "b02587d69ea624cd95a529fc998a0be2f96e7a27",
        "edition": "344",
        "date": 1651003805002,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Angel Wings"
            },
            {
                "trait_type": "BASE",
                "value": "Type 12"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Queen Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #345",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/345.png",
        "dna": "77a7872ecebfb904af508f076a9183be77b17bc8",
        "edition": "345",
        "date": 1650998896328,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #346",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/346.png",
        "dna": "99378a942677baf4bf9a7d18d901075b1442e7a6",
        "edition": "346",
        "date": 1651001963532,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Leather Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #347",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/347.png",
        "dna": "ea53b5e0c01cff45d2c6116c6402444a16da9d44",
        "edition": "347",
        "date": 1650993735371,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Club Shirt"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #348",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/348.png",
        "dna": "b161899269a554d992c0fa1967acc10b4c46b1e7",
        "edition": "348",
        "date": 1651002994955,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cigarette"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Robo Fem"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #349",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/349.png",
        "dna": "b5fb728f8158f12b48d70c2fd795bf32b57bbb1d",
        "edition": "349",
        "date": 1650994449744,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Broad Sword"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Blue Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #350",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/350.png",
        "dna": "38255d8654e744c278de035999770d0a042aaaf0",
        "edition": "350",
        "date": 1650991885880,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Red"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 4"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #351",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/351.png",
        "dna": "7002a797daf3b9338fec5e2f3b09e57624214fa1",
        "edition": "351",
        "date": 1650992754275,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Broad Sword"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Dragon Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Alpha Ranger"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #352",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/352.png",
        "dna": "c99ce5b50df44b35787918523abd1c75cd4d1c3b",
        "edition": "352",
        "date": 1651002265969,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Dread Rockets"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Triangle Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #353",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/353.png",
        "dna": "ae24f2f8f9efada04e31a3b7a9686c64086ba3e2",
        "edition": "353",
        "date": 1650993504845,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 8"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #354",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/354.png",
        "dna": "4018950b56752ed9886fd1207ac9bcf6a99c6c04",
        "edition": "354",
        "date": 1651000515711,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Undercut Green Tip"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #355",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/355.png",
        "dna": "73d70e5bb297de6f18f0725dd08af8189a68a768",
        "edition": "355",
        "date": 1650993035290,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Diamond Wings"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #356",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/356.png",
        "dna": "6122c4452afbba34ac6b4689e98852eae5f45347",
        "edition": "356",
        "date": 1650995506467,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Cropped"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #357",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/357.png",
        "dna": "95a0ef9f40afd3e3c0771f72c5c0562ec7631382",
        "edition": "357",
        "date": 1650993602302,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 5"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Glass Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #358",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/358.png",
        "dna": "2ac7010c4b678f07867a67cf027a1385708f448f",
        "edition": "358",
        "date": 1650989784465,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Scar"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Robo Cop"
            },
            {
                "trait_type": "HAIR",
                "value": "Purple Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Button Up"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #359",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/359.png",
        "dna": "900469adba477938c069d8ace32d2409b4932e46",
        "edition": "359",
        "date": 1650991198992,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 6"
            },
            {
                "trait_type": "EYES",
                "value": "Afterlife"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Buzzed"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Teddy Love u"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #360",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/360.png",
        "dna": "3b92b9b3941585d8fe6fb8571bbbd8331cd72746",
        "edition": "360",
        "date": 1650994731777,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Cyberclops"
            },
            {
                "trait_type": "HAIR",
                "value": "Locks"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #361",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/361.png",
        "dna": "c6bfc62a59bde8dfd6ea589b8d7759deea872b9e",
        "edition": "361",
        "date": 1650997546894,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Ancient 2"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Red Gas Mask"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #362",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/362.png",
        "dna": "6baaac141f9e7c9ccd23c662e3384c6eb2a1ab8d",
        "edition": "362",
        "date": 1650995037739,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Short Messy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Bio Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #363",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/363.png",
        "dna": "18dc3a640b683fcfd42c1768deeeed00b113db63",
        "edition": "363",
        "date": 1650994869471,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 3"
            },
            {
                "trait_type": "EYES",
                "value": "Silver"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Lipstick"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Pink Faux Hawk"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #364",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/364.png",
        "dna": "3bdd6016c13266ea91897edd84491f5fb5e00933",
        "edition": "364",
        "date": 1650989941609,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Purple"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metal scars"
            },
            {
                "trait_type": "HAIR",
                "value": "Bio Plate"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sombra T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #365",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/365.png",
        "dna": "895dc94e82c40937fa36a65487767268ac0708f1",
        "edition": "365",
        "date": 1651000509575,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Turquoise"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Excaliber"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Pink Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Black"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #366",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/366.png",
        "dna": "0bcec51a3528a3caaaa4c76c2c41fc4c2fa64c23",
        "edition": "366",
        "date": 1650994033160,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Brow Ring"
            },
            {
                "trait_type": "HAIR",
                "value": "Shaggy"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #367",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/367.png",
        "dna": "3d401963daf4c39480f70d5bb205732437e41a1f",
        "edition": "367",
        "date": 1651000199345,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 10"
            },
            {
                "trait_type": "EYES",
                "value": "Pink"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Chee Armor"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #368",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/368.png",
        "dna": "c991fed647b8260dc60db63eacb03a0d7164792e",
        "edition": "368",
        "date": 1650992379622,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "Yellow"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Cyber Pomp"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Cyber Vest"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #369",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/369.png",
        "dna": "0f921e48bdf3f9ca6f8d55688feafa9b1cc4ffb1",
        "edition": "369",
        "date": 1650997133374,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid Pink"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Sins T"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #370",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/370.png",
        "dna": "9ba6d8b9a2ed5827f6ef45cbc9743ef60067429c",
        "edition": "370",
        "date": 1651000242553,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "Daddys Gun"
            },
            {
                "trait_type": "BASE",
                "value": "Type 15"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Dread Patch"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Crop Top"
            },
            {
                "trait_type": "HELMET",
                "value": "Shedo Clan"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #371",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/371.png",
        "dna": "3ed1a9e39cde1ab15d28aa61ce32e6b7233e5b54",
        "edition": "371",
        "date": 1651001910487,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Gold"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 9"
            },
            {
                "trait_type": "EYES",
                "value": "Glass"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Human"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Mid White"
            },
            {
                "trait_type": "CLOTHES",
                "value": "BowTie Suit"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #372",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/372.png",
        "dna": "b574c573aa09458bf2a6bcc33566945dc3d89146",
        "edition": "372",
        "date": 1650999302557,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Pink"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 16"
            },
            {
                "trait_type": "EYES",
                "value": "Sleepy"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Diamond"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Viewfinder"
            },
            {
                "trait_type": "HAIR",
                "value": "Curls"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Yellow Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #373",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/373.png",
        "dna": "ef83352f3684edfcd617b2f7f7a2e5a79dacfc81",
        "edition": "373",
        "date": 1650999597594,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Lemon Lime"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 11"
            },
            {
                "trait_type": "EYES",
                "value": "Human"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Jewel Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "PC Visor"
            },
            {
                "trait_type": "HAIR",
                "value": "Messy Bun"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Flight Jacket"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #374",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/374.png",
        "dna": "38ea2dcd7b20abd8f94c233dfc17d5458399cd62",
        "edition": "374",
        "date": 1650994788547,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Blue"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 2"
            },
            {
                "trait_type": "EYES",
                "value": "From Hell"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Gold"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Sombra Earrings"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "FKU"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #375",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/375.png",
        "dna": "3985a8c69eb020bff88caec7233b9c58ed30dfbb",
        "edition": "375",
        "date": 1650991099457,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Peach"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 1"
            },
            {
                "trait_type": "EYES",
                "value": "Purple"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Cyber Jaw"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Color Tattoo"
            },
            {
                "trait_type": "HAIR",
                "value": "Blonde Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    },
    {
        "name": "SINS #376",
        "description": "A cyberpunk of humanoid from the Circinus Galaxy",
        "image": "https://sinsofshadow.s3.amazonaws.com/thumbnail/376.png",
        "dna": "ac171c46efb6d5c384a3e8242e032bc136afbbb3",
        "edition": "376",
        "date": 1650989782557,
        "attributes": [
            {
                "trait_type": "BACKGROUND",
                "value": "Black Yellow"
            },
            {
                "trait_type": "RARIBLES",
                "value": "None"
            },
            {
                "trait_type": "BASE",
                "value": "Type 7"
            },
            {
                "trait_type": "EYES",
                "value": "Bloodshot"
            },
            {
                "trait_type": "MOUTH_NECK",
                "value": "Geen Bubble Gum"
            },
            {
                "trait_type": "ACCESORIES",
                "value": "Metatooth"
            },
            {
                "trait_type": "HAIR",
                "value": "Teal Undercut"
            },
            {
                "trait_type": "CLOTHES",
                "value": "Hoodie"
            },
            {
                "trait_type": "HELMET",
                "value": "None"
            },
            {
                "trait_type": "WEAPONS",
                "value": "None"
            }
        ],
        "compiler": "Verified Code Compiler"
    }
]