import { useEffect } from "react";
import styled from "styled-components";
import RarityHeader from "../components/Home/RarityHeader";
import { colors } from "../styles";
import darkImage from "../assets/images/darkImage.jpg";
import Container from "@material-ui/core/Container";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
}

const MintContainer = styled.div`
  background-color: rgb(${colors.black});
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 70px;
  @media (max-width: 600px) {
    margin-top: 60px;
  }
`;
const Description4 = styled.p`
  font-size: 24px;
  color: white;
  font-family: Gilroy-Light;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  width: 100%;
  letter-spacing: 0.1em;
  @media screen and (max-width: 560px) {
    width: 100%;
    margin-left: 0%;
    font-size: 18px;
  }
`;
const MediumImage = styled.img`
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 20px;
  @media screen and (max-width: 560px) {
  }
  &:hover {
  }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const TopTitle1 = styled.p`
  font-family: Orbitron-Regular;
  font-size: 50px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    margin: 0px auto;
  }
`;

const BodegaScreen = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <TopTitle>STAKING</TopTitle>
      <TopTitle1>STAKING LAUNCHES MAY 17th</TopTitle1>
      <Container>
        <Description4>
          <span style={{ fontFamily: "Gilroy-ExtraBold" }}>SMBR STAKING</span>
          <br />
          <br />
          Your APY is proportional to your staked amount so the larger your pool
          the larger your rewards. Staked SMBR can be withdrawn at any time. You
          can also stake your Sins NFT for a 0.1% boost on your staked SMBR if you
          stake the NFT for 3 months. You must activate your boost on the sins
          web dapp once the time duration is up. It will not auto activate. Once
          boosted it will stay boosted until you unstake the NFT. Once you
          unstake the duration restarts.
          <br />
          <br />
          <span style={{ fontFamily: "Gilroy-ExtraBold" }}>NFT STAKING</span>
          <br />
          <br />
          SOS NFT staking is also available. Each PFP a user stakes increases
          their APY by 0.1% after 3 months - make sure you activate it manually
          after the duration.
          <br />
          <br />
          <br />
          <span style={{ fontFamily: "Gilroy-ExtraBold" }}>
            *Specifics for annual staking rewards and tier system coming soon*
          </span>
        </Description4>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 50,
          }}
        >
          <MediumImage style={{ maxWidth: 943 }} src={darkImage} alt="" />
        </div>
      </Container>
    </MintContainer>
  );
};

export default BodegaScreen;
