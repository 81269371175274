import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../styles";
import Dialog from "@material-ui/core/Dialog";

import styles from "../../components/Home/Footer.module.css";
import close from "../../assets/images/close.png";
import CUCUMBER from "../../assets/images/CUCUMBER.png";
import Eliete from "../../assets/images/Eliete.png";
import Alpha from "../../assets/images/Alpha.png";
import Epic from "../../assets/images/Epic.png";
import Legendary from "../../assets/images/Legendary.png";
import { Rarity_data } from "../../rarity";
import { num_revealed, rarityLink, rarityRankingLink, rairityPreImageLink } from "../../sombra_config";

import {
  createTheme,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";

interface Props {
  ownNFT: Array<any>;
  onCallLock: (tokenId: any) => void;
  onCallUnlock: (tokenId: any) => void;
  ownFlag: boolean;
  lockedFlag: boolean;
}
interface ContainerProps {
  isHide: boolean;
}

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  justify-content: center;
`;
const ItemContainer = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 10px 20px;
  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  @media screen and (max-width: 650px) {
    width: 40%;
    margin: 0;
    margin: 10px 2.5%;
  }
`;
const NftImage = styled.img`
  width: 200px;
  // height: 200px;
  align-self: center;
  border-radius: 15px;
  border: 3px solid white;
  margin-bottom: 10px;
  @media screen and (max-width: 650px) {
    width: 100%;
    // height: 100%;
  }
`;
const Title = styled.p`
  font-size: 18px;
  text-align: start;
  font-family: HRobotikSYSTM-Regular;
  color: white;
  @media screen and (max-width: 560px) {
    font-size: 12px;
    display: flex;
  }
`;
const LoadingImage = styled.img`
  width: 100px;
  height: 100px;
`;
const ModalContent = styled.div`
  position: relative;
  width: 100%;
  background-color: black;
  border: 2px solid #ff56f6;
  box-shadow: 0 4px 6px 0 rgba(50, 50, 93, 0.11);
`;
const ModalCloseBtn = styled.div`
  width: 100%;
  height: 30px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:hover {
    cursor: pointer;
  }
`;
const CloseBtn = styled.img`
  margin-top: 15px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 15px;
  border: 1px solid gray;
`;
const SideContent = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  @media (max-width: 750px) {
    width: 90%;
  }
`;
const SideContent1 = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  @media (max-width: 750px) {
    width: 90%;
  }
`;
const RarityPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 12%;
  padding-left: 7%;
  @media (max-width: 750px) {
    display: none;
  }
`;
const RarityPart1 = styled.div`
  width: 100%;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 10%;
  @media (max-width: 750px) {
    display: flex;
    padding-right: 0%;
    margin: 10px auto;
  }
`;
const PropertyArea = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
  margin-bottom: 30px;
  margin-top: 20px;
  border-top: 1px solid #ff56f6;
`;
const PropertyItemArea = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;
const PropertyItem = styled.div`
  width: 155px;
  height: 60px;
  border-radius: 2px;
  border-width: 2px;
  margin: 10px auto;
  border-image: linear-gradient(to right, #3c79d4 50%, #ffa6fa 100%);
  border-style: solid;
  border-image-slice: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    #d900f9 20%,
    #d500f8 60%,
    #4200ba 105%,
    #390063 120%
  );
  opacity: 0.5f;
  filter: drop-shadow(0 0px 1.2vw rgba(255, 86, 246, 0.51));
  cursor: pointer;
  @media (max-width: 750px) {
    width: 130px;
    height: 60px;
  }
`;
const PropertyTitle = styled.p`
  font-family: RobotoMono-Medium;
  font-size: 24px;
  color: white;
  text-align: center;
`;
const PropertyValue = styled.p`
  font-family: RobotoMono-Bold;
  font-size: 13px;
  text-align: center;
  margin: 0;
  color: #ffcd6c;
  @media (max-width: 750px) {
    font-size: 10px !important;
  }
`;
const RarityImage = styled.img`
  width: 133px;
  @media (max-width: 750px) {
    width: 80px;
  }
`;
const AvartarImg1 = styled.img`
  width: 90%;
  margin: auto;
  display: flex;
`;
const ModalItemTitle = styled.p`
  font-size: 24px;
  margin-left: 10%;
  font-family: Roboto-Bold;
  font-weight: bold;
  color: white;
  @media (max-width: 750px) {
    font-size: 18px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // @media (max-width: 600px) {
  //   margin-top: 50px;
  // }
`;
const ModalContentArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const OwnerArea = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1250px;
  width: 90%;
`;

const ButtonItem = styled.div`
  width: 155px;
  height: 60px;
  border-radius: 15px;
  border-width: 1px;
  margin: 10px auto;
  // border-image: linear-gradient(to right, #3c79d4 50%, #ffa6fa 100%);
  border-style: solid;
  border-image-slice: 1;
  border-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    #6c01cc 20%,
    #6c01cc 60%,
    #6c01cc 105%,
    #6c01cc 120%
  );
  opacity: 0.5f;
  filter: drop-shadow(0 0px 1.2vw rgba(255, 86, 246, 0.51));
  cursor: pointer;
  @media (max-width: 750px) {
    width: 130px;
    height: 60px;
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 689,
      width: 1000,
      marginTop: 200,
    },
    paper: {
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 10,
      padding: theme.spacing(2, 4, 3),
      width: "100%",
    },
    dialog: {
      maxWidth: 600,
      height: 689,
    },
    itemTitle: {
      textAlign: "center",
      fontFamily: "PlusJakartaSans-Regular",
      fontSize: 16,
      fontWeight: 700,
    },
  })
);

const HomeContainer: React.FC<Props> = ({
  ownNFT,
  ownFlag,
  onCallLock,
  lockedFlag,
  onCallUnlock,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [modalContent, setModalContent] = useState<any>({});
  const [rarityValue, setrarityValue] = useState("");
  const [rarityRanking, setrarityRanking] = useState("")
  const [openseaLink, setOpenseaLink] = useState("");
  const [raribleLink, setRaribleLink] = useState("");
  const [rarityImage, setRarityImage] = useState("");
  const [rarityName, setRarityName] = useState("");

  useEffect(() => {
    // console.log("ownNFT", ownNFT);
  }, [ownNFT]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = async (item: any) => {
    let raribleLink = "https://rarible.com/token/0x7fdc538356b1c137dc238001e92d480f197dedeb:"+item.edition+"?tab=details"
    let openseaLink = "https://opensea.io/assets/0x7fdc538356b1c137dc238001e92d480f197dedeb/"+item.edition
    setOpenseaLink(openseaLink)
    setRaribleLink(raribleLink)
    let rarityLik = rarityLink + item.edition + ".json";
    let rarityRankingLik = rarityRankingLink + item.edition + ".json";
    await fetch(rarityLik)
      .then((res) => res.json())
      .then((json) => {
        console.log("item", json.rarity);
        setrarityValue(json.rarity);
        if (json.rarity >= 330) {
          setRarityImage(CUCUMBER);
          setRarityName("Cucumber");
        } else if (json.rarity >= 264 && json.rarity < 330) {
          setRarityImage(Alpha);
          setRarityName("Alpha");
        } else if (json.rarity >= 198 && json.rarity < 264) {
          setRarityImage(Legendary);
          setRarityName("Legendary");
        } else if (json.rarity >= 132 && json.rarity < 198) {
          setRarityImage(Eliete);
          setRarityName("Elite");
        } else {
          setRarityImage(Epic);
          setRarityName("Epic");
        }
      });
    await fetch(rarityRankingLik)
      .then((res) => res.json())
      .then((json) => {
        setrarityRanking(json.ranking);
      });
    setModalContent(item);
    setOpen(true);
  };
  const calculateRarity = (rarityData: any) => {
    if (rarityData === undefined) {
      return "";
    }
    if (parseInt(rarityData) >= 0 && parseInt(rarityData) <= 4) {
      return "COMMON";
    } else if (parseInt(rarityData) >= 5 && parseInt(rarityData) <= 6) {
      return "RARE";
    }
    return "EPIC";
  };
  const getChance = (trait: string, value: string) => {
    console.log("trait", trait, value);
    let rarityData = Rarity_data;
    let tempObj = rarityData[trait as keyof typeof rarityData];
    let chanceValue = tempObj[value as keyof typeof tempObj];
    return chanceValue["chance" as keyof typeof chanceValue];
  };

  return (
    <Content>
      {/* <TopTitle>RARITY</TopTitle> */}
      {ownNFT.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={styles.social_item}>
            <p
              style={{
                color: "white",
                fontSize: 20,
                fontFamily: "Orbitron-Regular",
              }}
            >
              {lockedFlag
                ? "You don't have any unlocked NFTs."
                : "There is no filtered NFTs."}
            </p>
          </div>
        </div>
      ) : (
        <BannerContainer>
          {ownNFT.map((item, index) => {
            return (
              <ItemContainer
                key={index}
                onClick={() => {
                  handleOpen(item);
                }}
              >
                <NftImage
                  src={
                    item.edition > num_revealed
                      ? rairityPreImageLink
                      : item.image
                  }
                  alt=""
                />
                <Title>{item.name}</Title>
                {/* <Title>{item.description}</Title> */}
              </ItemContainer>
            );
          })}
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
          >
            <ModalContent>
              <ModalCloseBtn
                onClick={handleClose}
                id="transition-modal-description"
              >
                <CloseBtn src={close} />
              </ModalCloseBtn>
              <ModalContentArea>
                <SideContent>
                  <RarityPart1>
                    <div>
                      <ModalItemTitle style={{ margin: 0 }}>
                        RARITY VALUE :{" "}
                        <span
                          style={{
                            fontFamily: "RobotoMono-Regular",
                            color: "#FF00F5",
                            fontWeight: 400,
                          }}
                        >
                          {/* {calculateRarity(modalContent.rarityData)} */}
                          {parseFloat(rarityValue).toFixed(0)}
                        </span>
                      </ModalItemTitle>
                      <ModalItemTitle style={{ margin: 0 }}>
                        RARITY RANKING :{" "}
                        <span
                          style={{
                            fontFamily: "RobotoMono-Regular",
                            color: "#FF00F5",
                            fontWeight: 400,
                          }}
                        >
                          {/* {calculateRarity(modalContent.rarityData)} */}
                          {parseFloat(rarityRanking).toFixed(0)}
                        </span>
                      </ModalItemTitle>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <RarityImage src={rarityImage} alt="" />
                      <p style={{ color: "white", fontSize: 20, margin:0 }}>
                        {rarityName}
                      </p>
                    </div>
                  </RarityPart1>
                  <AvartarImg1
                    src={
                      modalContent.tokenId > num_revealed
                        ? rairityPreImageLink
                        : modalContent.image
                    }
                    alt=""
                  />
                  <ModalItemTitle>{modalContent.name}</ModalItemTitle>
                </SideContent>
                <SideContent1>
                  <RarityPart>
                    <div>
                      <ModalItemTitle style={{ margin: 0 }}>
                        RARITY VALUE :{" "}
                        <span
                          style={{
                            fontFamily: "RobotoMono-Regular",
                            color: "#FF00F5",
                            fontWeight: 400,
                          }}
                        >
                          {/* {calculateRarity(modalContent.rarityData)} */}
                          {parseFloat(rarityValue).toFixed(0)}
                        </span>
                      </ModalItemTitle>
                      <ModalItemTitle style={{ margin: 0 }}>
                        RARITY RANKING :{" "}
                        <span
                          style={{
                            fontFamily: "RobotoMono-Regular",
                            color: "#FF00F5",
                            fontWeight: 400,
                          }}
                        >
                          {/* {calculateRarity(modalContent.rarityData)} */}
                          {parseFloat(rarityRanking).toFixed(0)}
                        </span>
                      </ModalItemTitle>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <RarityImage src={rarityImage} alt="" />
                      <p style={{ color: "white", fontSize: 20, margin:0 }}>
                        {rarityName}
                      </p>
                    </div>
                  </RarityPart>
                  <PropertyArea>
                    <PropertyTitle>Properties</PropertyTitle>
                    <PropertyItemArea>
                      {open &&
                        modalContent.attributes.map((item: any, index: any) => {
                          return (
                            <PropertyItem key={index}>
                              <PropertyValue>{item.trait_type}</PropertyValue>
                              <PropertyValue
                                style={{ fontSize: 15, color: "white" }}
                              >
                                {item.value.toLowerCase() == "none"
                                  ? "n/a"
                                  : item.value}
                              </PropertyValue>
                              {/* <PropertyValue
                                style={{
                                  fontSize: 11,
                                  color: "white",
                                  fontFamily: "RobotoMono-Regular",
                                }}
                              >
                                {getChance(item.trait_type, item.value)}% have
                                this trait
                              </PropertyValue> */}
                            </PropertyItem>
                          );
                        })}
                        <>
                          <div
                            style={{
                              width: "100%",
                              height: 2,
                              backgroundColor: "#ff56f6",
                            }}
                          />
                          <ButtonItem
                            onClick={() => {
                              window.open(raribleLink, "_blank");
                            }}
                            style={{ height: 50, marginTop: 20 }}
                          >
                            <PropertyValue
                              style={{ fontSize: 20, color: "white" }}
                            >
                              Rarible
                            </PropertyValue>
                          </ButtonItem>
                          <ButtonItem
                            onClick={() => {
                              window.open(openseaLink, "_blank");
                            }}
                            style={{ height: 50, marginTop: 20 }}
                          >
                            <PropertyValue
                              style={{ fontSize: 20, color: "white" }}
                            >
                              Opensea
                            </PropertyValue>
                          </ButtonItem>
                          {/* {lockedFlag ? (
                            <ButtonItem
                              onClick={() => {
                                onCallUnlock(modalContent.tokenId);
                              }}
                              style={{ height: 50, marginTop: 20 }}
                            >
                              <PropertyValue
                                style={{ fontSize: 20, color: "white" }}
                              >
                                Unlock
                              </PropertyValue>
                            </ButtonItem>
                          ) : (
                            <ButtonItem
                              onClick={() => {
                                onCallLock(modalContent.tokenId);
                              }}
                              style={{ height: 50, marginTop: 20 }}
                            >
                              <PropertyValue
                                style={{ fontSize: 20, color: "white" }}
                              >
                                Lock1
                              </PropertyValue>
                            </ButtonItem>
                          )} */}
                        </>
                    </PropertyItemArea>
                  </PropertyArea>
                </SideContent1>
              </ModalContentArea>
            </ModalContent>
          </Dialog>
        </BannerContainer>
      )}
    </Content>
  );
};

export default HomeContainer;
