import React, { useEffect, useState, createRef } from "react";
import styles from "./Footer.module.css";
import twitter from "../../assets/images/mdi_twitter.png";
import telegram from "../../assets/images/ic_round-telegram.png";
import instagram from "../../assets/images/uim_instagram-alt.png";
import discord from "../../assets/images/jam_discord.png";
import buyBtn from "../../assets/images/buyBtn.png";
import contactBottomImage from "../../assets/images/contactBottomImage.png";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  background-image: url(/images/footerBackImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  color: white;
  flex-direction: column;
  margin-top: -80px;
  padding-top: 100px;
  z-index: 200;
  position: relative;
  @media screen and (max-width: 600px) {
    width: 100%;
    display: block;
  }
`;
const BuyBtnArea = styled.div`
  background-image: url(/images/buyAreaBackImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: scratch;
  padding: 70px 50px;
  margin-top: -50px;
  flex-direction: row;
  display: flex;
  @media screen and (max-width: 600px) {
    margin-top: 0px;
    padding: 35px 20px;
  }
`

const Footer = () => {
  useEffect(() => { }, []);

  return (
    <Container>
      <div className={styles.header_container}>
        <h1 className={styles.topTitle}>
          Client / Partners / Affliates / Softwares
        </h1>
        <div className={styles.desktopVisible}>
          <div className={styles.swapArea}>
            <img
              className={styles.coinmarketcap}
              alt=""
              src="/coinmarketcap.png"
            />
            <img className={styles.coingecko} alt="" src="/coingecko.png" />
            <img className={styles.uniswap} alt="" src="/uniswap.png" />
            <img className={styles.pancakeswap} alt="" src="/pancakeswap.png" />
            <img className={styles.quickswap} alt="" src="/quickswap.png" />
          </div>
          <div className={styles.swapArea}>
            <img className={styles.book} alt="" src="/bscscanImage.png" />
            <img className={styles.dappradar} alt="" src="/dappradar.png" />
            <img className={styles.bonfire} alt="" src="/bonfire.png" />
            <img className={styles.j} alt="" src="/j.png" />
            <img className={styles.unreal} alt="" src="/unreal.png" />
            <img className={styles.unity} alt="" src="/unity.png" />
          </div>
        </div>
        <div className={styles.mobileVisible}>
          <div className={styles.swapArea}>
            <img
              className={styles.coinmarketcap}
              alt=""
              src="/coinmarketcap.png"
            />
            <img className={styles.coingecko} alt="" src="/coingecko.png" />
            <img className={styles.dappradar} alt="" src="/dappradar.png" />
          </div>
          <div className={styles.swapArea}>
            <img className={styles.uniswap} alt="" src="/uniswap.png" />
            <img className={styles.pancakeswap} alt="" src="/pancakeswap.png" />
            <img className={styles.quickswap} alt="" src="/quickswap.png" />
          </div>
          <div className={styles.swapArea}>
            <img className={styles.bonfire} alt="" src="/bonfire.png" />
            <img className={styles.j} alt="" src="/j.png" />
            <img className={styles.unreal} alt="" src="/unreal.png" />
            <img className={styles.unity} alt="" src="/unity.png" />
          </div>
        </div>
      </div>
      <div className={styles.social_container}>
        <div className={styles.social_item}>
          <div className={styles.first_social_item} style={{letterSpacing:"13px", fontSize:18}}>CONTACT US</div>
          <img src={contactBottomImage} alt="" />
          <div className={styles.socialButtonArea}>
            <div className={styles.link_social_item}>
              <a href="https://twitter.com/SombraNetwork" target={"_blank"}>
                <img className={styles.socialImage} src={twitter} alt="" />
              </a>
            </div>
            <div className={styles.link_social_item}>
              <a href="/" target={"_blank"}>
                <img className={styles.socialImage} src={instagram} alt="" />
              </a>
            </div>
            <div className={styles.link_social_item}>
              <a href="https://t.me/sombranetwork" target={"_blank"}>
                <img className={styles.socialImage} src={telegram} alt="" />
              </a>
            </div>
            <div className={styles.link_social_item}>
              <a href="https://discord.gg/TFax3brq52" target={"_blank"}>
                <img className={styles.socialImage} src={discord} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.social_item}>
          <div className={styles.first_social_item}>RESOURCES</div>
          <div className={styles.link_social_item}>
            <a
              href="https://express.adobe.com/page/AVHVkM7HXH9SM/"
              target={"_blank"}
            >
              Dark Paper
            </a>
          </div>
          <div className={styles.link_social_item}>
            <a href="https://www.sombraswap.com" target={"_blank"}>
              SMBR
            </a>
          </div>
          <div className={styles.link_social_item}>
            <a href="/">Market</a>
          </div>
          <div className={styles.link_social_item}>
            <a href="/law">Sombra Law</a>
          </div>
          <div className={styles.link_social_item}>
            <a href="/Terms">Terms & Conditions</a>
          </div>
          <div className={styles.link_social_item}>
            <a href="/privacy">Privacy Policy</a>
          </div>
        </div>
        <BuyBtnArea>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: -20 }}
          >
            <div className={styles.social_item} style={{ margin: 0 }}>
              <div className={styles.bnb_smbr}>
                <img className={styles.ethe_icon} src="/footer_etheImage.png" />
              </div>
              <a href="https://eth.sombraswap.io/#/" target={"_blank"}>
                <img src={buyBtn} alt="buyBtn" className={styles.buyBtn} />
              </a>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: -20 }}
          >
            <div className={styles.social_item} style={{ margin: 0 }}>
              <div className={styles.bnb_smbr}>
                <img className={styles.ethe_icon} src="/footer_bnbImage.png" />
              </div>
              <a href="https://bsc.sombraswap.io/#/" target={"_blank"}>
                <img src={buyBtn} alt="buyBtn" className={styles.buyBtn} />
              </a>
            </div>
          </div>
        </BuyBtnArea>
      </div>
    </Container>
  );
};

export default Footer;
