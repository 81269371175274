import React from "react";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

import prepareBattleBack from "src/assets/images/prepareBattleBack.jpg";
import prepareMobileBack from "src/assets/images/prepareMobileBack.jpg";
import prepareTextImg from "src/assets/images/prepareTextImg.png";
import signupButton from "src/assets/images/signupButton.png";
import globeicon from "src/assets/images/globeicon.png";
import teamicon from "src/assets/images/teamicon.png";
import settingsicon from "src/assets/images/settingsicon.png";
import iicon from "src/assets/images/iicon.png";
import carticon from "src/assets/images/carticon.png";
import deliveryicon from "src/assets/images/deliveryicon.png";
import characterImg from "src/assets/images/characterImg.png";
import noteImg from "src/assets/images/noteImg.png";
import dangerWalkImg from "src/assets/images/dangerWalkImg.png";
import mapsImg from "src/assets/images/mapsImg.png";
import toolsImg from "src/assets/images/toolsImg.png";
import twitter from "src/assets/images/mdi_twitter.png";
import telegram from "src/assets/images/ic_round-telegram.png";
import instagram from "src/assets/images/uim_instagram-alt.png";
import discord from "src/assets/images/jam_discord.png";
import contactUsImg from "src/assets/images/contactUsImg.png";

const Content = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .prepareBattleBack {
    width: 100%;
  }
  .prepareMobileBack {
    display: none;
    width: 100%;
  }
  @media screen and (max-width: 1300px) {
    background-image: url(/images/prepareBattleBack.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
    .prepareBattleBack {
      display: none;
      position: absolute;
    }
  }
  @media screen and (max-width: 800px) {
    background-image: url(/images/prepareMobileBack.jpg);
  }
`;

const LeftMainArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 700px;
  position: absolute;
  bottom: 23%;
  left: 10%;
  .prepareTextImg {
    width: 100%;
  }
  @media screen and (max-width: 1560px) {
    bottom: 100px;
    left: 150px;
  }
  @media screen and (max-width: 1300px) {
    position: relative;
    margin-top: 200px;
    max-width: 400px;
    width: 90%;
    left: 0px;
    bottom: 0px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 650px) {
    position: relative;
    max-width: 400px;
    width: 90%;
    left: 0px;
    bottom: 0px;
    margin-bottom: 30px;
    height: 450px;
    justify-content: space-around;
    margin-top: 70px;
  }
`;

const SignUpButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const SocialButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  .seperate {
    display: flex;
    flex-direction: row;
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
`;
const SliderArea = styled.div`
  width: 100%;
  padding: 0 0.2rem;
  margin: 0 auto;
  margin-top: 30px;
  .slider {
  }
  .featureSliderItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .featureSliderItem:hover {
    cursor: pointer;
  }
  .sliderImage {
    width: 200px;
  }
  @media screen and (max-width: 1300px) {
    .sliderImage {
      width: 150px;
    }
  }
`;

const SocialButton = styled.img`
  width: 40px;
  &:hover {
    cursor: pointer;
  }
  margin: 10px 20px;
  // @media screen and (max-width: 1000px) {
  //   width: 30px;
  // }
`;

const RightMainArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  right: 0px;
  .socialButtons {
    display: flex;
    flexdirection: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .socialImage {
    width: 30px;
    margin: 0 20px;
    &:hover {
      cursor: pointer;
    }
  }
  @media screen and (max-width: 1300px) {
    position: relative;
    bottom: 0px;
    margin-top: 100px;
    margin-bottom: 30px;
    right: 0px;
  }
  @media screen and (max-width: 650px) {
    position: relative;
    bottom: 0px;
    margin-top: 30px;
    margin-bottom: 30px;
    right: 0px;
  }
`;

const ContactImage = styled.img`
  width: 200px;
  margin-bottom: 10px;
`;

export default function PrepareBattle() {
  //slider settigns
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      // {
      //   breakpoint: 780,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //     infinite: true,
      //     initialSlide: 1,
      //   },
      // },
    ],
  };

  let sliderList = [
    {
      id: 1,
      imageUrl: characterImg,
      link: "browse",
    },
    {
      id: 2,
      imageUrl: mapsImg,
      link: "game",
    },
    {
      id: 3,
      imageUrl: toolsImg,
      link: "rarity",
    },
    {
      id: 4,
      imageUrl: dangerWalkImg,
      link: "smbr",
    },
    {
      id: 5,
      imageUrl: noteImg,
      link: "litepaper",
    },
  ];

  let history = useHistory();

  return (
    <Content>
      <img
        className="prepareBattleBack"
        src={prepareBattleBack}
        alt="prepareBattleBack"
      />
      <img
        className="prepareMobileBack"
        src={prepareMobileBack}
        alt="prepareMobileBack"
      />
      <LeftMainArea>
        <img
          className="prepareTextImg"
          src={prepareTextImg}
          alt="prepareBattleBack"
        />
        <SignUpButton
          onClick={() => {
            window.open("https://discord.gg/TFax3brq52", "_blank");
          }}
        >
          <img
            className="signupButton"
            src={signupButton}
            alt="prepareBattleBack"
            width="150px"
          />
        </SignUpButton>
        {/* <SocialButtonArea>
          <div className="seperate">
            <SocialButton src={globeicon} alt="prepareBattleBack" />
            <SocialButton src={teamicon} alt="prepareBattleBack" />
            <SocialButton src={settingsicon} alt="prepareBattleBack" />
          </div>
          <div className="seperate">
            <SocialButton src={iicon} alt="prepareBattleBack" />
            <SocialButton src={carticon} alt="prepareBattleBack" />
            <SocialButton src={deliveryicon} alt="prepareBattleBack" />
          </div>
        </SocialButtonArea> */}
        <SliderArea>
          <Slider className="slider" {...settings}>
            {sliderList.map((item, index) => (
              <div
                className="featureSliderItem"
                onClick={() => {
                  history.push(`/${item.link}`);
                }}
              >
                <img
                  className="sliderImage"
                  style={{ margin: 0 }}
                  width="150"
                  src={item.imageUrl}
                  alt=""
                  key={item.id}
                />
              </div>
            ))}
          </Slider>
        </SliderArea>
      </LeftMainArea>
      <RightMainArea>
        <ContactImage src={contactUsImg} alt="contact" />
        <div className="socialButtons">
          <a href="https://twitter.com/SombraNetwork" target="_blank">
            <img className="socialImage" src={twitter} alt="" width="30" />
          </a>
          <a href="/">
            <img className="socialImage" src={instagram} alt="" width="30" />
          </a>
          <a href="https://t.me/sombranetwork" target="_blank">
            <img className="socialImage" src={telegram} alt="" width="30" />
          </a>
          <a href="https://discord.gg/TFax3brq52" target="_blank">
            <img className="socialImage" src={discord} alt="" width="30" />
          </a>
        </div>
      </RightMainArea>
    </Content>
  );
}
