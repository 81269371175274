import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { countDownData, openSeaLink } from "../../sombra_config";
import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../styles";
import { useHistory } from "react-router-dom";

import Gun from "../../assets/images/Gun.png";
import GameController from "../../assets/images/GameController.png";
import ship from "../../assets/images/ship.png";
import VR from "../../assets/images/VR.png";
import avatarsImage from "../../assets/images/landingAvatarsImage.jpg";
import charactersImage from "../../assets/images/charactersImage.jpg";
import deoImg from "../../assets/images/deoImg.jpg";
import PrepareBattle from "./PrepareBattle";
import WelcomContent from "./WelcomContent";
import videoBoxImage from "src/assets/images/videoBoxImage2.png";

// Material ui style
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  textCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  imgCont: {
    display: "flex",
    justifyContent: "center",
  },
  progress: {
    width: "80%",
    height: 14,
    borderRadius: 7,
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  topArea: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
  },
  stackarea: {
    backgroundColor: "#8F4AFF",
    [theme.breakpoints.down("md")]: {
      marginTop: -40,
    },
  },
  calcArea: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
}));

interface ButtonProps {
  status: number;
}

// styled-components
const GenesisTitle = styled.p`
  font-size: 36px;
  color: white;
  font-family: Gilroy-Light;
  margin: 0;
  padding-bottom: 40px;
  text-align: center;
  font-weight: bold;
  max-width: 700px;
  width: 90%;
  @media (max-width: 800px) {
    font-size: 18px !important;
  }
`;
const TopTitle = styled.p`
  font-size: 48px;
  color: white;
  font-family: Orbitron-Regular;
  margin: 0;
  padding-top: 40px;
  text-align: center;
  font-weight: bold;
  @media (max-width: 800px) {
    font-size: 24px !important;
  }
`;
const TopTitle1 = styled.p`
  font-size: 32px;
  color: white;
  font-family: Orbitron-Regular;
  margin: 0;
  padding-top: 40px;
  text-align: center;
  font-weight: bold;
  @media (max-width: 800px) {
    font-size: 20px;
  }
`;
const TopTitle2 = styled.p`
  font-size: 80px;
  color: white;
  font-family: Orbitron-Regular;
  margin: 0;
  padding-top: 40px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.1em;
  @media (max-width: 800px) {
    font-size: 32px !important;
  }
`;
const Description = styled.p`
  font-size: 24px;
  color: white;
  font-family: BaiJamjuree-Regular;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1000px;
  width: 90%;
  @media (max-width: 980px) {
    font-size: 16px;
  }
`;
const HorizontalLineArea = styled.div`
  width: 100%;
  height: 1px;
  max-width: 1250px;
  background-color: gray;
  box-shadow: 0.5px 1px 1px 0 #ff56f6;
  margin-bottom: 25px;
  margin-top: 25px;
  @media screen and (max-width: 600px) {
    margin-top: 0px !important;
  }
`;
const ToolsArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;
const DeoImageArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
`;
const DeoVideoArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: absolute;
  height: 100%;
  margin-top: auto;
  align-items: center;
  margin-bottom: auto;
  justify-content: center;
`;
const VideoBox = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1100px;
  width: 95%;
`
const ImageIteamArea = styled.div`
  // background-image: url(/images/imageItemBackImg.png);
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
`;
const ImageItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  padding: 0 50px;
  @media screen and (max-width: 660px) {
    padding: 0;
  }
`;
const WidgetImage = styled.img`
  width: 35%;
  @media screen and (max-width: 1100px) {
    width: 300px;
  }
  @media screen and (max-width: 860px) {
    width: 200px;
  }
  @media screen and (max-width: 660px) {
    width: 150px;
  }
`;
const Container1 = styled.div`
  background-color: rgb(${colors.black});
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BannerContainer = styled.div`
  background-color: rgb(${colors.black});
  background-image: url(/images/background1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 120vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media screen and (max-width: 1550px) {
    min-height: 100vh;
  }
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const TopImage = styled.img`
  width: 100%;
  //   &:hover {
  //     transform: scale(1.025);
  //     -ms-transform: scale(1.025);
  //     box-shadow: 4px 4px 6px 0 #ff56f6;
  //   }
`;
const MediumImage = styled.img`
  width: 431px;
  margin-bottom: 100px;
  margin-top: 50px;
  border-radius: 20px;
  @media (max-width: 500px) {
    width: 70%;
    margin-bottom: 50px;
    margin-top: 50px;
  }
`;
const Calculator = styled.div`
  // width: 100px;
  // margin: 0 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-right: -27px;
`;
const LogoTitle = styled.p`
  color: white;
  font-size: 24px;
  align-self: center;
  font-family: BaiJamjuree-Regular;
  font-weight: 700;
  @media (max-width: 660px) {
    font-size: 22px;
  }
  &:hover {
    cursor: pointer;
    text-shadow: 0 4px 8px 0 rgba(${colors.main}),
      0 6px 10px 0 rgba(${colors.main});
  }
`;
const LogoTitle1 = styled.p`
  color: #000;
  font-size: 18px;
  text-align: center;
  display: inline-block;
  background-color: #c4c4c4;
  width: 110px;
  height: 30px;
  border-radius: 15px;
  padding-top: 3px;
  font-family: Orbitron-Regular;
  @media (max-width: 660px) {
    font-size: 16px;
  }
`;
const LogoTitle4 = styled.p`
  color: #ffffff;
  font-size: 25px;
  text-align: center;
  display: inline-block;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
  &:hover {
    cursor: pointer;
  }
`;
const CountDownTxt = styled.p`
  color: #ffffff;
  font-size: 48px;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  margin: 0;
  @media (max-width: 660px) {
    font-size: 22px;
  }
`;
const AmountInput = styled.input`
  outline: none;
  margin: 0 1em;
  background-color: #c4c4c4;
  width: 110px;
  height: 30px;
  border-radius: 15px;
  border: 0;
  padding-left: 10px;
  text-align: center;
  margin: 10px;
`;
const AprrovingArea = styled.div`
  width: 70%;
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  align-items: center;
  @media screen and (max-width: 959px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 0em;
  }
`;
const BottomButtonArea = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  margin-top: 70px;
  @media screen and (max-width: 960px) {
    width: 100%;
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0em;
  }
`;
const BottomButton = styled.div`
  width: 297px;
  height: 60px;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: linear-gradient(
    120deg,
    rgba(60, 121, 212, 1),
    rgba(255, 166, 250, 0.47)
  );
  border-radius: 30px;
  border-width: 2px;
  padding: 2px;
  @media screen and (max-width: 959px) {
    width: 80%;
    justify-content: center;
    margin-bottom: 20px;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(${colors.main}),
      0 6px 10px 0 rgba(${colors.main});
  }
`;
const BottomButtonText = styled.div`
  width: 293px;
  height: 56px;
  background: linear-gradient(
    120deg,
    rgba(217, 0, 249, 1),
    rgba(66, 0, 138, 0.47)
  );
  color: white;
  font-size: 18px;
  font-family: Orbitron-Regular;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  @media screen and (max-width: 959px) {
    width: 100%;
  }
`;

const CenterButton = styled.div`
  width: 293px;
  height: 56px;
  background: linear-gradient(
    120deg,
    rgba(217, 0, 249, 1),
    rgba(66, 0, 138, 0.47)
  );
  color: white;
  font-size: 18px;
  font-family: Orbitron-Regular;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  justify-content: center;
  border-radius: 28px;
  @media screen and (max-width: 959px) {
    width: 100%;
  }
`;

const ProcessingArea = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  margin: 0 auto;
  align-items: flex-start;
  @media screen and (max-width: 959px) {
    width: 100%;
    justify-content: center;
    padding-bottom: 2em;
  }
`;
const ProcessingBar = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
  align-items: center;
  margin: 0 auto;
  // @media screen and (max-width: 959px) {
  //   width: 100%;
  //   justify-content: center;
  //   padding-bottom: 2em;
  // }
`;
const ProcessingLine = styled.div`
  width: 50%;
  height: 3px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
  // @media screen and (max-width: 959px) {
  //   width: 100%;
  //   justify-content: center;
  //   padding-bottom: 2em;
  // }
`;
const ProcessingLine1 = styled.div`
  width: 100%;
  height: 3px;
  background-color: #ff56f6;
  flex-direction: column;
  display: flex;
  // @media screen and (max-width: 959px) {
  //   width: 100%;
  //   justify-content: center;
  //   padding-bottom: 2em;
  // }
`;
const CalArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  text-align: center;
  max-width: 450px;
  width: 100%;
  padding: 40px;
  margin-top: -2em;
  border-radius: 10px;
  @media (max-width: 660px) {
    padding: 20px;
  }
`;
const CalContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #9f6fed;
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
`;
const BuyButton = styled.button<ButtonProps>`
  color: white;
  font-size: 14px;
  border-radius: 22.5px;
  width: 50%;
  padding: 12px;
  font-weight: 700;
  margin-top: 50px;
  font-family: Orbitron-Regular;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 200px;
  height: 45px;
  background: linear-gradient(
    ${(props) =>
    props.status === 1
      ? "0deg, rgba(77, 71, 99,1), rgba(77,71,99,0.47)"
      : "0deg, rgba(255, 0, 214,1), rgba(255,0,175,0.47)"}
  );
  // background: linear-gradient(0deg, rgba(255, 0, 214,1), rgba(255,0,175,0.47) );
  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(${colors.main}),
      0 6px 10px 0 rgba(${colors.main});
  }
`;

const ContainerVideo = styled.video`
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
  display: flex;
  padding: 1.3% 1.2% 1.2% 1%;
  border-radius: 30px 55px 40px 65px;
  @media screen and (max-width: 650px) {
    border-radius: 15px 20px 20px 25px;
    padding: 1.1% 1.2% 1% 1%;
    padding-bottom: -10px;
  }
`;

const CountDownArea = styled.div`
  color: white;
  font-size: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: -10px;
  font-weight: 700;
  @media (max-width: 660px) {
    font-size: 22px;
  }
`;

interface Props {
  balance: number;
  totalPrice: number;
  onMintChangeButtonClick: (dir: number) => void;
  onMaxButtonClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCallMint: () => void;
  add_chain_with_web3: () => void;
  amount: number;
  maxAmount: number;
  itemPrice: number;
  mintLoading: number;
  connect: () => void;
  connected: boolean;
  connectFlag: boolean;
}

const BannerContent: React.FC<Props> = ({
  balance,
  onMintChangeButtonClick,
  onMaxButtonClick,
  onCallMint,
  amount,
  totalPrice,
  maxAmount,
  itemPrice,
  connect,
  connected,
  connectFlag,
  add_chain_with_web3,
  mintLoading,
}) => {
  let history = useHistory();

  const [daysInfo, setDaysInfo] = useState(0);
  const [hoursInfo, setHoursInfo] = useState(0);
  const [minutesInfo, setMinutesInfo] = useState(0);
  const [secondsInfo, setSecondsInfo] = useState(0);
  const [expiredFlag, setExpiredFlag] = useState(false);
  const [loadingNum, setLoadingNum] = useState(0);

  useEffect(() => {
    setLoadingNum(mintLoading);
  }, [mintLoading]);

  useEffect(() => {
    AOS.init();
    if (countDownData.countdown) {
      var countDownDate = countDownData.countDownDate;
      var x = setInterval(function () {
        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setDaysInfo(days);
        setHoursInfo(hours);
        setMinutesInfo(minutes);
        setSecondsInfo(seconds);

        // Display the result in the element with id="demo"

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          setExpiredFlag(true);
        }
      }, 1000);
    }
  }, []);

  const classes = useStyles();

  const warning = () => {
    alert("Not quite ready yet");
  };

  const navigateTo = (targetPath: string) => {
    history.push(`/${targetPath}`);
  };

  const dateToFormat = "2022-08-17T20:00-0000";

  const [timeStatus, setTimeStatus] = useState(false);

  const eventFunction = () => {
    // var now = mmt(new Date());
    // var end = mmt(dateToFormat);
    // var duration = mmt.duration(now.diff(end));
    // if (duration >= 0) {
    //     setTimeStatus(true);
    // }
  };

  return (
    <Container1>
      <BannerContainer>
        <MainContent>
          <PrepareBattle />
          <WelcomContent />
          <TopImage src={avatarsImage} alt="" style={{ borderRadius: 0 }} />
          <ToolsArea>
            <img src="/images/imageItemBackImg.jpg" alt="" width="100%" />
            <ImageIteamArea>
              <WidgetImage src={GameController} alt="" />
              <WidgetImage src={Gun} alt="" />
              <WidgetImage src={ship} alt="" />
              <WidgetImage src={VR} alt="" />
            </ImageIteamArea>
          </ToolsArea>
          <DeoImageArea>
            <TopImage src={deoImg} alt="" style={{ borderRadius: 0 }} />
            <DeoVideoArea>
              <VideoBox>
                <ContainerVideo
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source
                    src="https://sinsofshadow.s3.amazonaws.com/videos/SINS_DEBUT_1.mp4"
                    type="video/mp4"
                  />
                </ContainerVideo>
                <img src={videoBoxImage} alt="" style={{ position: 'absolute', width: '100%', height: '100%' }} />
              </VideoBox>
            </DeoVideoArea>
          </DeoImageArea>
          <TopImage
            style={{ borderRadius: 0 }}
            src={charactersImage}
            alt=""
            id="mint"
          />
          {/* <ContainerVideo
            style={{ borderRadius: 10 }}
            autoPlay
            muted
            loop
            playsInline
          >
            <source
              src="https://sinsofshadow.s3.amazonaws.com/videos/SINS_DEBUT_1.mp4"
              type="video/mp4"
            />
          </ContainerVideo> */}
        </MainContent>
        <Container>
          <Grid item md={12} xs={12} className={classes.calcArea}>
            {countDownData.countdown ? (
              expiredFlag ? (
                <CountDownTxt>EXPIRED</CountDownTxt>
              ) : (
                <></>
                /// unused part
                // <CountDownArea>
                //   <CountDownTxt>
                //     <span style={{ color: "#fc5eff" }}>SINS</span> Drop In
                //   </CountDownTxt>
                //   <Moment
                //     interval={1000}
                //     date={dateToFormat}
                //     format="dd:hh:mm:ss"
                //     durationFromNow
                //     onChange={eventFunction}
                //   />
                // </CountDownArea>
                ///
              )
            ) : (
              <CalArea>
                <CalContent>
                  <LogoTitle>NFTS REMAINING:</LogoTitle>
                  <LogoTitle1>{maxAmount}</LogoTitle1>
                </CalContent>
                <CalContent>
                  <LogoTitle>ETH BALANCE:</LogoTitle>
                  <LogoTitle1>{balance}</LogoTitle1>
                </CalContent>
                <CalContent>
                  <LogoTitle>MINT PRICE:</LogoTitle>
                  <LogoTitle1>{itemPrice}</LogoTitle1>
                </CalContent>
                <CalContent>
                  <LogoTitle>MINT AMOUNT:</LogoTitle>
                  <Calculator>
                    <LogoTitle4
                      onClick={() => {
                        onMintChangeButtonClick(-1);
                      }}
                    >
                      -
                    </LogoTitle4>
                    <AmountInput
                      type="number"
                      value={amount}
                      onChange={onMaxButtonClick}
                    />
                    <LogoTitle4
                      onClick={() => {
                        onMintChangeButtonClick(1);
                      }}
                    >
                      +
                    </LogoTitle4>
                  </Calculator>
                </CalContent>
                {!connected ? (
                  <BuyButton status={loadingNum} onClick={connect}>
                    Connect Wallet
                  </BuyButton>
                ) : !connectFlag ? (
                  <BuyButton status={loadingNum} onClick={add_chain_with_web3}>
                    Switch to Ethereum
                  </BuyButton>
                ) : (
                  <BuyButton
                    status={loadingNum}
                    disabled={loadingNum === 1}
                    onClick={onCallMint}
                  >
                    Mint
                  </BuyButton>
                )}
              </CalArea>
            )}
          </Grid>
        </Container>
        {loadingNum === 0 ? null : (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Description style={{ textAlign: "center" }}>
              {loadingNum === 1
                ? "Approving transaction..."
                : "Item minted successfully. Navigate to Rarity page to view your NFTs"}
            </Description>

            <ProcessingArea>
              {loadingNum === 1 ? <ProcessingLine /> : <ProcessingLine1 />}
              <ProcessingBar />
            </ProcessingArea>
          </div>
        )}
      </BannerContainer>
      {/* <AprrovingArea id="mint">
        <BottomButtonArea>
          <BottomButton
            onClick={() => {
              window.open(openSeaLink, "_blank");
            }}
          >
            <BottomButtonText>OPENSEA</BottomButtonText>
          </BottomButton>
          <BottomButton>
            <BottomButtonText onClick={() => navigateTo("rarity")}>
              RARITY
            </BottomButtonText>
          </BottomButton>
          <BottomButton>
            <BottomButtonText
              onClick={() => {
                window.open("https://rarible.com/sinsofshadow/items", "_blank");
              }}
            >
              RARIBLE
            </BottomButtonText>
          </BottomButton>
        </BottomButtonArea>
      </AprrovingArea> */}
    </Container1>
  );
};

export default BannerContent;
