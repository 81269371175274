import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RarityHeader from "../components/Home/RarityHeader";
import { colors } from "../styles";
import { useHistory } from "react-router-dom";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  amount: number;
  totalPrice: number;
  balance: number;
  ownNFT: Array<any>;
  add_chain_with_web3: () => void;
  connectFlag: boolean;
  isloading: boolean;
}

const MintContainer = styled.div`
  background-color: rgb(${colors.black});
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  align-items:center;
  padding-bottom: 100px;
  @media (max-width: 600px) {
    padding-top: 60px;
  }
`;

const LitepaperScreen = (props: Props) => {
  let history = useHistory();

  const networkWarning = () => {
    alert("Not quite ready yet");
  };

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <iframe
        style={{ border: "1px solid #777", width: "85vw", height: "85vh", marginTop:50 }}
        src="https://indd.adobe.com/embed/267aae8c-53e9-464f-9365-f922b7ad6631?startpage=1&allowFullscreen=true"
        allowFullScreen
      ></iframe>
    </MintContainer>
  );
};

export default LitepaperScreen;
