import { useEffect, useState } from "react";
import styled from "styled-components";
import RarityHeader from "../components/Home/RarityHeader";
import SmbrContainer from "../components/Home/SmbrContainer";
import { colors } from "../styles";
import polygonImage from "../assets/images/polygonImage.png";
import bnbImage from "../assets/images/bnbImage.png";
import buyButtonImage from "../assets/images/buyButtonImage.png";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import sinsImage from "src/assets/images/SINS_LOGO_WHite.png";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  amount: number;
  totalPrice: number;
  balance: number;
  ownNFT: Array<any>;
  add_chain_with_web3: () => void;
  connectFlag: boolean;
  isloading: boolean;
}

const MintContainer = styled.div`
  background-image: url(/images/smbrBackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
  @media (max-width: 600px) {
    padding-top: 60px;
  }
`;
const Description4 = styled.p`
  font-size: 18px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  margin-top: -50px;
  padding-bottom: 40px;
  width: 100%;
  letter-spacing: 4px;
  font-weight: 400;
  line-height: 25px;
  word-wrap: break-word;
  @media screen and (max-width: 560px) {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 15px;
  }
`;
const TokenArea = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
`;
const TokenLeftArea = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;
const Description7 = styled.p`
  font-size: 18px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 40px;
  width: 100%;
  font-weight: 700;
  @media screen and (max-width: 560px) {
    font-size: 13px;
  }
`;
const Description5 = styled.p`
  font-size: 24px;
  color: white;
  font-family: RobotoMono-Regular;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  @media screen and (max-width: 560px) {
    font-size: 15px;
  }
`;

const Description6 = styled.p`
  font-size: 16px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  width: 100%;
  font-weight: 300;
  @media screen and (max-width: 560px) {
    font-size: 12px;
  }
`;
const ButtonArea = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;
const ButtonItem = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 50px;
  @media screen and (max-width: 680px) {
    margin-bottom: 30px;
  }
`;
const MediumImage7 = styled.img`
  width: 118px;
  margin-bottom: 20px;
  @media screen and (max-width: 560px) {
  }
  &:hover {
    transform: scale(1.1);
  }
`;
const BottomButton = styled.div`
  width: 203px;
  height: 57px;
  flex-direction: column;
  display: flex;
  align-items: center;
  border-width: 2px;
  @media screen and (max-width: 970px) {
    justify-content: center;
    padding-bottom: 2px;
  }
  &:hover {
    cursor: pointer;
    // box-shadow: 0 4px 8px 0 rgba(${colors.main}),
    //   0 6px 10px 0 rgba(${colors.main});
  }
`;
const BottomButtonText = styled.div`
  width: 199px;
  height: 53px;
  margin-top: 2px;
  margin-left: 2px;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MediumImage8 = styled.img`
  width: 92px;
  margin-bottom: 46px;
  @media screen and (max-width: 560px) {
  }
  &:hover {
    transform: scale(1.1);
  }
`;
const SinsImage1 = styled.img`
  width: 185px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 600px) {
    width: 75px;
    display: flex;
    margin-top: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const MintScreen = (props: Props) => {
  const [nftData, setNftData] = useState<any[]>([]);
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    setNftData(props.ownNFT);
  }, [props.ownNFT]);

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SinsImage1
          onClick={() => {
            history.push(`/`);
          }}
          src={sinsImage}
          alt=""
        />
      </div>
      <SmbrContainer ownNFT={nftData} />
      <Container>
        <Description4>
          <span style={{ color: "#FF00F5" }}>SMBR</span> Token is the utility
          token that powers the Sins of Shadow NFT ecosystem and our NFT
          marketplace.
          <span style={{ color: "#FF00F5" }}>SMBR</span> is required for
          participation <span style={{ color: "#FF00F5" }}>Sins of Shadow</span>
          .
        </Description4>
        <Description4 style={{ margin: 0, padding: 0 }}>
          SMBR token is traded Polygon Network, Binance Smart Chain, and
          Ethereum.
        </Description4>
        <TokenArea>
          <TokenLeftArea>
            <Description7>HOW TO GET SMBR</Description7>
            <Description4>
              *SINS OF SHADOW PHASE 1 RUNS ON ETH*
              <br />
              <br />
              1. Download Metamask or Trustwallet (web 3 wallets)
              <br />
              2. Connect your web 3 wallet to Sombra Swap/Pancakeswap/Quickswap
              <br />
              3. Set slippage to 7% (this is found in the little gear icon and
              allows for the SMBR 6% tax)
              <br />
              4. Enter the amount of ETH you want to swap
              <br />
              5. Confirm Swap
              <br />
              6. Confirm Web 3 transaction
              <br />
              7. SMBR is now in your wallet
              <br />
              <br />
              Please note you must have SMBR added to you web 3 wallet using the
              contract address(es): <br />
              <br />
              <span style={{ color: "#3300FF" }}>ETH</span> -
              0x16B3E050e9e2f0Ac4f1BEA1b3E4fdc43d7f062Dd (Uniswap V2)
              <br />
              <span style={{ color: "#3300FF" }}>BSC</span> -
              0x16B3E050e9e2f0Ac4f1BEA1b3E4fdc43d7f062Dd (Pancakeswap)
              <br />
              <br />
              Decimal - 9
            </Description4>
            <Description6>
              Sombra Network does not control the Decentralized Exchanges
              that allows peer to peer trading of SMBR. We do not manipulate or
              alter the price of the token or condone anyone trying to do so.
              Financial gain cannot be promised and is not recommended you
              purchase SMBR with the intent of profiting financially in a 2ndary
              market. SMBR was created strictly as a support mechanism and
              community utility tool to advance development of our Metaverse
              Games and allow a DAO to govern and manage creative choices for
              the project. As a holder of SMBR you are not an investor and we
              cannot and will not speculate on potential profits or gains.
              Please purchase SMBR at your own risk and understand how to use
              web 3 applications prior to making any purchases. Please always
              check URLs and never respond to emails asking for web 3
              connection. Also NEVER give out passwords or seed phrases.
            </Description6>
          </TokenLeftArea>
        </TokenArea>
        <ButtonArea>
          <ButtonItem>
            <MediumImage8 style={{ width: 60 }} src="/etheImage.png" alt="" />
            <BottomButton
              onClick={() => {
                window.open("https://eth.sombraswap.io/#/", "_blank");
              }}
            >
              <img width={"100%"} src={buyButtonImage} alt="" />
            </BottomButton>
          </ButtonItem>
          <ButtonItem>
            <MediumImage8
              style={{ width: 70, marginBottom: 65 }}
              src="/bnbImage.png"
              alt=""
            />
            <BottomButton
              onClick={() => {
                window.open("https://bsc.sombraswap.io/#/", "_blank");
              }}
            >
              <img width={"100%"} src={buyButtonImage} alt="" />
            </BottomButton>
          </ButtonItem>
        </ButtonArea>
      </Container>
    </MintContainer>
  );
};

export default MintScreen;
