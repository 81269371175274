import { GenesisData } from "../genesis_metadata";

export const getValue = (key, filterArr, item) => {
  const initialVal = {
    BACKGROUND: [],
    RARIBLES: [],
    BASE: [],
    EYES: [],
    MOUTH_NECK: [],
    ACCESORIES: [],
    HAIR: [],
    CLOTHES: [],
    HELMET: [],
    WEAPONS: [],
  };
  const getKey = (part, obj) =>
    Object.entries(obj).find(([k, v]) => k.startsWith(part))?.[0];
  const getValue = (part, obj) =>
    Object.entries(obj).find(([k, v]) => k.startsWith(part))?.[1];
  let objKey = getKey(key, filterArr);
  let objValue = getValue(key, filterArr);
  let tempFilterArr = { ...filterArr };
  if (objValue.indexOf(item) > -1) {
    const newArr = [...objValue];
    newArr.splice(newArr.indexOf(item), 1);
    tempFilterArr[objKey] = newArr;
  } else {
    const newArr = [...objValue, item];
    tempFilterArr[objKey] = newArr;
  }
  let tempFilteredArr = [];
  console.log("tempFilterArr", tempFilterArr);
  if (JSON.stringify(tempFilterArr) == JSON.stringify(initialVal)) {
    tempFilteredArr = GenesisData;
  } else {
    tempFilteredArr = GenesisData;
    for (let l = 0; l < Object.keys(tempFilterArr).length; l++) {
      const key = Object.keys(tempFilterArr)[l];
      if (tempFilterArr[key].length !== 0) {
        let temp = [];
        for (let i = 0; i < tempFilterArr[key].length; i++) {
          for (let j = 0; j < tempFilteredArr.length; j++) {
            for (let k = 0; k < tempFilteredArr[j].attributes.length; k++) {
              if (tempFilteredArr[j].attributes[k].trait_type === key) {
                if (
                  tempFilterArr[key][i] ===
                  tempFilteredArr[j].attributes[k].value
                ) {
                  temp.push(tempFilteredArr[j]);
                }
              }
            }
          }
        }
        tempFilteredArr = temp;
      }
    }
  }
  return [tempFilterArr, tempFilteredArr];
};
export const checkFlagFun = (key, filterArr) => {
  const getValue = (part, obj) =>
    Object.entries(obj).find(([k, v]) => k.startsWith(part))?.[1];
  let objValue = getValue(key, filterArr);
  return objValue;
};

export const getUniqueListBy = (arr) => {
  let keys = ["tokenId"];
  const filtered = arr.filter(
    (
      (s) => (o) =>
        ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
    )(new Set())
  );
  console.log("result", filtered);
  return filtered;
};
