import styled from "styled-components";

import welcomBackImg from "../../assets/images/welcomeBackImg.jpg";
import welcomeMobileBack from "../../assets/images/welcomeMobileBack.jpg";

const Content = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  margin-bottom: -5px;
  .desktop {
    display: flex;
  }
  .mobile {
    display: none;
  }
  @media (max-width: 600px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
  }
`;

export default function WelcomContent() {
  return (
    <Content>
      <img
        className="desktop"
        src={welcomBackImg}
        alt="prepareBattleBack"
        width="100%"
      />
      <img
        className="mobile"
        src={welcomeMobileBack}
        alt="welcomeMobileBack"
        width="100%"
      />
    </Content>
  );
}
