import { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import RarityHeader from "../components/Home/RarityHeader";
import { colors } from "../styles";
import womenAvatar from "../assets/images/womenAvatar.png";
import ruleImage from "../assets/images/ruleImage.png";
import avatarsImage from "../assets/images/avatarsImage.png";
import avatarImage1 from "../assets/images/avatarImage1.png";
import avatarImage2 from "../assets/images/avatarImage2.png";
import avatarImage3 from "../assets/images/avatarImage3.png";
import wineImage from "../assets/images/wineImage.png";
import storyTitle from "../assets/images/storyTitle.png";
import Container from "@material-ui/core/Container";
import sinsImage from "src/assets/images/SINS_LOGO_WHite.png";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  amount: number;
  totalPrice: number;
  balance: number;
  ownNFT: Array<any>;
  add_chain_with_web3: () => void;
  connectFlag: boolean;
  isloading: boolean;
}

const MintContainer = styled.div`
  background-image: url(/images/storyBackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
  @media (max-width: 600px) {
    padding-top: 60px;
  }
`;
const Description4 = styled.p`
  font-size: 20px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  width: 100%;
  letter-spacing: 0.1em;
  max-width: 900px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 560px) {
    width: 100%;
    margin-left: 0%;
    font-size: 13px;
  }
`;
const TitleImage = styled.img`
  margin-top: 80px;
  margin-bottom: 50px;
  max-width: 500px;
  width: 80%;
  display: flex;
  align-self: center;
  @media screen and (max-width: 560px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &:hover {
  }
`;
const MediumImage = styled.img`
  margin-bottom: 30px;
  max-width: 900px;
  width: 100%;
  display: flex;
  align-self: center;
  @media screen and (max-width: 560px) {
  }
  &:hover {
  }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const SinsImage1 = styled.img`
  width: 185px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 600px) {
    width: 75px;
    display: flex;
    margin-top: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const StoryScreen = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.ownNFT]);
  let history = useHistory();

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SinsImage1
          onClick={() => {
            history.push(`/`);
          }}
          src={sinsImage}
          alt=""
        />
      </div>
      <Container>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <TitleImage src={storyTitle} alt="" />
          <MediumImage src={avatarsImage} alt="" />
          <Description4>
            It’s year 7777 or so we’re told. The Earth is dead and we are living
            under the rule of Sombra. No one knows how long it’s been.
            <br />
            <br />
            After years of global warming, resource depletion and war, the earth
            became questionably inhabitable. As interplanetary travel became
            possible, the population of earth began leaving and setting up
            communities throughout the galaxy.
            <br />
            <br />
            The majority of humanity relocated to a series of planets along the
            Circinus Galaxy and settled into new existences of building and
            growing. While most chose peaceful paths, others did not. With
            technological advancements came dangerous accessibility to weapons.
            At first it was only a few bad actors here and there but soon whole
            planets were being taken over my gangs of marauding raiders. The
            Council of Planets formed a task force called The Alphas to combat
            the surge in ghost weapons, energy based explosive technology and
            under group separatist groups. Things seemed to be getting better,
            but then the Hydrogen Mines were discovered.
          </Description4>
          <MediumImage src={avatarImage1} alt="" />
          <Description4>
            A rich supplement in the development of ghost weapons, Hydrogen
            became a coveted element and highly sought after in pure form. While
            many farms and labs existed the supply was never enough and the
            demand for the combustible substance skyrocketed. When the mines
            were discovered the planets rushed in to claim their stake and as
            history has shown us, sharing is never easy. The Hydrogen Wars
            erupted into the cosmos wreaking havoc and destruction across space
            and time. Legends of the Hydrogen Wars and the final battle, The
            Entropy of Combustion are all that remain of the lost ages.
          </Description4>
          <MediumImage src={ruleImage} alt="" />
          <Description4>
            Sombra Network, a multi trillion dollar global organization formed
            in the wake of the Wars was in charge of the rehoming the displaced
            populations following the mass destruction of planets and untold
            loss of life. Using new elements and blockchain technology , they
            created a series of new mechanically made planets to support life,
            commerce and the preservation of humanity. At the center of these
            planets is Sins of Shadow, a massive open world of different
            environments and communities.
            <br />
            <br />
            The planet ranges from lush costal lifestyles and neighborhoods to
            dusty barren post apocalyptic lands and everything in between.
            Flying vehicles are the norm and everyone is armed. Even your
            grandma. Only specific areas of the planet are safe for travel
            whereas others…well let’s just say don’t go there alone.
          </Description4>
          <MediumImage src={womenAvatar} alt="" />
          <Description4>
            At the center of the planet sits The Arena Del Toros. This massive
            structure hosts epic battles between teams of players called Guilds.
            These guilds compete against each other for fame and fortune while
            staking their cold hard cash for a winner takes all team deathmatch.
            The bigger the battle, the bigger the stakes. If you don’t like to
            get your hands dirty, no worries, The Arena hosts spectators as well
            inside the Arena Casino where you can try your luck betting on the
            battles.
          </Description4>
          <MediumImage src={avatarImage2} alt="" />
          <Description4>
            Under the Arena sits a large planetary jail which hosts some of the
            galaxy’s most notorious criminals. It’s rumored that ancients, the
            Hydrogen Wars’ most brutal and dangerous criminals are still
            imprisoned there to this day.
          </Description4>
          <MediumImage src={avatarImage3} alt="" />
          <Description4>
            The Sins of Shadow world economy is one based on good faith. If you
            do good deeds you earn a positive rating. If you do bad deeds you
            earn a negative rating. These ratings affect your eligibility for
            upgrades and rewards in the greater ecosystem and if you can play
            inside the Arena as a guild. The company you keep is important.
          </Description4>
          <MediumImage src={wineImage} alt="" />
        </div>
      </Container>
    </MintContainer>
  );
};

export default StoryScreen;
