import React, { useEffect } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/images/logo.png";

interface Props {
  openMenu: () => void;
}

const ToggleIcon = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0px;
  top: 25px;
  display: block;
  @media (max-width: 960px) {
    display: block;
    top: 17px;
    width: 30px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const LoagImage = styled.img`
  width: 30px;
  @media (max-width: 600px) {
    width: 25px;
  }
`;

const ToggleMenuIcon: React.FC<Props> = ({ openMenu }) => {
  useEffect(() => {
    openMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ToggleIcon>
      {/* <IconButton aria-label="delete" size="medium" color="primary" onClick={openMenu}>
                <MenuIcon fontSize="inherit" />
            </IconButton> */}
      <LoagImage src={logo} alt="" onClick={openMenu} />
    </ToggleIcon>
  );
};

export default ToggleMenuIcon;
