import { useEffect, useState } from "react";
import styled from "styled-components";
import RarityHeader from "../components/Home/RarityHeader";
import { colors } from "../styles";
import { useHistory } from "react-router-dom";
import bodegaImage from "../assets/images/bodegaImage.png";
import bodegaImage1 from "../assets/images/bodegaImage1.png";
import bodegaImage2 from "../assets/images/bodegaImage2.png";
import bodegaImage3 from "../assets/images/bodegaImage3.png";
import sinsImage from "src/assets/images/SINS_LOGO_WHite.png";
import Container from "@material-ui/core/Container";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
}

const MintContainer = styled.div`
  background-image: url(/images/bodegaBackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
  @media (max-width: 600px) {
    padding-top: 60px;
  }
`;
const Description4 = styled.p`
  font-size: 20px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  width: 100%;
  letter-spacing: 0.1em;
  @media screen and (max-width: 560px) {
    width: 100%;
    margin-left: 0%;
    font-size: 13px;
  }
`;
const LeftSideTitle = styled.p`
  font-size: 40px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  @media screen and (max-width: 1300px) {
    font-size: 30px;
  }
  @media screen and (max-width: 900px) {
    width: 100%;
    margin-left: 0%;
    font-size: 24px;
    text-align: center;
  }
`;
const MediumImage = styled.img`
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 15px;
  @media screen and (max-width: 560px) {
  }
  &:hover {
  }
`;
const MediumImage1 = styled.img`
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  display: none;
  @media screen and (max-width: 900px) {
    display: flex;
  }
  &:hover {
  }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;
const LeftArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;
const RightArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  @media screen and (max-width: 900px) {
    display: none;
    width: 100%;
  }
`;
const SinsImage1 = styled.img`
  width: 185px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 600px) {
    width: 75px;
    display: flex;
    margin-top: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const BodegaScreen = (props: Props) => {
  let history = useHistory();

  const networkWarning = () => {
    alert("Not quite ready yet");
  };

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SinsImage1
          onClick={() => {
            history.push(`/`);
          }}
          src={sinsImage}
          alt=""
        />
      </div>
      <TopTitle>BODEGA</TopTitle>
      <Container>
        <MediumImage src={bodegaImage} alt="" />
        <MainContainer>
          <LeftArea>
            <LeftSideTitle>A Creator Economy</LeftSideTitle>
            <MediumImage1 src={bodegaImage2} alt="" />
            <Description4>
              The Bodega is an NFT digital store front specifically for Sins of
              Shadow. Users can upload their own 3D creations and the Sins of
              Shadow team will turn the asset into a game ready NFT that the
              artist makes money on every time it sells or is traded. If the NFT
              trends you also get rewarded! This incetivizes unique builds and
              creativity.
              <br />
              <br />
              Creators can sell any item they wish and we will support you with
              integration. <br />
              <br />
              <MediumImage1 src={bodegaImage1} alt="" />
              Sombra also creates and supplies many different types of NFTs and
              digital assets for the Sins of Shadow game and experience. Collect
              all the amazing goods inside our ecosystem and get rewards for
              staking them!
            </Description4>
          </LeftArea>
          <RightArea>
            <MediumImage style={{ width: "90%" }} src={bodegaImage2} alt="" />
            <MediumImage style={{ width: "90%" }} src={bodegaImage1} alt="" />
          </RightArea>
        </MainContainer>
        <MediumImage src={bodegaImage3} alt="" />
      </Container>
    </MintContainer>
  );
};

export default BodegaScreen;
