import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TvShowHeader from "../components/Home/TvShowHeader";
import { colors } from "../styles";
import { useHistory } from "react-router-dom";
import gameTopImage from "../assets/images/gameTopImage.png";
import tvshowImage from "../assets/images/tvshowImage.png";
import tvshowImage1 from "../assets/images/tvshowImage1.png";
import sinsImage from "../assets/images/SINS_LOGO_WHite.png";
import tvshowImage3 from "../assets/images/tvshowImage3.png";
import tvshowImage4 from "../assets/images/tvshowImage4.png";
import tvshowImage5 from "../assets/images/tvshowImage5.png";
import tvshowImage6 from "../assets/images/tvshowImage6.png";
import tvShowTitle from "../assets/images/tvShowTitle.png";
import Container from "@material-ui/core/Container";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
}

const MintContainer = styled.div`
  background-image: url(/images/tvShowBackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  align-items: center;
  padding-bottom: 25px;
  @media (max-width: 600px) {
    padding-top: 50px;
  }
`;

const LoadingImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 150px;
`;
const ConnectButton = styled.button`
  border: none;
  background: white;
  color: black;
  font-size: 30px;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: none;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 50px;
  height: 50px;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(${colors.border});
  }
`;
const Description1 = styled.p`
  font-size: 18px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 30px;
  width: 90%;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  @media screen and (max-width: 560px) {
    width: 100%;
    margin-left: 0%;
    font-size: 13px;
  }
`;
const Description2 = styled.p`
  font-size: 18px;
  color: white;
  font-family: MonumentExtended-Regular;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 30px;
  width: 90%;
  text-transform: uppercase;
  margin-top: -100px;
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-left: 0%;
    font-size: 13px;
    margin-top: -50px;
  }
`;
const TokenArea = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
`;
const TokenLeftArea = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;
const Description7 = styled.p`
  font-size: 36px;
  color: white;
  font-family: RobotoMono-Bold;
  margin: 0;
  padding-top: 40px;
  width: 100%;
  font-weight: 700;
  @media screen and (max-width: 560px) {
    font-size: 24px;
  }
`;
const Description5 = styled.p`
  font-size: 24px;
  color: white;
  font-family: RobotoMono-Regular;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  @media screen and (max-width: 560px) {
    font-size: 15px;
  }
`;
const MediumImage4 = styled.img`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 560px) {
    display: block;
    width: 70%;
    width: 70%;
    margin: 0px auto;
  }
  &:hover {
    transform: scale(1.1);
    box-shadow: 4px 4px 6px 0 #ff56f6;
  }
`;
const Description6 = styled.p`
  font-size: 18px;
  color: white;
  font-family: RobotoMono-BoldItalic;
  margin: 0;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  @media screen and (max-width: 560px) {
    font-size: 16px;
  }
`;
const TokenImageArea = styled.div`
  width: 20%;
  flex-direction: column;
  display: flex;
  align-items: center;
  @media screen and (max-width: 560px) {
    width: 340px;
    height: 100%;
    margin: 20px auto;
    display: none;
  }
`;
const MediumImage2 = styled.img`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    width: 95%;
    width: 95%;
    margin: 50px auto;
    margin-bottom: 0px;
  }
  &:hover {
    transform: scale(1.1);
  }
`;
const ButtonArea = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
`;
const ButtonItem = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 50px;
  @media screen and (max-width: 680px) {
    margin-bottom: 30px;
  }
`;
const MediumImage7 = styled.img`
  width: 118px;
  margin-bottom: 20px;
  @media screen and (max-width: 560px) {
  }
  &:hover {
    transform: scale(1.1);
  }
`;
const BottomButton = styled.div`
  width: 203px;
  height: 57px;
  flex-direction: column;
  display: flex;
  align-items: center;
  background: linear-gradient(
    120deg,
    rgba(60, 121, 212, 1),
    rgba(255, 166, 250, 0.47)
  );
  border-width: 2px;
  @media screen and (max-width: 970px) {
    justify-content: center;
    padding-bottom: 2px;
  }
  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(${colors.main}),
      0 6px 10px 0 rgba(${colors.main});
  }
`;
const BottomButtonText = styled.div`
  width: 199px;
  height: 53px;
  margin-top: 2px;
  margin-left: 2px;
  background: linear-gradient(
    120deg,
    rgba(217, 0, 249, 1),
    rgba(66, 0, 138, 0.47)
  );
  color: white;
  font-size: 18px;
  font-family: MonumentExtended-Regular;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MediumImage = styled.img`
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 10px;
  @media screen and (max-width: 560px) {
  }
  &:hover {
  }
`;
const MediumImage1 = styled.img`
  width: 100%;
  margin-bottom: 30px;
  margin-top: -80px;
  text-align: center;
  border-radius: 10px;
  @media screen and (max-width: 600px) {
    margin-top: -20px;
  }
  &:hover {
  }
`;
const TopTitle = styled.p`
  font-family: MonumentExtended-Regular;
  font-size: 50px;
  color: white;
  margin: 30px auto;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  @media screen and (max-width: 600px) {
    font-size: 24px;
    margin: 10px auto;
  }
`;
const HorizontalLineArea = styled.div`
  width: 100%;
  height: 3px;
  background-color: #ff56f6;
  margin-top: 25px;
  margin-bottom: 25px;
  @media screen and (max-width: 560px) {
    margin-top: 0px !important;
  }
`;
const SinsImage1 = styled.img`
  width: 185px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 600px) {
    width: 75px;
    display: flex;
    margin-top: 10px;
  }
`;
const ContainerVideo = styled.video`
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
  display: flex;
  margin-bottom: 30px;
`;
const TitleImage = styled.img`
  margin-top: 80px;
  margin-bottom: 50px;
  max-width: 700px;
  width: 80%;
  display: flex;
  align-self: center;
  @media screen and (max-width: 560px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1100px;
  width: 95%;
`;

const TvshowScreen = (props: Props) => {
  let history = useHistory();
  return (
    <MintContainer>
      <TvShowHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SinsImage1
          onClick={() => {
            history.push(`/`);
          }}
          src={sinsImage}
          alt=""
        />
      </div>
      <TitleImage src={tvShowTitle} alt="" />
      <ContentContainer>
        <ContainerVideo autoPlay muted loop playsInline>
          <source
            src="https://sinsofshadow.s3.amazonaws.com/videos/GenesisFootage.mp4"
            type="video/mp4"
          />
        </ContainerVideo>
        <Description1>
          Sins produces a monthly TV show created inside Unreal Engine with
          motion capture technology. Each month the show is written in 5 days
          after a community governance vote for the plot. As players mint their
          PFPs and 3D twins, they become characters inside the cinematic
          universe and holders become eligable for rewards based on the player
          actions inside the game and show.
        </Description1>
        <MediumImage src={tvshowImage} alt="" />
        <MediumImage style={{ marginBottom: 0 }} src={tvshowImage1} alt="" />
        <HorizontalLineArea />
      </ContentContainer>
      <TopTitle id="characters">Characters</TopTitle>
      <ContentContainer>
        <MediumImage src={gameTopImage} alt="" />
        <Description1>
          Each NFT becomes a character inside the cinematic universe. Not every
          single NFT will be a super star but depending on luck, you could have
          one of the rarest characters in existance. As the ecosytem progresses
          we will add more episodes with more characters.
          <br />
          <br />
          If your character is in the TV show, your choices in the P2E game
          influence how the characters are written. If you have an evil Shadow
          rating your character will be inherintly evil and the opposite for a
          good Shadow rating.
          <br />
          <br />
          Shadow Rating = How naughty you are. Choose wisely.
        </Description1>
        <HorizontalLineArea />
      </ContentContainer>
      <TopTitle id="plot">Plot Governance</TopTitle>
      <ContentContainer>
        <MediumImage1 src={tvshowImage3} alt="" />
        <Description2>
          NFT holders get to vote each month at a large community party, either
          in person or digitally. We will rotate the voting party from city to
          city across the world!
          <br />
          <br />
          Everyone is welcome to vote and influence the story but avatar NFT
          owners have a large influence on their characters and can control them
          unless the community votes strongly against it.
        </Description2>
        <HorizontalLineArea />
      </ContentContainer>
      <TopTitle id="schedule">Schedule</TopTitle>
      <ContentContainer>
        <MediumImage src={tvshowImage4} alt="" />
        <Description1>
          Each episode is written over 5 days following the governance vote. The
          script is then put into production over a 1 month period. Using a
          combination of Unreal Engine, motion capture technology and other
          emeriging tech workflows we are able to produce top level cinematic
          content in record time. We are expert storytellers and VFX artists
          commited to delivering the best quality possible.
          <br />
        </Description1>
        <HorizontalLineArea />
      </ContentContainer>
      <TopTitle id="rewards">Rewards</TopTitle>
      <ContentContainer>
        <MediumImage1 src={tvshowImage5} alt="" />
        <Description2>
          Rewards are given to people who participate in the show’s governance
          and if you own NFTs that appear in the season. Voting each month
          provides a reward in $SMBR as well as NFTs.
          <br />
          <br />
          If you are a character owner you get paid for every episode they are
          in as well as rewarded if your character wins the monthly popularity
          contest among the community.
          <br />
        </Description2>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <MediumImage1
            style={{ maxWidth: 375, width: "70%", marginBottom: 0 }}
            src={tvshowImage6}
            alt=""
          />
        </div>
      </ContentContainer>
    </MintContainer>
  );
};

export default TvshowScreen;
