import * as React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
// @ts-ignore
import Web3 from "web3";
import Web3Modal from "web3modal";
// @ts-ignore
import WalletConnectProvider from "@walletconnect/web3-provider";
// @ts-ignore
import Fortmatic from "fortmatic";
import WalletLink from "walletlink";
import Torus from "@toruslabs/torus-embed";
import { ethers } from "ethers";
import Portis from "@portis/web3";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

//custom module
import { apiGetAccountAssets } from "./helpers/api";
import { getChainData } from "./helpers/utilities";
import { IAssetData, NftData } from "./helpers/types";
import {
  callMint,
  getItemPrice,
  getMaxMintAmount,
  fetchNFT,
  fetchSMBRTokenBalance,
  callStake,
  approve,
  fetchStakedTokenBalance,
  callWithDraw,
  callHarvest,
  nftApprove,
  callNFTLock,
  callNFTUnlock,
  isApprovedForAll,
  isCheckStakable,
  callBoostCStaking,
  fetchBonuses,
  fetchEarnedTokenBalance,
} from "./helpers/web3";

import { MINT_CONTRACT, LOCK_CONTRACT } from "./constants";
import { getUniqueListBy } from "./helpers/utility";

import ScreenWrapper from "./components/Home/ScreenWrapper";
import RarityScreen from "./pages/RarityScreen";
import SmbrScreen from "./pages/SmbrScreen";
import StoryScreen from "./pages/StoryScreen";
import BodegaScreen from "./pages/BodegaScreen";
import StakingScreen from "./pages/StakingScreen";
import StakingScreenTemp from "./pages/StakingScreenTemp";
import MintScreen from "./pages/MintScreen";
import TermsScreem from "./pages/TermsScreem";
import PrivacyScreen from "./pages/PrivacyScreen";
import GameScreen from "./pages/GameScreen";
import LawScreen from "./pages/LawScreen";
import TvshowScreen from "./pages/TvshowScreen";
import NFTStakingScreen from "./pages/NFTStakingScreen";
import LitepaperScreen from "./pages/LitepaperScreen";
import BrowseScreen from "./pages/BrowseScreen";
import LootScreen from "./pages/LootScreen";
import coinBase from "../src/assets/images/coinbase.png";

import {
  rinkebyChainId,
  mainChainId,
  rinkebyAddress,
  mainAddress,
  rinkebyNetworkInfo,
  mainNetworkInfo,
  rarityLink,
  rairityImageLink,
  num_revealed,
  rairityJsonLink,
  rairityPreJsonLink,
  testNet,
} from "./sombra_config";
import Footer from "./components/Home/Footer";

interface IAppState {
  fetching: boolean;
  address: string;
  web3: any;
  library: any;
  provider: any;
  connected: boolean;
  chainId: number;
  networkId: number;
  assets: IAssetData[];
  showModal: boolean;
  pendingRequest: boolean;
  result: any | null;
  balance: number;
  totalPrice: number;
  amount: number;
  stakeAmount: number;
  stakeRelease: number;
  itemPrice: number;
  maxAmount: number;
  // my state
  isHide: boolean;
  ownNFT: NftData[];
  lockedNFT: NftData[];
  connectFlag: boolean;
  isloading: boolean;
  lockLoading: number;
  isUnlockloading: boolean;
  mintLoading: number;
  stakeLoading: number;
  withdrawLoading: number;
  harvestLoading: number;
  maticPrice: number;
  sombraPrice: number;
  smbrTokenBalance: number;
  stakedTokenBalance: number;
  earnedTokenBalance: number;
  loadingStatus: number;
  networkChainId: number;
  networkInfo: any;
  contractAddress: any;
  bonuses: any;
  stakeFlag: string;
}

const INITIAL_STATE: IAppState = {
  fetching: false,
  address: "",
  web3: null,
  library: null,
  provider: null,
  connected: false,
  chainId: 3,
  networkId: 1,
  assets: [],
  showModal: false,
  pendingRequest: false,
  result: null,
  isHide: true,
  totalPrice: 0,
  amount: 1,
  stakeAmount: 1,
  stakeRelease: 1,
  balance: 0,
  itemPrice: 0,
  maxAmount: 0,
  ownNFT: [],
  lockedNFT: [],
  connectFlag: false,
  isloading: true,
  lockLoading: 0,
  isUnlockloading: true,
  mintLoading: 0,
  stakeLoading: 0,
  withdrawLoading: 0,
  harvestLoading: 0,
  maticPrice: 0,
  sombraPrice: 0,
  smbrTokenBalance: 0,
  stakedTokenBalance: 0,
  earnedTokenBalance: 0,
  loadingStatus: 0,
  networkChainId: 1,
  networkInfo: {},
  contractAddress: {},
  bonuses: [],
  stakeFlag: "",
};

function initWeb3(provider: any) {
  const web3: any = new Web3(provider);

  web3.eth.extend({
    methods: [
      {
        name: "chainId",
        call: "eth_chainId",
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });
  return web3;
}

const history = createBrowserHistory();

class App extends React.Component<any, any> {
  // @ts-ignore
  public web3Modal: Web3Modal;
  public state: IAppState;

  constructor(props: any) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };

    this.web3Modal = new Web3Modal({
      network: this.getNetwork(),
      cacheProvider: true,
      providerOptions: this.getProviderOptions(),
      theme: {
        background: "rgba(43, 51, 94, 0.9)",
        main: "rgb(250, 250, 250)",
        secondary: "rgba(250, 250, 250, 0.7)",
        border: "rgba(196, 196, 196, 0.3)",
        hover: "rgba(53, 61, 104, 0.75)",
      },
    });
  }

  public componentDidMount = async () => {
    if (testNet) {
      await this.setState({
        networkChainId: rinkebyChainId,
        networkInfo: rinkebyNetworkInfo,
        contractAddress: rinkebyAddress,
      });
    } else {
      await this.setState({
        networkChainId: mainChainId,
        networkInfo: mainNetworkInfo,
        contractAddress: mainAddress,
      });
    }
    fetch("https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd")
      .then((response) => response.json())
      .then((data) => {
        let ethereumUsd = data["ethereum"].usd;
        this.setState({ maticPrice: parseFloat(ethereumUsd).toFixed(2) });
      });
    fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=sombra-network&vs_currencies=usd"
    )
      .then((response) => response.json())
      .then((data) => {
        let sombraUsd = data["sombra-network"].usd;
        this.setState({ sombraPrice: parseFloat(sombraUsd).toFixed(2) });
      });
    AOS.init();
    if (this.web3Modal.cachedProvider) {
      this.onConnect();
    } else {
    }
    this.setState({ isHide: true });
  };

  public convert_to_wei(val: any) {
    return parseFloat(val) * 1e18;
  }
  public convert_to_wei1(val: any) {
    return parseFloat(val) * 1e9;
  }
  public convert_from_wei(val: any) {
    return parseFloat(val) / 1e18;
  }
  public convert_from_wei1(val: any) {
    return parseFloat(val) / 1e9;
  }

  public onConnect = async () => {
    this.setState({ isHide: true });
    const provider = await this.web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);

    await this.subscribeProvider(provider);

    const web3: any = initWeb3(provider);

    const accounts = await web3.eth.getAccounts();

    const address = accounts[0];

    const walletBallance = await web3.eth.getBalance(address);
    const d: number = web3.utils.fromWei(walletBallance.toString(), "ether");

    const networkId = await web3.eth.net.getId();

    const chainId = await web3.eth.chainId();
    await this.setState({
      web3,
      provider,
      connected: true,
      address,
      chainId,
      networkId,
      library,
    });
    this.getAccountAssets();

    if (chainId === this.state.networkChainId) {
      this.fetchLockedNFTData(web3, address);
      this.setState({ connectFlag: true });
      this.setState({ balance: parseFloat(d.toString()).toFixed(6) });
      let itemValue = await getItemPrice(
        this.state.contractAddress.MINT_CONTRACT,
        web3
      );
      let etherValue = Web3.utils.fromWei(itemValue.toString(), "ether");
      let mintedArray = await getMaxMintAmount(
        this.state.contractAddress.MINT_CONTRACT,
        web3
      );
      let maxAmount = mintedArray[1] - mintedArray[0];
      let SMBRTokenBalance = await fetchSMBRTokenBalance(
        web3,
        address,
        this.state.contractAddress.TOKEN_CONTRACT
      );
      let stakedTokenBalance = await fetchStakedTokenBalance(
        web3,
        address,
        this.state.contractAddress.STAKING_CONTRACT
      );
      let earnTokenBalance = await fetchEarnedTokenBalance(
        web3,
        address,
        this.state.contractAddress.STAKING_CONTRACT
      );
      let bonusesBalance = await fetchBonuses(
        web3,
        address,
        this.state.contractAddress.STAKING_CONTRACT
      );
      this.setState({
        itemPrice: parseFloat(etherValue).toFixed(6),
        maxAmount: maxAmount,
        totalPrice: parseFloat(etherValue).toFixed(6),
        smbrTokenBalance: this.convert_from_wei1(SMBRTokenBalance),
        stakedTokenBalance: this.convert_from_wei1(stakedTokenBalance),
        // smbrTokenBalance: web3.utils.fromWei(
        //   SMBRTokenBalance.toString(),
        //   "ether"
        // ),
        // stakedTokenBalance: web3.utils.fromWei(
        //   stakedTokenBalance.toString(),
        //   "ether"
        // ),
        // earnedTokenBalance: web3.utils.fromWei(
        //   earnTokenBalance.toString(),
        //   "ether"
        // ),
        earnedTokenBalance: this.convert_from_wei1(earnTokenBalance),
        bonuses: bonusesBalance,
      });

      // now fetch all nfts
      this.fetchNFT(web3, address);
      // fetch all locked nfts
    } else {
      // window.alert("Choose Rinkeby Test Network in your Wallet.");
      this.setState({ connectFlag: false });
    }
  };

  public fetchNFT = async (web3: any, address: any) => {
    let j = 0;
    let keep_going = true;
    let current_nfts: any = [];
    // let tempObj: any = {};
    while (keep_going) {
      let tempObj: any = {};
      try {
        let nft_call: any = await fetchNFT(
          this.state.contractAddress.MINT_CONTRACT,
          web3,
          j,
          address
        );
        if (!nft_call) {
          break;
        }
        tempObj.tokenId = nft_call[2];
        tempObj.metaDataLink = nft_call[0];
        if (tempObj.tokenId < num_revealed) {
          let link = rairityJsonLink + tempObj.tokenId;
          let rarityLik = rarityLink + tempObj.tokenId + ".json";
          let rarityData: any;
          await fetch(rarityLik)
            .then((res) => res.json())
            .then((json) => {
              rarityData = json.rarity;
            });
          fetch(link)
            .then((res) => res.json())
            .then((json) => {
              tempObj.name = json.name;
              tempObj.description = json.description;
              tempObj.attributes = json.attributes;
              tempObj.rarityData = rarityData;
              tempObj.image = rairityImageLink + tempObj.tokenId + ".png";
            });
        } else {
          let link = rairityPreJsonLink + tempObj.tokenId;
          fetch(link)
            .then((res) => res.json())
            .then((json) => {
              tempObj.name = json.name;
              tempObj.description = json.description;
              tempObj.attributes = json.attributes;
              tempObj.rarityData = "";
              // tempObj.image = json.image.replace('ipfs://', 'https://sins.mypinata.cloud/ipfs/')
              tempObj.image = rairityImageLink + tempObj.tokenId + ".png";
            });
        }

        current_nfts.unshift(tempObj); // changed this to after break to avoid double of the previsous
      } catch (err) {
        console.log(err);
        keep_going = false;
      }
      j++;
    }
    this.setState({ isloading: false });
    await this.setState({
      ownNFT: current_nfts,
      loadingStatus: this.state.loadingStatus + 1,
    });
  };

  public fetchLockedNFTData = async (web3: any, address: any) => {
    const { contractAddress } = this.state;
    let contract_token = new web3.eth.Contract(
      MINT_CONTRACT.abi,
      contractAddress.MINT_CONTRACT
    );
    let contract_lock = new web3.eth.Contract(
      LOCK_CONTRACT.abi,
      contractAddress.LOCK_CONTRACT
    );
    let current_nfts: any = [];
    let uniqueArr: any = [];
    await web3.eth
      .getPastLogs({
        address: contractAddress.LOCK_CONTRACT,
        fromBlock: "0x1",
        toBlock: "latest",
        topics: [web3.utils.sha3("NftLocked(address,uint256)")],
      })
      .then(async (res: any) => {
        for (let i = 0; i < res.length; i++) {
          const element = res[i];
          let tempObj: any = {};
          if (Number(element.topics[1]) == Number(address)) {
            /// confirm the nft is locked
            let lockFlag = await contract_lock.methods
              .address_has_locked(address, Number(element.data))
              .call();
            if (lockFlag) {
              tempObj.tokenId = Number(element.data);
              let nft_uri = await contract_token.methods
                .tokenURI(Number(element.data))
                .call();
              let just_hash = nft_uri.slice(7); // change to appropriate length
              let meta_data_link =
                "https://sins.mypinata.cloud/ipfs/" + just_hash;
              console.log("meta_data_link", meta_data_link);
              tempObj.metaDataLink = meta_data_link;
              let link = rairityJsonLink + tempObj.tokenId;
              let rarityLik = rarityLink + tempObj.tokenId + ".json";
              let rarityData: any;
              await fetch(rarityLik)
                .then((res) => res.json())
                .then((json) => {
                  rarityData = json.rarity;
                });
              fetch(link)
                .then((res) => res.json())
                .then((json) => {
                  tempObj.name = json.name;
                  tempObj.description = json.description;
                  tempObj.attributes = json.attributes;
                  tempObj.rarityData = rarityData;
                  tempObj.image = rairityImageLink + tempObj.tokenId + ".png";
                });

              current_nfts.unshift(tempObj); // changed this to after break to avoid double of the previsous
            }
          }
        }
        uniqueArr = await getUniqueListBy(current_nfts);
      });
    this.setState({ isUnlockloading: false });
    await this.setState({
      lockedNFT: uniqueArr,
      loadingStatus: this.state.loadingStatus + 1,
    });
  };

  public onCallStake = async (stakePrice: any) => {
    if (stakePrice === 0) {
      window.alert("Please select stake amount.");
    } else if (stakePrice > this.state.smbrTokenBalance) {
      window.alert("You don't have the amount you selected.");
    } else {
      this.setState({ stakeLoading: 1 });
      let contractCall = callStake;

      if (!contractCall) {
        throw new Error(
          `No matching contract calls for functionSig= callGetAuctions`
        );
      }

      const { web3, amount, totalPrice, address, contractAddress } = this.state;
      let convertedPrice = this.convert_to_wei1(stakePrice);
      console.log("convertedPrice1", convertedPrice, typeof convertedPrice);
      // let convertedPrice = web3.utils.toWei(stakePrice.toString(), "ether");
      await approve(
        web3,
        address,
        convertedPrice,
        contractAddress.TOKEN_CONTRACT,
        contractAddress.STAKING_CONTRACT,
      );
      try {
        this.setState({ modalStatus: 0 });
        let res = await contractCall(
          address,
          web3,
          convertedPrice,
          contractAddress.STAKING_CONTRACT
        );
        // this.setState({auctionList: result});
        // await this.getTotalTokenSold();
        if (res) {
          this.setState({ stakeLoading: 2 });
          setTimeout(async () => {
            let SMBRTokenBalance = await fetchSMBRTokenBalance(
              web3,
              address,
              this.state.contractAddress.TOKEN_CONTRACT
            );
            let stakedTokenBalance = await fetchStakedTokenBalance(
              web3,
              address,
              this.state.contractAddress.STAKING_CONTRACT
            );
            let earnTokenBalance = await fetchEarnedTokenBalance(
              web3,
              address,
              this.state.contractAddress.STAKING_CONTRACT
            );
            this.setState({
              smbrTokenBalance: this.convert_from_wei1(SMBRTokenBalance),
              stakedTokenBalance: this.convert_from_wei1(stakedTokenBalance),
              earnedTokenBalance: this.convert_from_wei1(earnTokenBalance),
              stakeLoading: 0,
            });
          }, 2000);
        } else {
          this.setState({ stakeLoading: 0 });
        }
      } catch (error) {
        this.setState({ web3, result: null });
      }
    }
  };

  public onCallWithDraw = async (withDrawAmount: any) => {
    if (withDrawAmount === 0 || Number.isNaN(withDrawAmount)) {
      window.alert("Please select widthdraw amount.");
    } else if (withDrawAmount > this.state.stakedTokenBalance) {
      window.alert("You don't have the amount you selected.");
    } else {
      this.setState({ stakeLoading: 1 });

      let contractCall = callWithDraw;

      if (!contractCall) {
        throw new Error(
          `No matching contract calls for functionSig= callGetAuctions`
        );
      }

      const { web3, address } = this.state;
      let convertedPrice = this.convert_to_wei1(withDrawAmount);
      // let convertedPrice = web3.utils.toWei(withDrawAmount.toString(), "ether");
      try {
        this.setState({ modalStatus: 0 });
        let res = await contractCall(
          address,
          web3,
          convertedPrice,
          this.state.contractAddress.STAKING_CONTRACT
        );
        // this.setState({auctionList: result});
        // await this.getTotalTokenSold();
        if (res) {
          this.setState({ stakeLoading: 2 });
          setTimeout(async () => {
            let SMBRTokenBalance = await fetchSMBRTokenBalance(
              web3,
              address,
              this.state.contractAddress.TOKEN_CONTRACT
            );
            let stakedTokenBalance = await fetchStakedTokenBalance(
              web3,
              address,
              this.state.contractAddress.STAKING_CONTRACT
            );
            let earnTokenBalance = await fetchEarnedTokenBalance(
              web3,
              address,
              this.state.contractAddress.STAKING_CONTRACT
            );
            this.setState({
              smbrTokenBalance: this.convert_from_wei1(SMBRTokenBalance),
              stakedTokenBalance: this.convert_from_wei1(stakedTokenBalance),
              earnedTokenBalance: this.convert_from_wei1(earnTokenBalance),
              stakeLoading: 0,
            });
          }, 2000);
        } else {
          this.setState({ stakeLoading: 0 });
        }
      } catch (error) {
        this.setState({ web3, result: null });
      }
    }
  };

  public onCallHarvest = async () => {
    if (this.state.earnedTokenBalance === 0) {
      window.alert("You don't have the earned token yet.");
    } else {
      this.setState({ stakeLoading: 1 });
      let contractCall = callHarvest;

      if (!contractCall) {
        throw new Error(
          `No matching contract calls for functionSig= callGetAuctions`
        );
      }

      const { web3, address } = this.state;
      try {
        this.setState({ modalStatus: 0 });
        let res = await contractCall(
          address,
          web3,
          this.state.contractAddress.STAKING_CONTRACT
        );
        // this.setState({auctionList: result});
        // await this.getTotalTokenSold();
        if (res) {
          this.setState({ stakeLoading: 2 });
          setTimeout(async () => {
            let SMBRTokenBalance = await fetchSMBRTokenBalance(
              web3,
              address,
              this.state.contractAddress.TOKEN_CONTRACT
            );
            let stakedTokenBalance = await fetchStakedTokenBalance(
              web3,
              address,
              this.state.contractAddress.STAKING_CONTRACT
            );
            let earnTokenBalance = await fetchEarnedTokenBalance(
              web3,
              address,
              this.state.contractAddress.STAKING_CONTRACT
            );
            this.setState({
              smbrTokenBalance: this.convert_from_wei1(SMBRTokenBalance),
              stakedTokenBalance: this.convert_from_wei1(stakedTokenBalance),
              earnedTokenBalance: this.convert_from_wei1(earnTokenBalance),
              stakeLoading: 0,
            });
          }, 2000);
        } else {
          this.setState({ stakeLoading: 0 });
        }
      } catch (error) {
        this.setState({ web3, result: null });
      }
    }
  };

  public add_chain_with_web3 = async () => {
    const { library } = this.state;
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: this.state.networkInfo.chainId }],
      });
    } catch (error) {
      console.log(error);
    }

    // const provider = window.ethereum;

    // let result;
    // try {
    //   result = await provider.request({
    //     method: "wallet_addEthereumChain",
    //     params: [rinkebyNetworkInfo],
    //   });
    // } catch (addError) {
    //   // handle "add" error
    // }
    // // }
    // // handle other "switch" errors
    // // }
  };

  public subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on("close", () => this.resetApp());
    provider.on("accountsChanged", async (accounts: string[]) => {
      // await this.setState({ address: accounts[0] });
      // await this.getAccountAssets();
      await this.onConnect();
    });
    provider.on("chainChanged", async (chainId: number) => {
      // const { web3 } = this.state;
      // const networkId = await web3.eth.net.getId();
      // await this.setState({ chainId, networkId });
      // await this.getAccountAssets();
      await this.onConnect();
    });

    provider.on("networkChanged", async (networkId: number) => {
      // const { web3 } = this.state;
      // const chainId = await web3.eth.chainId();
      // await this.setState({ chainId, networkId });
      // await this.getAccountAssets();
      await this.onConnect();
    });
    provider.on("disconnect", (error: { code: number; message: string }) => {
      console.log(error);
    });
  };

  public getNetwork = () => getChainData(this.state.chainId).network;

  public getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.REACT_APP_INFURA_ID,
        },
      },
      "custom-coinbase": {
        display: {
          logo: coinBase,
          name: "Coinbase",
          description: "Scan with WalletLink to connect",
        },
        options: {
          appName: "app", // Your app name
          networkUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
          chainId: 3,
        },
        package: WalletLink,
        connector: async (_: any, options: any) => {
          const { appName, networkUrl, chainId } = options;
          const walletLink = new WalletLink({
            appName,
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        },
      },
      portis: {
        package: Portis,
        options: {
          id: process.env.REACT_APP_PORTIS_ID,
        },
      },
      fortmatic: {
        package: Fortmatic,
        options: {
          key: process.env.REACT_APP_FORTMATIC_KEY,
        },
      },
      // mewconnect: {
      //   package: MewConnect, // required
      //   options: {
      //     infuraId: process.env.REACT_APP_INFURA_ID // required
      //   }
      // },
      torus: {
        package: Torus,
      },
    };
    return providerOptions;
  };

  public getAccountAssets = async () => {
    const { address, chainId } = this.state;
    this.setState({ fetching: true });
    try {
      // get account balances
      const assets = await apiGetAccountAssets(address, chainId);

      await this.setState({ fetching: false, assets });
    } catch (error) {
      console.error(error); // tslint:disable-line
      await this.setState({ fetching: false });
    }
  };

  public toggleModal = () =>
    this.setState({ showModal: !this.state.showModal });

  public onMintChangeButtonClick = async (dir: number) => {
    const { amount, totalPrice } = this.state;
    if (dir == -1) {
      if (amount <= 1) {
        this.setState({ amount: 1 });
      } else {
        let totalAmonut = amount + dir;
        this.setState({ amount: totalAmonut });
        let totalprice = (this.state.itemPrice * totalAmonut).toFixed(6);
        // const etherValue = Web3.utils.fromWei(totalprice.toString(), 'ether');
        this.setState({ totalPrice: totalprice });
        // setTotalPrice(totalAmonut)
      }
    } else if (dir == 1) {
      if (amount == this.state.maxAmount) {
        this.setState({ amount: this.state.maxAmount });
      } else if (amount >= 7) {
        this.setState({ amount: amount });
      } else {
        let totalAmonut: number = amount + dir;
        this.setState({ amount: totalAmonut });
        let totalprice = (this.state.itemPrice * totalAmonut).toFixed(6);
        this.setState({ totalPrice: totalprice });
      }
    }
  };
  public onStakAmountChangeButtonClick = async (dir: number) => {
    const { stakeAmount } = this.state;
    if (dir == -1) {
      if (stakeAmount <= 1) {
        this.setState({ stakeAmount: 1 });
      } else {
        let totalAmonut = stakeAmount + dir;
        this.setState({ stakeAmount: totalAmonut });
        let totalprice = (this.state.itemPrice * totalAmonut).toFixed(6);
        // const etherValue = Web3.utils.fromWei(totalprice.toString(), 'ether');
        this.setState({ totalPrice: totalprice });
        // setTotalPrice(totalAmonut)
      }
    } else if (dir == 1) {
      let totalAmonut: number = stakeAmount + dir;
      this.setState({ stakeAmount: totalAmonut });
    }
  };
  public onStakReleaseChangeButtonClick = async (dir: number) => {
    const { stakeRelease } = this.state;
    if (dir == -1) {
      if (stakeRelease <= 1) {
        this.setState({ stakeRelease: 1 });
      } else {
        let totalAmonut = stakeRelease + dir;
        this.setState({ stakeRelease: totalAmonut });
      }
    } else if (dir == 1) {
      let totalAmonut: number = stakeRelease + dir;
      this.setState({ stakeRelease: totalAmonut });
    }
  };

  public onMaxButtonClick = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (parseInt(event.target.value) <= 1) {
      this.setState({ amount: 1 });
    } else if (parseInt(event.target.value) >= 7) {
      this.setState({ amount: 7 });
    } else if (parseInt(event.target.value) >= this.state.maxAmount) {
      this.setState({ amount: this.state.maxAmount });
    } else {
      this.setState({ amount: parseInt(event.target.value) });
    }
    let totalprice = this.state.itemPrice * parseInt(event.target.value);
    this.setState({ totalPrice: totalprice });
  };

  public onStakeAmountHandle = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (parseInt(event.target.value) <= 1) {
      this.setState({ stakeAmount: 1 });
    } else {
      this.setState({ stakeAmount: parseInt(event.target.value) });
    }
  };
  public onStakeReleaseHandle = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (parseInt(event.target.value) <= 1) {
      this.setState({ stakeRelease: 1 });
    } else {
      this.setState({ stakeRelease: parseInt(event.target.value) });
    }
  };

  public onCallMint = async () => {
    this.setState({ mintLoading: 1 });
    let contractCall = callMint;

    if (!contractCall) {
      throw new Error(
        `No matching contract calls for functionSig= callGetAuctions`
      );
    }

    const { web3, address, chainId, amount, totalPrice, contractAddress } =
      this.state;
    try {
      this.setState({ modalStatus: 0 });
      let res = await contractCall(
        contractAddress.MINT_CONTRACT,
        address,
        chainId,
        web3,
        amount,
        totalPrice
      );
      // this.setState({auctionList: result});
      // await this.getTotalTokenSold();
      if (res) {
        this.setState({ mintLoading: 2 });
        history.push("/rarity");
        window.location.reload();
      } else {
        this.setState({ mintLoading: 0 });
        // history.push("/rarity");
        // window.location.reload();
      }
    } catch (error) {
      this.setState({ web3, result: null });
    }
  };

  public onCallCheckStakable = async (tokenId: any) => {
    const { web3, address, contractAddress } = this.state;
    let stakableFlag = await isCheckStakable(
      web3,
      tokenId,
      contractAddress.LOCK_CONTRACT
    );
    let res = "";
    if (stakableFlag == true) {
      res = "true";
    } else if (stakableFlag.includes("already used as a boost")) {
      res = "already used as a boost";
    } else if (stakableFlag.includes("need more time to pass untill")) {
      res = "need more time to pass untill";
    }
    this.setState({ stakeFlag: res });
  };

  public onCallBoostStaking = async (tokenId: any) => {
    this.setState({ lockLoading: 1 });
    const { web3, address, contractAddress } = this.state;
    await callBoostCStaking(
      web3,
      tokenId,
      address,
      contractAddress.LOCK_CONTRACT
    );
    let bonusesBalance = await fetchBonuses(
      web3,
      address,
      this.state.contractAddress.STAKING_CONTRACT
    );
    this.setState({ lockLoading: 2, bonuses: bonusesBalance });
  };

  public onCallLock = async (tokenId: any) => {
    this.setState({ lockLoading: 1 });
    let contractCall = callNFTLock;

    if (!contractCall) {
      throw new Error(
        `No matching contract calls for functionSig= callGetAuctions`
      );
    }
    const { web3, address } = this.state;
    let approvedFlag = await isApprovedForAll(
      web3,
      address,
      this.state.contractAddress.MINT_CONTRACT,
      this.state.contractAddress.LOCK_CONTRACT
    );
    if (!approvedFlag) {
      await nftApprove(
        web3,
        address,
        this.state.contractAddress.MINT_CONTRACT,
        this.state.contractAddress.LOCK_CONTRACT
      );
    } else {
      try {
        let res = await contractCall(
          address,
          tokenId,
          web3,
          this.state.contractAddress.LOCK_CONTRACT
        );
        if (res) {
          this.setState({ lockLoading: 2 });
          // now fetch all nfts
          this.fetchNFT(web3, address);
          // fetch all locked nfts
          this.fetchLockedNFTData(web3, address);
        } else {
          this.setState({ lockLoading: 0 });
        }
      } catch (error) {
        this.setState({ web3, result: null });
      }
    }
  };

  public onCallUnlock = async (tokenId: any) => {
    this.setState({ lockLoading: 1 });
    let contractCall = callNFTUnlock;

    if (!contractCall) {
      throw new Error(
        `No matching contract calls for functionSig= callGetAuctions`
      );
    }
    const { web3, address } = this.state;
    try {
      let res = await contractCall(
        address,
        tokenId,
        web3,
        this.state.contractAddress.LOCK_CONTRACT
      );
      if (res) {
        this.fetchNFT(web3, address);
        // fetch all locked nfts
        this.fetchLockedNFTData(web3, address);
        let bonusesBalance = await fetchBonuses(
          web3,
          address,
          this.state.contractAddress.STAKING_CONTRACT
        );
        this.setState({ lockLoading: 2, bonuses: bonusesBalance });
      } else {
        this.setState({ lockLoading: 0 });
      }
    } catch (error) {
      this.setState({ web3, result: null });
    }
  };

  public resetApp = async () => {
    const { web3 } = this.state;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await this.web3Modal.clearCachedProvider();
    this.setState({ ...INITIAL_STATE });
  };

  public _onHideMenu = (bool: boolean) => {
    this.setState({ isHide: bool });
  };

  public render = () => {
    const {
      address,
      connected,
      chainId,
      isHide,
      balance,
      amount,
      totalPrice,
      maxAmount,
      itemPrice,
      ownNFT,
      lockedNFT,
      connectFlag,
      isloading,
      isUnlockloading,
      lockLoading,
      mintLoading,
      stakeAmount,
      stakeRelease,
      maticPrice,
      sombraPrice,
      smbrTokenBalance,
      stakedTokenBalance,
      earnedTokenBalance,
      stakeLoading,
      withdrawLoading,
      harvestLoading,
      loadingStatus,
      contractAddress,
      bonuses,
      stakeFlag,
    } = this.state;
    return (
      <ScreenWrapper>
        <Router history={history}>
          <Switch>
            <Route
              exact
              path="/"
              render={() => (
                <MintScreen
                  connectFlag={connectFlag}
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                  onMintChangeButtonClick={this.onMintChangeButtonClick}
                  onStakAmountChangeButtonClick={
                    this.onStakAmountChangeButtonClick
                  }
                  onStakReleaseChangeButtonClick={
                    this.onStakReleaseChangeButtonClick
                  }
                  onMaxButtonClick={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => this.onMaxButtonClick(event)}
                  onStakeAmountHandle={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => this.onStakeAmountHandle(event)}
                  onStakeReleaseHandle={(
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => this.onStakeReleaseHandle(event)}
                  onCallMint={this.onCallMint}
                  amount={amount}
                  stakeAmount={stakeAmount}
                  stakeRelease={stakeRelease}
                  totalPrice={totalPrice}
                  balance={balance}
                  maxAmount={maxAmount}
                  itemPrice={itemPrice}
                  maticPrice={maticPrice}
                  sombraPrice={sombraPrice}
                  add_chain_with_web3={this.add_chain_with_web3}
                  mintLoading={mintLoading}
                />
              )}
            />
            <Route
              exact
              path="/rarity"
              render={() => (
                <RarityScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                  amount={amount}
                  totalPrice={totalPrice}
                  balance={balance}
                  ownNFT={ownNFT}
                  lockedNFT={lockedNFT}
                  add_chain_with_web3={this.add_chain_with_web3}
                  connectFlag={connectFlag}
                  lockLoading={lockLoading}
                  isloading={isloading}
                  contractAddress={contractAddress}
                  stakeFlag={stakeFlag}
                  onCallLock={this.onCallLock}
                  onCallUnlock={this.onCallUnlock}
                  onCallCheckStakable={this.onCallCheckStakable}
                  onCallBoostStaking={this.onCallBoostStaking}
                />
              )}
            />
            <Route
              exact
              path="/browse"
              render={() => (
                <BrowseScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                  amount={amount}
                  totalPrice={totalPrice}
                  balance={balance}
                  ownNFT={ownNFT}
                  lockedNFT={lockedNFT}
                  add_chain_with_web3={this.add_chain_with_web3}
                  connectFlag={connectFlag}
                  lockLoading={lockLoading}
                  isloading={isloading && isUnlockloading}
                  onCallLock={this.onCallLock}
                  onCallUnlock={this.onCallUnlock}
                />
              )}
            />
            <Route
              exact
              path="/litepaper"
              render={() => (
                <LitepaperScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                  amount={amount}
                  totalPrice={totalPrice}
                  balance={balance}
                  ownNFT={ownNFT}
                  add_chain_with_web3={this.add_chain_with_web3}
                  connectFlag={connectFlag}
                  isloading={isloading}
                />
              )}
            />
            <Route
              exact
              path="/smbr"
              render={() => (
                <SmbrScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                  amount={amount}
                  totalPrice={totalPrice}
                  balance={balance}
                  ownNFT={ownNFT}
                  add_chain_with_web3={this.add_chain_with_web3}
                  connectFlag={connectFlag}
                  isloading={isloading}
                />
              )}
            />
            <Route
              exact
              path="/story"
              render={() => (
                <StoryScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                  amount={amount}
                  totalPrice={totalPrice}
                  balance={balance}
                  ownNFT={ownNFT}
                  add_chain_with_web3={this.add_chain_with_web3}
                  connectFlag={connectFlag}
                  isloading={isloading}
                />
              )}
            />
            <Route
              exact
              path="/tvshow"
              render={() => (
                <TvshowScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                />
              )}
            />
            <Route
              exact
              path="/game"
              render={() => (
                <GameScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                />
              )}
            />
            <Route
              exact
              path="/bodega"
              render={() => (
                <BodegaScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                />
              )}
            />
            <Route
              exact
              path="/stake1"
              render={() => (
                <StakingScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                />
              )}
            />
            <Route
              exact
              path="/loot"
              render={() => (
                <LootScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                />
              )}
            />
            <Route
              exact
              path="/nftstake"
              render={() => (
                <NFTStakingScreen
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                  amount={amount}
                  totalPrice={totalPrice}
                  balance={balance}
                  ownNFT={ownNFT}
                  add_chain_with_web3={this.add_chain_with_web3}
                  connectFlag={connectFlag}
                  isloading={isloading}
                />
              )}
            />
            <Route
              exact
              path="/stake"
              render={() => (
                <StakingScreenTemp
                  connect={this.onConnect}
                  isHide={isHide}
                  setIsHide={(e: any) => this._onHideMenu(e)}
                  connected={connected}
                  address={address}
                  chainId={chainId}
                  killSession={this.resetApp}
                  amount={amount}
                  totalPrice={totalPrice}
                  balance={balance}
                  ownNFT={ownNFT}
                  add_chain_with_web3={this.add_chain_with_web3}
                  connectFlag={connectFlag}
                  isloading={isloading}
                  smbrTokenBalance={smbrTokenBalance}
                  stakedTokenBalance={stakedTokenBalance}
                  earnedTokenBalance={earnedTokenBalance}
                  onCallStake={this.onCallStake}
                  onCallWithDraw={this.onCallWithDraw}
                  onCallHarvest={this.onCallHarvest}
                  stakeLoading={stakeLoading}
                  withdrawLoading={withdrawLoading}
                  harvestLoading={harvestLoading}
                  bonuses={bonuses}
                  lockedNFT={lockedNFT}
                  lockLoading={lockLoading}
                  contractAddress={contractAddress}
                  stakeFlag={stakeFlag}
                  onCallLock={this.onCallLock}
                  onCallUnlock={this.onCallUnlock}
                  onCallCheckStakable={this.onCallCheckStakable}
                  onCallBoostStaking={this.onCallBoostStaking}
                />
              )}
            />
            <Route exact path="/terms" render={() => <TermsScreem />} />
            <Route exact path="/privacy" render={() => <PrivacyScreen />} />
            <Route exact path="/law" render={() => <LawScreen />} />
          </Switch>
          <Footer />
        </Router>
      </ScreenWrapper>
    );
  };
}

export default App;
