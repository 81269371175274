import { useEffect, useState } from "react";
import styled from "styled-components";
import RarityHeader from "../components/Home/RarityHeader";
import BrowseContainer from "../components/Home/BrowseContainer";
import { colors } from "../styles";
import { useHistory } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import loading from "../assets/images/loading.gif";
import checked from "../assets/images/checked.png";
import unchecked from "../assets/images/unchecked.png";
import sinsImage from "src/assets/images/SINS_LOGO_WHite.png";
import close from "../assets/images/close.png";
import browseTitle from "../assets/images/browseTitle.png";
import { MINT_CONTRACT } from "../constants/contracts";
import {
  rarityLink,
  rairityImageLink,
  rairityJsonLink,
} from "../sombra_config";
import { GenesisData } from "../genesis_metadata";
import Web3 from "web3";
import { getValue, checkFlagFun } from "../helpers/utility";
import Dialog from "@material-ui/core/Dialog";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
  amount: number;
  totalPrice: number;
  balance: number;
  ownNFT: Array<any>;
  lockedNFT: Array<any>;
  add_chain_with_web3: () => void;
  connectFlag: boolean;
  isloading: boolean;
  lockLoading: number;
  onCallLock: (tokenId: any) => void;
  onCallUnlock: (tokenId: any) => void;
}

interface TapContainerProps {
  tapStatus: boolean;
}

const MintContainer = styled.div`
  background-image: url(/images/browseBackImage.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
  @media (max-width: 600px) {
    padding-top: 60px;
  }
`;
const SinsImage1 = styled.img`
  width: 185px;
  margin-top: 50px;
  display: none;
  @media screen and (max-width: 600px) {
    width: 75px;
    display: flex;
    margin-top: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;
const MenuContainer = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease-in-out;
  justify-content: space-between;
  flex-direction: column;
  background: black;
  opacity: 1;
  width: 100%;
  height: 100%;
  left: calc((100% - 300px) / 2);
  padding-top: 20px;
  padding-bottom: 20px !important;
  z-index: 1000;
  border: 1px solid rgba(${colors.border});
  @media (max-width: 960px) {
    padding-bottom: 30px !important;
    width: 100%;
    left: 0;
  }
`;

const LoadingImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 150px;
`;
const TapArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 80%;
  margin-bottom: 30px;
`;
const TapItem = styled.div<TapContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 10px;
  font-family: Orbitron-Regular;
  font-size: 18px;
  background-color: ${(props) => (props.tapStatus ? "#e9a82b" : "balck")};
  padding: 10px 20px;
  border-radius: 50px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
  &:hover {
    cursor: pointer;
  }
`;
const SwitchAddress = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 1400px;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
  align-items: center;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;
const AddressInput = styled.input`
  border-radius: 10px;
  margin-right: 30px;
  width: 300px;
  outline: none;
  color: black;
  font-family: Gilroy-Light;
  font-size: 18px;
  padding: 0 10px;
  @media screen and (max-width: 600px) {
    width: 200px;
    margin-right: 10px;
  }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const OwnerButton = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  @media screen and (max-width: 1200px) {
    position: relative;
    margin-top: 20px;
  }
`;
const SideBarArea = styled.div`
  width: 250px;
  margin-right: 5%;
  @media (max-width: 1000px) {
    display: none;
  }
`;
const SideBarArea1 = styled.div`
  width: 250px;
  margin-right: 5%;
  background-color: black;
  display: none;
  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;
const FilterTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const IdTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
  font-family: MonumentExtended-Regular;
  color: white;
`;
const IdTitle2 = styled.p`
  font-size: 30px;
  font-weight: 500;
  font-family: Animal-Silence;
  color: white;
`;
const IdTitle1 = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: Animal-Silence;
  color: white;
  display: none;
  text-align: end;
  width: 85%;
  margin-top: -30px;
  @media (max-width: 1000px) {
    display: flex;
  }
  &:hover {
    cursor: pointer;
  }
`;
const FilterNum = styled.p`
  font-size: 16px;
  border: 1px solid gray;
  padding: 2px 10px;
  border-radius: 11px;
  text-align: center;
  color: white;
`;
const FilterItemTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-image: url(/images/categoryBackImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: space-between;
  align-items: center;
  // border-bottom: 1px solid gray;
  margin-top: 10px;
  height: 57px;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1000px) {
    height: 70px;
  }
`;
const DropImage = styled.img`
  width: 14px;
  height: 8px;
  &:hover {
    cursor: pointer;
  }
`;
const ClothesDropArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const SelectItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  // justify-content:space-between;
  align-items: flex-end;
  &:hover {
    cursor: pointer;
  }
`;
const CheckTitle = styled.p`
  font-size: 13px;
  font-family: MonumentExtended-Regular;
  font-weight: 400;
  margin: 0;
  margin-left: 8px;
  color: white;
`;
const ContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`;
const MainContentArea = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: 1500px) {
    width: 90%;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  @media screen and (max-width: 959px) {
    padding: 0;
    height: 100%;
  }
`;

const CheckImage = styled.img`
  margin-left: 20px;
  width: 25px;
`;

const TitleImage = styled.img`
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 500px;
  width: 80%;
  display: flex;
  align-self: center;
  @media screen and (max-width: 560px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const FilterAttributes = [
  {
    id: 0,
    title: "BACKGROUND",
    subItems: [
      "Black Yellow",
      "Blue",
      "Fuchsia",
      "Gold",
      "Lemon Lime",
      "Lime",
      "Peach",
      "Pink",
      "Purple",
      "Red",
      "Turquoise",
    ],
  },
  {
    id: 1,
    title: "RARIBLES",
    subItems: [
      "AK_47s",
      "Angel Wings",
      "Broad Sword",
      "Daddys Gun",
      "Diamond Wings",
      "Dread Rockets",
      "Excaliber",
      "Lightning Wings",
      "Matrix",
      "None",
      "Sword of Power",
    ],
  },
  {
    id: 2,
    title: "BASE",
    subItems: [
      "Ancient 1",
      "Ancient 2",
      "Type 1",
      "Type 10",
      "Type 11",
      "Type 12",
      "Type 13",
      "Type 14",
      "Type 15",
      "Type 16",
      "Type 2",
      "Type 3",
      "Type 4",
      "Type 5",
      "Type 6",
      "Type 7",
      "Type 8",
      "Type 9",
    ],
  },
  {
    id: 3,
    title: "EYES",
    subItems: [
      "Afterlife",
      "Alpha Flames",
      "Bloodshot",
      "From Hell",
      "Glass",
      "Human",
      "Pink",
      "Purple",
      "Scar",
      "Silver",
      "Sleepy",
      "Yellow",
    ],
  },
  {
    id: 4,
    title: "MOUTH_NECK",
    subItems: [
      "Bandaid",
      "Black Skull",
      "Blue Gas Mask",
      "Cigarette",
      "Crystal Skull",
      "Cyber Jaw",
      "Diamond",
      "Dragon Mask",
      "Geen Bubble Gum",
      "Gold",
      "Human",
      "Hybrid",
      "Jewel Jaw",
      "Lipstick",
      "Metal Head",
      "Neck Tattoo",
      "Pink Bubble Gum",
      "Purple Cyber Neck",
      "Red Gas Mask",
      "Scars",
      "Sinister Cyborg",
      "Skull",
    ],
  },
  {
    id: 5,
    title: "ACCESORIES",
    subItems: [
      "Brain Implants",
      "Brow Ring",
      "Color Tattoo",
      "Cyber Shades",
      "Cyberclops",
      "Dread Patch",
      "Metal scars",
      "Metatooth",
      "Moody Eye",
      "PC Visor",
      "Poly Earrings",
      "Rad Earrings",
      "Robo Cop",
      "Sombra Earrings",
      "Sombra Tattoo",
      "Thermals",
      "Triangle Earrings",
      "Viewfinder",
      "Watchmen",
    ],
  },
  {
    id: 6,
    title: "HAIR",
    subItems: [
      "Bio Plate",
      "Blonde Buzzed",
      "Blonde Undercut",
      "Bun",
      "Chun Li",
      "Cool White",
      "Cropped",
      "Curls",
      "Cyber Fem",
      "Cyber Pomp",
      "Cyber Tattoo",
      "Dreads",
      "Green",
      "Heart Throb",
      "Locks",
      "Messy Bun",
      "Mid Black",
      "Mid Pink",
      "Mid White",
      "Mullet",
      "Pink Faux Hawk",
      "Punk",
      "Purple Undercut",
      "Shaggy",
      "Short Messy",
      "Teal Undercut",
      "Undercut Green Tip",
      "White Buzzed",
    ],
  },
  {
    id: 7,
    title: "CLOTHES",
    subItems: [
      "Alpha Ranger",
      "Biker Jacket",
      "Bio Armor",
      "Blue Suit",
      "BowTie Suit",
      "Button Up",
      "Chee Armor",
      "Club Shirt",
      "Crop Top",
      "Cyber Jacket",
      "Cyber Vest",
      "FKU",
      "Fire Fur",
      "Flight Jacket",
      "Gi",
      "Glass Armor",
      "Hoodie",
      "Leather Jacket",
      "Orange Fur",
      "Pink Fur",
      "Queen Suit",
      "Robo Fem",
      "Sins T",
      "Sombra T",
      "Teddy Love u",
      "Warrior Vest",
      "Yellow Jacket",
    ],
  },
  {
    id: 8,
    title: "HELMET",
    subItems: [
      "Hydrogen Captain",
      "Hydrogen Commando",
      "Mercenary",
      "None",
      "Shedo Clan",
    ],
  },
  {
    id: 9,
    title: "WEAPONS",
    subItems: [
      "Baby Bear",
      "Blue Balls",
      "Bonfire",
      "Harpa Katana",
      "Merry Peppins",
      "None",
      "Rabbit",
      "Sable de Sombra",
      "Yogi Bear",
    ],
  },
];

const BrowseScreen = (props: Props) => {
  const [nftData, setNftData] = useState<any[]>([]);
  const [otherWalletAddress, setOtherWalletAddress] = useState("");
  const [lockedNftData, setLockedNftData] = useState<any[]>([]);
  const [isloading, setIsLoading] = useState(false);
  const [switchFlag, setSwitchFlag] = useState(false);
  const [tapStatus, setTapStatus] = useState(true);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [menuFlag, setMenuFlag] = useState(false);
  const [clothesDrop, setClothesDrop] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [filterArr, setFilterArr] = useState({
    BACKGROUND: [],
    RARIBLES: [],
    BASE: [],
    EYES: [],
    MOUTH_NECK: [],
    ACCESORIES: [],
    HAIR: [],
    CLOTHES: [],
    HELMET: [],
    WEAPONS: [],
  });

  const handleClose = () => {
    setMenuFlag(false);
  };

  useEffect(() => {
    console.log("props.lockedNFT", props.lockedNFT);
    setNftData(props.ownNFT);
    setLockedNftData(props.lockedNFT);
    setIsLoading(props.isloading);
  }, [props.ownNFT, props.lockedNFT]);

  useEffect(() => {
    setFilteredData(GenesisData);
    window.scrollTo(0, 0);
    const initialWeb3 = async () => {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider);
      }
    };
    initialWeb3();
  }, []);

  let history = useHistory();

  const networkWarning = () => {
    alert("Not quite ready yet");
  };

  const clothesHandler = (index: number) => {
    let temp = [...clothesDrop];
    temp[index] = !temp[index];
    setClothesDrop(temp);
  };

  const fetchNFT = async (address: any) => {
    if (address === "") {
      window.alert("Please type address");
    } else {
      setSwitchFlag(true);
      // if (!props.connectFlag) {
      //   await props.add_chain_with_web3();
      // }
      let j = 0;
      let keep_going = true;
      let current_nfts: any = [];
      setIsLoading(true);
      while (keep_going) {
        let tempObj: any = {};
        try {
          let nft_call: any = await fetchNFTItemData(j, address);
          if (!nft_call) {
            break;
          }
          tempObj.tokenId = nft_call[2];
          tempObj.metaDataLink = nft_call[0];
          let link = rairityJsonLink + tempObj.tokenId;
          let rarityLik = rarityLink + tempObj.tokenId + ".json";
          let rarityData: any;
          await fetch(rarityLik)
            .then((res) => res.json())
            .then((json) => {
              rarityData = json.rarity;
            });
          fetch(link)
            .then((res) => res.json())
            .then((json) => {
              tempObj.name = json.name;
              tempObj.description = json.description;
              tempObj.attributes = json.attributes;
              tempObj.rarityData = rarityData;
              // tempObj.image = json.image.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
              tempObj.image = rairityImageLink + tempObj.tokenId + ".png";
            });

          current_nfts.unshift(tempObj); // changed this to after break to avoid double of the previsous
        } catch (err) {
          console.log(err);
          keep_going = false;
        }
        j++;
      }
      setIsLoading(false);
      setNftData(current_nfts);
    }
  };

  const fetchNFTItemData = async (j: any, address: any) => {
    let web3 = new Web3(
      "https://rinkeby.infura.io/v3/522640124384455690d796f8eb334569"
    );
    const contract_token = new web3.eth.Contract(
      MINT_CONTRACT.abi,
      MINT_CONTRACT.address
    );
    let current_nft;
    let owners_balance;
    try {
      current_nft = await contract_token.methods
        .tokenOfOwnerByIndex(address, j)
        .call();
      owners_balance = await contract_token.methods.balanceOf(address).call();
      // nft_info = await contract_token.methods.nft_info(current_nft).call()
    } catch (err) {
      console.log(err);
      return false;
    }
    try {
      if (current_nft) {
        let nft_uri = await contract_token.methods.tokenURI(current_nft).call();

        let just_hash = nft_uri.slice(7); // change to appropriate length

        let meta_data_link = "https://cloudflare-ipfs.com/ipfs/" + just_hash;

        return [meta_data_link, owners_balance, current_nft];
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <SinsImage1
          onClick={() => {
            history.push(`/`);
          }}
          src={sinsImage}
          alt=""
        />
      </div>
      <TitleImage src={browseTitle} alt="" />
      <IdTitle1 onClick={() => setMenuFlag(!menuFlag)}>
        FILTER CATEGORIES
      </IdTitle1>
      <ContentArea>
        <SideBarArea>
          <FilterTitle>
            <IdTitle2 style={{ marginTop: 45 }}>CATEGORIES</IdTitle2>
            {/* <FilterNum style={{ marginTop: 45 }}>1</FilterNum> */}
          </FilterTitle>
          {FilterAttributes.map((item, index) => {
            return (
              <div>
                <FilterItemTitle onClick={() => clothesHandler(index)}>
                  <IdTitle>{item.title}</IdTitle>
                  {/* <DropImage src={arrowDown} /> */}
                </FilterItemTitle>
                {clothesDrop[index] && (
                  <ClothesDropArea>
                    {item.subItems.map((item1, index) => {
                      return (
                        <SelectItem
                          onClick={() => {
                            let newFilterVal = getValue(
                              item.title,
                              filterArr,
                              item1
                            );
                            setFilterArr(newFilterVal[0]);
                            setFilteredData(newFilterVal[1]);
                          }}
                        >
                          <CheckImage
                            src={
                              checkFlagFun(item.title, filterArr).indexOf(
                                item1
                              ) > -1
                                ? checked
                                : unchecked
                            }
                            alt=""
                          />
                          <CheckTitle>{item1}</CheckTitle>
                        </SelectItem>
                      );
                    })}
                  </ClothesDropArea>
                )}
              </div>
            );
          })}
        </SideBarArea>
        <BrowseContainer
          ownNFT={filteredData}
          onCallLock={props.onCallLock}
          onCallUnlock={props.onCallUnlock}
          ownFlag={false}
          lockedFlag={tapStatus ? false : true}
        />
      </ContentArea>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={menuFlag}
        onClose={handleClose}
      >
        <MenuContainer>
          <SideBarArea1>
            <img
              src={close}
              onClick={handleClose}
              alt=""
              style={{
                position: "absolute",
                right: 15,
                top: 15,
                width: 25,
                height: 25,
              }}
            />
            <FilterTitle>
              <IdTitle2 style={{ marginTop: 15 }}></IdTitle2>
            </FilterTitle>
            {FilterAttributes.map((item, index) => {
              return (
                <div>
                  <FilterItemTitle onClick={() => clothesHandler(index)}>
                    <IdTitle>{item.title}</IdTitle>
                  </FilterItemTitle>
                  {clothesDrop[index] && (
                    <ClothesDropArea>
                      {item.subItems.map((item1, index) => {
                        return (
                          <SelectItem>
                            <CheckImage
                              src={
                                checkFlagFun(item.title, filterArr).indexOf(
                                  item1
                                ) > -1
                                  ? checked
                                  : unchecked
                              }
                              alt=""
                              onClick={() => {
                                let newFilterVal = getValue(
                                  item.title,
                                  filterArr,
                                  item1
                                );
                                setFilterArr(newFilterVal[0]);
                                setFilteredData(newFilterVal[1]);
                              }}
                            />
                            <CheckTitle>{item1}</CheckTitle>
                          </SelectItem>
                        );
                      })}
                    </ClothesDropArea>
                  )}
                </div>
              );
            })}
          </SideBarArea1>
        </MenuContainer>
      </Dialog>
    </MintContainer>
  );
};

export default BrowseScreen;
