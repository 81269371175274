export const testNet = false;

export const countDownData: any = {
    countdown: true,
    countDownDate: '2022-05-04T22:00-0500',
}

export const rinkebyChainId: any = 4;
export const mainChainId: any = 1;
export const openSeaLink: string = 'https://opensea.io/collection/sinsofshadow'
export const rarityLink: string = 'https://sinsofshadow.s3.amazonaws.com/rarity/'
export const rarityRankingLink: string = 'https://sinsofshadow.s3.amazonaws.com/ranking/'
export const rairityImageLink: string ='https://sinsofshadow.s3.amazonaws.com/thumbnail/'
export const rairityPreImageLink: string ='https://sinsofshadow.s3.amazonaws.com/thumbnail/pre.png'
export const rairityJsonLink: string ="https://sinsofshadow.s3.amazonaws.com/jsons/"
export const rairityPreJsonLink: string ="https://sinsofshadow.s3.amazonaws.com/pre-reveal-jsons/"

export const num_revealed = 377;

export const rinkebyNetworkInfo: any = {
    chainId: '0x4',
    rpcUrls: ['https://rinkeby.infura.io/v3/'],
    chainName: "Rinkeby Test Network",
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    blockExplorerUrls: ["https://rinkeby.etherscan.io"]
}

export const mainNetworkInfo: any = {
    chainId: '0x1',
    rpcUrls: ['https://mainnet.infura.io/v3/'],
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    blockExplorerUrls: ["https://etherscan.io"]
}

export const rinkebyAddress = {
    TOKEN_CONTRACT:"0xbE6BE4F3F8f03Ea572324f22075C54633C195Ee3",
    NFT_CONTRACT:"0x43AB328295CC202C99194f4b638917431B5e5F3d",
    STAKING_CONTRACT:"0x0759173abB68BBb501608594e25F166380293225",
    LOCK_CONTRACT:"0x01C3d173F57c4114f07a98535a415c30ED83b6A5",
    MINT_CONTRACT:"0x43AB328295CC202C99194f4b638917431B5e5F3d",
}
export const mainAddress = {
    TOKEN_CONTRACT:"0x16b3e050e9e2f0ac4f1bea1b3e4fdc43d7f062dd",
    NFT_CONTRACT:"0x7fdc538356b1c137dc238001e92d480f197dedeb",
    STAKING_CONTRACT:"0xE5e72199cF12c4A4158cE36b0708cBb770CAe630",
    LOCK_CONTRACT:"0x39e15f2A97Fa03c8C09Fc495A63d4742099c26d5",
    MINT_CONTRACT:"0x7fdc538356b1c137dc238001e92d480f197dedeb",
}