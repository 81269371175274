import  { useEffect } from "react";
import styled from "styled-components";
import RarityHeader from "../components/Home/RarityHeader";
import { colors } from "../styles";
import lootImage from "../assets/images/lootImage.png";
import Container from "@material-ui/core/Container";

interface Props {
  connect: () => void;
  setIsHide: (e: any) => void;
  killSession: () => void;
  isHide: boolean;
  connected: boolean;
  address: string;
  chainId: number;
}

const MintContainer = styled.div`
  background-color: rgb(${colors.black});
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 70px;
  @media (max-width: 600px) {
    margin-top: 60px;
  }
`;
const Description4 = styled.p`
  font-size: 24px;
  color: white;
  font-family: Gilroy-Light;
  margin: 0;
  padding-top: 0px;
  padding-bottom: 40px;
  width: 100%;
  letter-spacing: 0.1em;
  @media screen and (max-width: 560px) {
    width: 100%;
    margin-left: 0%;
    font-size: 18px;
  }
`;
const MediumImage = styled.img`
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
  border-radius: 20px;
  @media screen and (max-width: 560px) {
  }
  &:hover {
  }
`;
const TopTitle = styled.p`
  font-family: Orbitron-Regular;
  font-size: 72px;
  color: white;
  margin: 50px auto;
  @media screen and (max-width: 600px) {
    font-size: 48px;
  }
`;
const TopTitle1 = styled.p`
  font-family: Orbitron-Regular;
  font-size: 50px;
  color: white;
  margin: 30px auto;
  text-align:center;
  @media screen and (max-width: 600px) {
    margin: 0px auto;
    font-size: 24px;
  }
`;

const LootScreen = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MintContainer>
      <RarityHeader
        connect={props.connect}
        isHide={props.isHide}
        setIsHide={(e: any) => props.setIsHide(e)}
        connected={props.connected}
        address={props.address}
        chainId={props.chainId}
        killSession={props.killSession}
      />
      <TopTitle>LOOT</TopTitle>
      <TopTitle1>Lootbox details dropping in 24 hours</TopTitle1>
      <Container>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 50,
          }}
        >
          <MediumImage style={{ maxWidth: 943 }} src={lootImage} alt="" />
        </div>
      </Container>
    </MintContainer>
  );
};

export default LootScreen;
